define('clever-sparky-frontend/routes/login', ['exports', 'ember', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'clever-sparky-frontend/mixins/handle-error', 'ember-local-storage'], function (exports, _ember, _unauthenticatedRouteMixin, _handleError, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      inject = _ember.default.inject;
  exports.default = Route.extend(_unauthenticatedRouteMixin.default, _handleError.default, {
    invite: (0, _emberLocalStorage.storageFor)('invite'),
    session: inject.service(),
    branch: inject.service('branch'),
    afterModel: function afterModel() {
      this.get('branch').justBranchfirst();
    },


    queryParams: {
      '_branch_match_id': {
        refreshModel: true
      }
    },

    actions: {
      authenticate: function authenticate(identification, password) {
        var _this = this;

        this.get('session').authenticate('authenticator:cleversparky', identification, password).then(function () {
          _this.controllerFor('application').set('hasJustLoggedIn', true);
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        });
      }
    }
  });
});