define('clever-sparky-frontend/models/order-item', ['exports', 'ember-data', 'ember-macaroni', 'clever-sparky-frontend/mixins/option-lookup'], function (exports, _emberData, _emberMacaroni, _optionLookup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend(_optionLookup.default, {
    // Relationships
    fitting: belongsTo('fitting'),

    // Attributes
    partType: attr('string'),

    partNumber: attr('string'),

    // Properties
    partTypeOption: (0, _emberMacaroni.findFromCollectionByKey)('partTypeOptions', 'value', 'partType'),

    orderItemLabel: (0, _emberMacaroni.joinWith)(' - ', 'partTypeOption.label', 'fitting.deviceReference')

  });
});