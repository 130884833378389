define('clever-sparky-frontend/routes/site/deleted-fittings/show/maintenance', ['exports', 'clever-sparky-frontend/routes/base-routes/deleted-fittings-tab'], function (exports, _deletedFittingsTab) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _deletedFittingsTab.default.extend({
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      // overriding setupController function of BaseFaultyFittingsTabRoute
      controller.set('maintenances', model.maintenances);
    }
  });
});