define('clever-sparky-frontend/abilities/site-permissions', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed;
  exports.default = _emberCan.Ability.extend({
    canModify: computed('model', function () {
      return this.get('model.isTechnician');
    })
  });
});