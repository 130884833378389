define('clever-sparky-frontend/controllers/test-event-group/fittings', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      computed = _ember.default.computed;
  exports.default = Controller.extend({

    testEventGroup: computed.alias('model.testEventGroup'),

    testEvent: computed.alias('model.testEvent'),

    testEventFittings: computed.alias('model.testEventFittings'),

    fitting: computed.alias('model.fitting'),

    queryParams: ['collected'],

    collected: false,

    displayAddFittingModal: false,

    displayQuickTestModal: false,

    isQuickTestPassModal: false,

    selectedTestEventFittings: [],

    isSelectingTestEventFittings: false,

    newTestEvent: computed.alias('model.newTestEvent'),

    duplicatedTestEvent: computed('newTestEvent', 'testEvent', function () {
      var newStartDate = (0, _moment.default)(this.get('testEvent.endDate')).add(1, 'days');
      var newEndDate = (0, _moment.default)(newStartDate).add(6, 'months');
      this.set('newTestEvent.startDate', newStartDate.format('YYYY-MM-DD'));
      this.set('newTestEvent.endDate', newEndDate.format('YYYY-MM-DD'));
      return this.get('newTestEvent');
    }),

    displayAddGroupsModal: false,

    displayRemoveGroupsModal: false,

    displayEditDateRangeModal: false,

    displayDuplicateTestEventModal: false,

    displayArchiveConfirmationModal: false,

    displayQuickTestDefaultsModal: false,

    actions: {
      setIsCollectedFilter: function setIsCollectedFilter() {
        if (!this.get('collected')) {
          this.set('collected', true);
        }
      },
      setIsNotCollectedFilter: function setIsNotCollectedFilter() {
        if (this.get('collected')) {
          this.set('collected', false);
        }
      },
      showAddFittingModal: function showAddFittingModal() {
        this.set('displayAddFittingModal', true);
      },
      closeAddFittingModal: function closeAddFittingModal() {
        this.set('displayAddFittingModal', false);
      },
      showQuickTestModal: function showQuickTestModal(isQuickTestPassModal, selectedTestEventFittings) {
        this.set('isQuickTestPassModal', isQuickTestPassModal);
        this.set('selectedTestEventFittings', selectedTestEventFittings);
        this.set('displayQuickTestModal', true);
      },
      closeQuickTestModal: function closeQuickTestModal() {
        this.set('displayQuickTestModal', false);
      },
      backToTestEventFittingsIndex: function backToTestEventFittingsIndex() {
        this.transitionToRoute('test-event-group.fittings');
      },
      showTestEventGroupDetails: function showTestEventGroupDetails(testEventGroup) {
        this.transitionToRoute('test-event-group', testEventGroup.id);
      },
      showAddGroupsModal: function showAddGroupsModal() {
        this.set('displayAddGroupsModal', true);
      },
      closeAddGroupsModal: function closeAddGroupsModal() {
        this.set('displayAddGroupsModal', false);
        this.set('selectedGroups', []);
      },
      showRemoveGroupsModal: function showRemoveGroupsModal() {
        this.set('displayRemoveGroupsModal', true);
      },
      closeRemoveGroupsModal: function closeRemoveGroupsModal() {
        this.set('displayRemoveGroupsModal', false);
        this.set('selectedGroups', []);
      },
      showEditDateRangeModal: function showEditDateRangeModal() {
        this.set('displayEditDateRangeModal', true);
      },
      closeEditDateRangeModal: function closeEditDateRangeModal() {
        this.set('displayEditDateRangeModal', false);
      },
      showDuplicateTestEventModal: function showDuplicateTestEventModal() {
        this.set('displayDuplicateTestEventModal', true);
      },
      closeDuplicateTestEventModal: function closeDuplicateTestEventModal() {
        this.set('displayDuplicateTestEventModal', false);
      },
      showArchiveConfirmationModal: function showArchiveConfirmationModal() {
        this.set('displayArchiveConfirmationModal', true);
      },
      closeArchiveConfirmationModal: function closeArchiveConfirmationModal() {
        this.set('displayArchiveConfirmationModal', false);
      },
      showQuickTestDefaultsModal: function showQuickTestDefaultsModal() {
        this.set('displayQuickTestDefaultsModal', true);
      },
      closeQuickTestDefaultsModal: function closeQuickTestDefaultsModal() {
        this.set('displayQuickTestDefaultsModal', false);
      }
    }

  });
});