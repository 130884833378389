define('clever-sparky-frontend/routes/groups', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'clever-sparky-frontend/mixins/show-nav-bar', 'clever-sparky-frontend/mixins/handle-error'], function (exports, _ember, _authenticatedRouteMixin, _showNavBar, _handleError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _showNavBar.default, _handleError.default, {
    model: function model(params) {
      var _this = this;

      var _modelFor = this.modelFor('application'),
          currentUser = _modelFor.currentUser,
          currentEmployment = _modelFor.currentEmployment;

      return new RSVP.Promise(function (resolve, reject) {
        _this.get('store').findRecord('group', params.group_id, { reload: true }).then(function (group) {
          RSVP.hash({
            group: group,
            currentUser: currentUser,
            currentEmployment: currentEmployment,
            site: group.get('site')
          }).then(function (model) {
            resolve(model);
          });
        }).catch(function (reason) {
          reject(reason);
        });
      });
    },
    afterModel: function afterModel(model, transition) {
      var _this2 = this;

      return new RSVP.Promise(function (resolve) {
        RSVP.hash({
          sitesGroups: model.site.get('groups'),
          testEvents: _this2.get('store').query('test-event', {
            site: model.site.get('id')
          })
        }).then(function (hash) {
          model.sitesGroups = hash.sitesGroups;
          model.testEvents = hash.testEvents;
          resolve();
          if (transition.targetName === 'groups.index') {
            _this2.transitionTo('groups.fittings');
          }
        });
      });
    },


    actions: {
      error: function error(reason) {
        // called when an error occurs in model query
        this.handleRailsError(reason);
        this.replaceWith('sites');
      },
      refreshRoute: function refreshRoute() {
        this.refresh();
      }
    }

  });
});