define('clever-sparky-frontend/components/validated-input', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed,
      defineProperty = _ember.default.defineProperty;
  exports.default = Component.extend({

    classNames: ['validated-input'],

    classNameBindings: ['showErrorClass:has-error', 'isValid:has-success', 'isHalfInput:half'],

    model: null,

    value: null,

    type: 'text',

    name: '',

    placeholder: '',

    validation: null,

    showValidations: false,

    didValidate: false,

    hasPasswordToggle: false,

    showPasswordToggle: computed.and('hasPasswordToggle', 'hasContent'),

    showPasswordText: computed('type', function () {
      return this.get('type') === 'password' ? 'Show' : 'Hide';
    }),

    notValidating: computed.not('validation.isValidating').readOnly(),

    hasContent: computed.notEmpty('value').readOnly(),

    hasWarnings: computed.notEmpty('validation.warnings').readOnly(),

    isValid: computed.and('hasContent', 'validation.isTruelyValid').readOnly(),

    shouldDisplayValidations: computed.or('showValidations', 'didValidate').readOnly(),

    showErrorClass: computed.and('notValidating', 'showErrorMessage', 'hasContent', 'validation').readOnly(),

    showErrorMessage: computed.and('shouldDisplayValidations', 'validation.isInvalid').readOnly(),

    showWarningMessage: computed.and('shouldDisplayValidations', 'hasWarnings', 'isValid').readOnly(),

    init: function init() {
      this._super.apply(this, arguments);
      var name = this.get('name');

      defineProperty(this, 'validation', computed.readOnly('model.validations.attrs.' + name));
      defineProperty(this, 'value', computed.alias('model.' + name));
    },


    actions: {
      toggleShowPassword: function toggleShowPassword() {
        if (this.get('type') === 'password') {
          this.set('type', 'text');
        } else {
          this.set('type', 'password');
        }
      }
    }
  });
});