define('clever-sparky-frontend/components/forms/fitting-details-form-rows', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend({

    inputsDisabled: computed.not('canEdit'),

    maxDate: (0, _moment.default)(),

    actions: {
      commission: function commission() {
        this.sendAction('commission');
      },
      lookupFittingInfo: function lookupFittingInfo() {
        this.sendAction('lookupFittingInfo');
      }
    }

  });
});