define('clever-sparky-frontend/routes/logout', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-local-storage'], function (exports, _ember, _authenticatedRouteMixin, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      inject = _ember.default.inject;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    session: inject.service('session'),
    sitetemp: (0, _emberLocalStorage.storageFor)('site'),
    orgtemp: (0, _emberLocalStorage.storageFor)('current-organisation'),
    beforeModel: function beforeModel() {
      this.get('session').deleteSessionAndInvalidate();
      this.set('sitetemp.site', null);
      this.set('orgtemp.id', null);
    }
  });
});