define('clever-sparky-frontend/components/forms/change-password-form', ['exports', 'ember', 'clever-sparky-frontend/mixins/toggle-label-focus', 'clever-sparky-frontend/validators/change-password-validator'], function (exports, _ember, _toggleLabelFocus, _changePasswordValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend(_toggleLabelFocus.default, _changePasswordValidator.ChangePasswordValidations, {
    didInsertElement: function didInsertElement() {
      this.toggleLabelFocus(this.$('#change-password-form'));
    },


    isQueryPending: false,

    currentPassword: computed(function () {
      return this.get('user.currentPassword');
    }),

    password: computed(function () {
      return this.get('user.password');
    }),

    passwordConfirmation: computed(function () {
      return this.get('user.passwordConfirmation');
    }),

    updateUser: function updateUser() {
      this.set('user.currentPassword', this.get('currentPassword'));
      this.set('user.password', this.get('password'));
      this.set('user.passwordConfirmation', this.get('passwordConfirmation'));
    },
    resetUser: function resetUser() {
      this.set('currentPassword', this.get('user.currentPassword'));
      this.set('password', this.get('user.password'));
      this.set('passwordConfirmation', this.get('user.passwordConfirmation'));
    },


    actions: {
      save: function save(user) {
        this.set('didValidate', true);

        if (this.get('validations.isValid')) {
          this.updateUser();
          this.set('isQueryPending', true);
          this.attrs.save(user);
        }
      }
    }
  });
});