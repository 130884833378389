define('clever-sparky-frontend/routes/base-routes/site-fittings-tab', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route;
  exports.default = Route.extend({
    model: function model() {
      return this.modelFor('site.fittings.show');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('fitting', model.fitting);
      controller.set('canEdit', false);
      this.controllerFor('site.fittings.show').set('canEdit', false);
    },


    actions: {
      edit: function edit() {
        this.controller.set('canEdit', true);
        this.controllerFor('site.fittings.show').set('canEdit', true);
      },
      cancel: function cancel() {
        this.controller.set('canEdit', false);
        this.controllerFor('site.fittings.show').set('canEdit', false);
      }
    }

  });
});