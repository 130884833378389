define('clever-sparky-frontend/controllers/site/shopping-cart', ['exports', 'ember', 'clever-sparky-frontend/mixins/handle-error'], function (exports, _ember, _handleError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      computed = _ember.default.computed,
      inject = _ember.default.inject;
  exports.default = Controller.extend(_handleError.default, {
    // Services

    downloadReport: inject.service(),

    // Attributes

    displayBatchDeleteConfirmationModal: false,

    isCheckingOut: false,

    selectedGroups: [],

    isQueryPending: false,

    displayDownloadReportSuccessModal: false,

    // Properties

    site: computed.alias('model.site'),

    currentOrderGroups: computed.alias('model.currentOrderGroups'),

    order: computed.alias('model.order'),

    currentUser: computed.alias('model.currentUser'),

    currentOrganisation: computed.alias('model.currentOrganisation'),

    currentEmployment: computed.alias('model.currentEmployment'),

    hasItemsToCheckout: computed.notEmpty('currentOrderGroups'),

    isViewingAsOrganisation: computed.notEmpty('currentOrganisation'),

    isViewingAsSelf: computed.not('isViewingAsOrganisation'),

    isOrgNotAuthenticatedWithWholesaler: computed.not('currentOrganisation.wholesalerAuthenticated'),

    cannotCheckoutAsOrganisation: computed.and('isViewingAsOrganisation', 'isOrgNotAuthenticatedWithWholesaler'),

    isUserNotAuthenticatedWithWholesaler: computed.not('currentUser.wholesalerAuthenticated'),

    cannotCheckoutAsSelf: computed.and('isViewingAsSelf', 'isUserNotAuthenticatedWithWholesaler'),

    hasCheckoutPermissionsForOrg: computed.and('isViewingAsOrganisation', 'currentEmployment.checkoutPermission'),

    hasPurchasingRights: computed.or('isViewingAsSelf', 'hasCheckoutPermissionsForOrg'),

    hasNoPurchasingRights: computed.not('hasPurchasingRights'),

    shouldRestrictCheckout: computed.or('cannotCheckoutAsOrganisation', 'cannotCheckoutAsSelf', 'hasNoPurchasingRights'),

    isCheckoutDisabled: computed.and('hasItemsToCheckout', 'shouldRestrictCheckout'),

    shouldDisplayInfoBanner: computed.alias('isCheckoutDisabled'),

    actions: {
      showOrderItems: function showOrderItems(group) {
        this.transitionToRoute('order-item-group', group.id);
      },
      closeCheckoutConfirmationModal: function closeCheckoutConfirmationModal() {
        this.set('displayCheckoutConfirmationModal', false);
        this.set('selectedGroups', []);
        this.set('isCheckingOut', false);
      },
      showCheckoutConfirmationModal: function showCheckoutConfirmationModal() {
        this.set('displayCheckoutConfirmationModal', true);
      },
      downloadCartReport: function downloadCartReport() {
        var _this = this;

        this.get('downloadReport').downloadOrderItemsReport(this.get('site'), this.get('order')).then(function () {
          _this.set('displayDownloadReportSuccessModal', true);
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        });
      },
      closeDownloadReportSuccessModal: function closeDownloadReportSuccessModal() {
        this.set('displayDownloadReportSuccessModal', false);
      }
    }
  });
});