define('clever-sparky-frontend/mixins/toggle-label-focus', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = _ember.default.Mixin,
      $ = _ember.default.$,
      run = _ember.default.run;
  exports.default = Mixin.create({

    _rowSelector: '.input',

    _inputSelector: 'input[type="text"], input[type="url"], input[type="number"], input[type="tel"], input[type="email"], input[type="password"], textarea',

    _applyFocusedClass: function _applyFocusedClass($input, rowSelector) {
      var apply = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

      $input.closest(rowSelector).toggleClass('focused', apply);
    },
    focusFilledInputs: function focusFilledInputs($form) {
      var _this = this;

      var $inputs = $form.find(this.get('_inputSelector'));

      var rowSelector = this.get('_rowSelector');

      $.each($inputs, function (index, value) {
        _this._applyFocusedClass($(value), rowSelector, !!$(value).val().length);
      });
    },
    toggleLabelFocus: function toggleLabelFocus($form) {
      run.scheduleOnce('afterRender', this, function () {
        var rowSelector = this.get('_rowSelector');
        var applyFocusedClass = this._applyFocusedClass;

        $form.find(this.get('_inputSelector')).off();

        $form.find(this.get('_inputSelector')).on('focus blur change', function (e) {
          applyFocusedClass($(this), rowSelector, e.type === 'focus' || !!this.value.length);
        });
      });
    }
  });
});