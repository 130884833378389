define('clever-sparky-frontend/components/batch-delete-error', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend({

    batchName: '',

    batchLength: null,

    uppercaseBatchName: computed('batchName', function () {
      return this.get('batchName').capitalize();
    })

  });
});