define('clever-sparky-frontend/controllers/onboarding', ['exports', 'ember', 'ember-macaroni'], function (exports, _ember, _emberMacaroni) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      computed = _ember.default.computed;
  exports.default = Controller.extend({

    // Attributes

    currentSlide: 0,

    lastSlideIndex: 2,

    // Properties

    isFirstSlide: computed.equal('currentSlide', 0),

    isLastSlide: (0, _emberMacaroni.isEqualByKeys)('currentSlide', 'lastSlideIndex'),

    actions: {
      next: function next() {
        this.incrementProperty('currentSlide');
      },
      previous: function previous() {
        this.decrementProperty('currentSlide');
      }
    }
  });
});