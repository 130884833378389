define('clever-sparky-frontend/controllers/invite', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      computed = _ember.default.computed;
  exports.default = Controller.extend({

    invite: computed.alias('model.invite'),

    organisation: computed.alias('model.organisation'),

    mod: computed.alias('model.data'),

    sitenametmp: computed.alias('model.sitename'),

    id: computed.alias('model.id'),

    displayTransferSitesModal: false,

    isQueryPending: false,

    isInviteAccepted: computed.equal('invite.status', 'accepted'),

    isInviteExpired: computed.equal('invite.status', 'expired'),

    isInvitePending: computed.equal('invite.status', 'pending'),

    isInviteToTransferSite: true,
    sitename: 'sitename',

    formattedOrganisations: computed.map('model.currentUsersAdminOrganisations', function (item) {
      return { label: item.get('name'), value: item.get('id') };
    }),

    availableOrganisations: computed.filter('formattedOrganisations', function (organisation) {
      return organisation.value !== this.get('currentOrganisation.id');
    }),

    displayOrganisations: computed('currentUser', 'currentOrganisation', function () {
      var displayOrgs = this.get('availableOrganisations');

      if (this.get('currentOrganisation')) {
        displayOrgs.unshift({ label: this.get('currentUser.fullName'), value: null });
      }

      return displayOrgs;
    }),

    hasOrganisationsToTransferTo: true,
    selectedAccount: null,

    actions: {
      openTransferSiteToUserModal: function openTransferSiteToUserModal() {
        this.set('displayTransferSitesModal', true);
      },
      closeTransferSiteToUserModal: function closeTransferSiteToUserModal() {
        this.set('displayTransferSitesModal', false);
      }
    }

  });
});