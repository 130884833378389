define('clever-sparky-frontend/services/invite-delete', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = _ember.default.Service,
      inject = _ember.default.inject,
      ENV = _ember.default.ENV;
  exports.default = Service.extend({
    requestService: inject.service('request-service'),

    _deleteMethod: 'DELETE',

    _inviteDeleteURL: function _inviteDeleteURL(inviteId) {
      return ENV.api.host + '/' + ENV.api.namespace + '/invites/' + inviteId;
    },
    delete: function _delete(inviteId) {
      var url = this._inviteDeleteURL(inviteId);
      var method = this.get('_deleteMethod');
      var data = null;

      return this.get('requestService').sendAuthenticatedRequest(url, method, data);
    }
  });
});