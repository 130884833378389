define('clever-sparky-frontend/controllers/groups/fittings/show', ['exports', 'ember', 'clever-sparky-frontend/controllers/fitting-panel-tab'], function (exports, _ember, _fittingPanelTab) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed;
  exports.default = _fittingPanelTab.default.extend({
    fitting: computed.alias('model.fitting'),

    group: computed.alias('model.group'),

    showDeleteModal: false,

    isQueryPending: false,

    showEdit: computed.not('canEdit'),

    showDelete: computed.not('canEdit'),

    actions: {
      delete: function _delete() {
        this.set('showDeleteModal', true);
      },
      closeDeleteModal: function closeDeleteModal() {
        this.set('showDeleteModal', false);
      }
    }

  });
});