define('clever-sparky-frontend/components/forms/test-and-status-form-rows', ['exports', 'ember', 'moment', 'clever-sparky-frontend/mixins/option-lookup'], function (exports, _ember, _moment, _optionLookup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component;
  exports.default = Component.extend(_optionLookup.default, {

    disabled: false,

    minDate: (0, _moment.default)(),

    maxDate: (0, _moment.default)()

  });
});