define('clever-sparky-frontend/components/forms/login-form', ['exports', 'ember', 'clever-sparky-frontend/mixins/toggle-label-focus', 'clever-sparky-frontend/validators/login-validator'], function (exports, _ember, _toggleLabelFocus, _loginValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component;
  exports.default = Component.extend(_toggleLabelFocus.default, _loginValidator.LoginValidations, {
    didInsertElement: function didInsertElement() {
      this.toggleLabelFocus(this.$('#login-form'));
    },


    email: '',
    password: '',

    actions: {
      validate: function validate(email, password) {
        this.set('didValidate', true);

        if (this.get('validations.isValid')) {
          this.authenticate(email, password);
        }
      },
      authenticate: function authenticate(email, password) {
        this.attrs.authenticate(email, password);
      }
    }
  });
});