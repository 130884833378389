define('clever-sparky-frontend/components/validated-multi-select', ['exports', 'clever-sparky-frontend/components/validated-input'], function (exports, _validatedInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = _validatedInput.default.extend({
    selection: [],

    selectionModel: '',

    displaySelection: false,

    canSelectAll: true,

    actions: {
      selectionsChanged: function selectionsChanged(_ref) {
        var _ref2 = _slicedToArray(_ref, 1),
            value = _ref2[0];

        var selection = this.get('selection');
        var selectionModel = this.get('selectionModel');

        if (value === 'all') {
          this.set('selection', ['All ' + selectionModel]);
          // if item is in selection and its the last one, reset to all groups
        } else if (selection.indexOf(value) !== -1 && selection.length === 1) {
          this.set('selection', ['All ' + selectionModel]);
        } else {
          selection.indexOf(value) === -1 ? selection.pushObject(value) : selection.removeObject(value);
          selection.removeObject('All ' + selectionModel);
        }

        return selection;
      },
      removeItem: function removeItem(item) {
        var selection = this.get('selection');
        return selection.length === 1 && this.get('canSelectAll') ? this.set('selection', ['All ' + this.get('selectionModel')]) : selection.removeObject(item);
      },
      toggleShowSelection: function toggleShowSelection() {
        this.toggleProperty('displaySelection');
      }
    }
  });
});