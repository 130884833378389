define('clever-sparky-frontend/models/access-request', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    // Relationships
    user: belongsTo('user'),

    site: belongsTo('site'),

    // actions
    approve: (0, _emberApiActions.memberAction)({
      path: 'approve',
      type: 'post'
    })

  });
});