define('clever-sparky-frontend/controllers/analytics', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      computed = _ember.default.computed;
  exports.default = Controller.extend({

    organisationAnalytics: computed.reads('model.organisationAnalytics'),

    fittingsCountData: computed('organisationAnalytics', function () {
      return { value: this.get('organisationAnalytics.fittingsCount'), total: this.get('organisationAnalytics.fittingsCount') };
    }),

    faultyFittingsCountData: computed('organisationAnalytics', function () {
      return { value: this.get('organisationAnalytics.faultyFittingsCount'), total: this.get('organisationAnalytics.fittingsCount') };
    }),

    upcomingTestsCountData: computed('organisationAnalytics', function () {
      return { value: this.get('organisationAnalytics.upcomingTestsCount'), total: this.get('organisationAnalytics.fittingsCount') };
    })

  });
});