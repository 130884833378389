define('clever-sparky-frontend/mixins/report-actions', ['exports', 'ember', 'clever-sparky-frontend/mixins/handle-error'], function (exports, _ember, _handleError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = _ember.default.Mixin,
      inject = _ember.default.inject;
  exports.default = Mixin.create(_handleError.default, {
    downloadReport: inject.service('downloadReport'),
    holdvaribles: inject.service('holdvaribles'),
    displayReportModal: false,
    displayReportSuccessModal: false,
    currentSite: null,
    init: function init() {
      this._super.apply(this, arguments);
      var site = this.get('holdvaribles').showCurrentSite();
      this.set('currentSite', site.data);
    },
    downloadGroupsReport: function downloadGroupsReport(groups, category, reportFormat, cstatebool, cstatement) {
      var _this = this;

      if (groups.includes('All Groups')) {
        groups = [];
      }
      this.get('downloadReport').downloadGroupsReport(this.get('site'), groups, category, reportFormat, cstatebool, cstatement).then(function () {
        _this.send('showReportSuccessModal');
      }).catch(function (reason) {
        _this.handleRailsError(reason);
      }).finally(function () {
        _this.set('isQueryPending', false);
      });
    },
    downloadTestEventReport: function downloadTestEventReport(testEvent, category, reportFormat, cstatebool, cstatement) {
      var _this2 = this;

      this.get('downloadReport').downloadTestEventReport(this.get('site'), testEvent, category, reportFormat, cstatebool, cstatement).then(function () {
        _this2.send('showReportSuccessModal');
      }).catch(function (reason) {
        _this2.handleRailsError(reason);
      }).finally(function () {
        _this2.set('isQueryPending', false);
      });
    },


    actions: {
      downloadReport: function downloadReport(reportContent, reportItems, category, reportFormat, cstatebool, cstatement) {
        if (reportContent === 'groups') {
          this.downloadGroupsReport(reportItems, category, reportFormat, cstatebool, cstatement);
        } else {
          this.downloadTestEventReport(reportItems, category, reportFormat, cstatebool, cstatement);
        }
      },
      toggleReportModal: function toggleReportModal() {
        this.toggleProperty('displayReportModal', true);
        this.set('displayReportSuccessModal', false);
      },
      showReportSuccessModal: function showReportSuccessModal() {
        this.set('displayReportSuccessModal', true);
      }
    }
  });
});