define('clever-sparky-frontend/components/forms/organisation-edit-form', ['exports', 'ember', 'clever-sparky-frontend/components/forms/organisation-form', 'clever-sparky-frontend/mixins/handle-error'], function (exports, _ember, _organisationForm, _handleError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = _ember.default.inject.service;
  var computed = _ember.default.computed,
      observer = _ember.default.observer,
      inject = _ember.default.inject;
  exports.default = _organisationForm.default.extend(_handleError.default, {
    // Services

    filename: '',
    features: inject.service(),
    imgupload: false,
    // Attributes

    isEditing: false,

    wholesalers: [],

    username: '',

    password: '',

    authorizableWholesalers: ['MMEM', 'L&H'],

    // Hooks

    didInsertElement: function didInsertElement() {
      this.toggleLabelFocus(this.$('#organisation-edit-form'));
    },


    // Properties

    isShoppingCartEnabled: computed(function () {
      return this.get('features').isEnabled('shopping-cart');
    }),

    inputsDisabled: computed.not('isEditing'),

    wholesaler: computed(function () {
      return this.get('organisation.wholesaler.content');
    }),

    cstatement: computed(function () {
      var text = this.get('organisation.cstatement');
      if (text === '' || text == null) {
        text = 'This Emergency lighting duration test report has been collected as per the record keeping requirements of AS/NZS2293.2 sections 2.7.2 & 2.7.3.\n' + 'The information contained in this report demonstrates compliance with the 6 monthly inspection and record keeping tasks listed in AS/NZS2293.2- Appendix A- Table A2- Schedule 2- Parts 2.1, 2.7, 2.8 and 2.10.';
      }

      return text;
    }),

    displayWholesalers: computed('wholesalers.[]', function () {
      var wholesalerObjects = this.get('wholesalers').map(function (wholesaler) {
        return { label: wholesaler.get('name'), value: wholesaler };
      });

      wholesalerObjects.push({ label: 'Other', value: null });

      return wholesalerObjects;
    }),

    hasSelectedWholesaler: computed.notEmpty('wholesaler'),

    isWholesalerAuthAllowed: computed('isShoppingCartEnabled', 'authorizableWholesalers.[]', 'wholesaler', function () {
      return this.get('isShoppingCartEnabled') && this.get('authorizableWholesalers').includes(this.get('wholesaler.name'));
    }),

    showWholesalerAuthFields: computed.and('hasSelectedWholesaler', 'isWholesalerAuthAllowed', 'isEditing'),

    hasUsername: computed.notEmpty('username'),

    hasPassword: computed.notEmpty('password'),

    hasEnteredAuthDetails: computed.and('hasUsername', 'hasPassword'),

    shouldPerformWholesalerAuth: computed.and('isWholesalerAuthAllowed', 'hasEnteredAuthDetails'),

    // Observers

    hasShownWholesalerAuthFields: observer('showWholesalerAuthFields', function () {
      if (this.get('showWholesalerAuthFields')) {
        this.toggleLabelFocus(this.$('#organisation-edit-form'));
      }
    }),

    // Methods

    updateOrganisation: function updateOrganisation() {
      this._super();

      this.set('organisation.wholesaler', this.get('wholesaler'));
      this.set('organisation.cstatement', this.get('cstatement'));
    },
    resetOrganisation: function resetOrganisation() {
      this._super();
      this.set('wholesaler', this.get('organisation.wholesaler.content'));
    },
    clearWholesalerAuthFields: function clearWholesalerAuthFields() {
      this.set('username', '');
      this.set('password', '');
    },

    signedRequest: service(),
    s3Upload: service(),

    actions: {
      edit: function edit() {
        this.set('isEditing', true);
      },
      cancel: function cancel() {
        this.resetOrganisation();
        this.set('isEditing', false);
        this.set('didValidate', false);
      },
      save: function save() {
        var _this = this;

        this.set('didValidate', true);

        if (this.get('validations.isValid')) {

          this.updateOrganisation();

          if (this.get('shouldPerformWholesalerAuth')) {
            this.attrs.authenticateWholesaler(this.get('wholesaler'), this.get('username'), this.get('password')).then(function (wholesalerAuthDetails) {
              _this.set('organisation.wholesalerAuthDetails', wholesalerAuthDetails);
              _this.attrs.save(_this.get('organisation'));
              _this.clearWholesalerAuthFields();
            }).catch(function (reason) {
              _this.handleRailsError({}, reason.error_description);
            });
          } else {
            this.attrs.save(this.get('organisation'));
          }
          this.set('isQueryPending', true);
        }
      }
    }
  });
});