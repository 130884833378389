define('clever-sparky-frontend/validators/forms/update-fitting-details', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.FittingValidations = undefined;
  var FittingValidations = exports.FittingValidations = (0, _emberCpValidations.buildValidations)({

    installDate: (0, _emberCpValidations.validator)('presence', true)
  });

  exports.default = { FittingValidations: FittingValidations };
});