define('clever-sparky-frontend/validators/site-validator', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.SiteValidations = undefined;
  var SiteValidations = exports.SiteValidations = (0, _emberCpValidations.buildValidations)({

    address: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please enter an address',
      ignoreBlank: true
    }),

    businessName: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please enter a business name',
      ignoreBlank: true
    })

  });

  exports.default = { SiteValidations: SiteValidations };
});