define('clever-sparky-frontend/routes/signup/user', ['exports', 'ember', 'clever-sparky-frontend/mixins/reset-scroll-position', 'ember-cli-uuid'], function (exports, _ember, _resetScrollPosition, _emberCliUuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP;
  exports.default = Route.extend(_resetScrollPosition.default, {
    model: function model() {
      return RSVP.hash({
        user: this.store.createRecord('user'),
        organisation: this.store.createRecord('organisation', {
          id: (0, _emberCliUuid.uuid)()
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var signupToken = this.paramsFor('signup').token;

      this.controllerFor('signup.user').set('token', signupToken);
    }
  });
});