define('clever-sparky-frontend/components/fitting-panelsite', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      observer = _ember.default.observer,
      computed = _ember.default.computed;
  exports.default = Component.extend({

    classNames: ['fitting-panel'],

    viewFittingPanelTourNames: ['viewFittingPanel'],

    showSuccessFlash: observer('showSuccess', function () {
      var _this = this;

      if (this.get('showSuccess')) {
        this.set('showSuccessConfirmation', true);
        setTimeout(function () {
          _this.set('showSuccessConfirmation', false);
          _this.set('showSuccess', false);
        }, 1500);
      }
    }),

    shouldShowFaultyIndicator: computed('type', 'fitting.faulty', function () {
      return this.get('type') !== 'test-event-fitting' && this.get('fitting.faulty');
    }),

    tabLinks: computed('type', function () {
      switch (this.get('type')) {
        case 'faults':
          return {
            fittingLink: 'site.faults.show.details',
            testAndStatusLink: 'site.faults.show.test-and-status',
            maintenanceLink: 'site.faults.show.maintenance'
          };
        case 'deleted-fittings':
          return {
            fittingLink: 'site.deleted-fittings.show.details',
            testAndStatusLink: 'site.deleted-fittings.show.test-and-status',
            maintenanceLink: 'site.deleted-fittings.show.maintenance'
          };
        case 'test-event-fitting':
          return {
            fittingLink: 'test-event-group.fittings.show.details',
            testAndStatusLink: 'test-event-group.fittings.show.test-and-status',
            maintenanceLink: 'test-event-group.fittings.show.maintenance'
          };
        default:
          return {
            fittingLink: 'site.fittings.show.details',
            testAndStatusLink: 'site.fittings.show.test-and-status',
            maintenanceLink: 'site.fittings.show.maintenance'
          };
      }
    }),

    actions: {
      edit: function edit() {
        this.sendAction('edit');
      },
      delete: function _delete() {
        this.sendAction('delete');
      },
      reinstate: function reinstate() {
        this.sendAction('reinstate');
      }
    }

  });
});