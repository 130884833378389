define('clever-sparky-frontend/controllers/test-event-group/fittings/show', ['exports', 'ember', 'clever-sparky-frontend/controllers/fitting-panel-tab'], function (exports, _ember, _fittingPanelTab) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed;
  exports.default = _fittingPanelTab.default.extend({

    showDeleteModal: false,

    isQueryPending: false,

    testEvent: computed.reads('model.testEvent'),

    isNotArchived: computed.not('testEvent.archived'),

    showEdit: computed.not('canEdit'),

    shouldShowEdit: computed.and('isNotArchived', 'showEdit'),

    showDelete: computed.not('canEdit'),

    shouldShowDelete: computed.and('isNotArchived', 'showDelete'),

    actions: {
      delete: function _delete() {
        this.set('showDeleteModal', true);
      },
      closeDeleteModal: function closeDeleteModal() {
        this.set('showDeleteModal', false);
      }
    }

  });
});