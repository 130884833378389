define('clever-sparky-frontend/services/session', ['exports', 'ember', 'ember-simple-auth/services/session'], function (exports, _ember, _session) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed,
      isEmpty = _ember.default.isEmpty,
      RSVP = _ember.default.RSVP,
      getOwner = _ember.default.getOwner,
      ENV = _ember.default.ENV,
      $ = _ember.default.$;
  exports.default = _session.default.extend({
    CurrentOrganisationStr: undefined,
    isLoggedIn: computed.alias('session.isAuthenticated'),

    createUser: function createUser() {
      var _this = this;

      // DS.Store.createRecord is a synchronous method, so we wrap it in a
      // promise to match the other promise-returning methods in this service.
      return new RSVP.Promise(function (resolve) {
        resolve(getOwner(_this).lookup('service:store').createRecord('user'));
      });
    },
    findUser: function findUser(userID) {
      return getOwner(this).lookup('service:store').findRecord('user', userID);
    },


    currentUser: computed('isLoggedIn', 'session.authenticated.session.user_id', function () {
      var userID = this.get('session.authenticated.session.user_id');
      var userAlreadyInStore = this.get('isLoggedIn') && !isEmpty(userID);

      return userAlreadyInStore ? this.findUser(userID) : this.createUser();
    }),

    setupCurrentOrganisation: function setupCurrentOrganisation(organisation) {
      this.set('CurrentOrganisationStr', organisation);
    },
    showCurrentOrganisation: function showCurrentOrganisation() {
      return this.get('CurrentOrganisationStr');
    },
    deleteSessionAndInvalidate: function deleteSessionAndInvalidate() {
      var _this2 = this;

      var session = this.get('session.authenticated.session');

      var sessionID = session.session_id;
      var token = session.token;


      return this._deleteSession(sessionID, token).then(function () {
        return _this2.invalidate();
      });
    },
    _deleteSession: function _deleteSession(sessionID, token) {
      var _this3 = this;

      return new RSVP.Promise(function (resolve, reject) {
        var url = _this3._deleteSessionURL(sessionID);

        return $.ajax({
          url: url,
          method: 'DELETE',
          headers: {
            'X-Session-Id': sessionID,
            'X-Session-Token': token
          },
          success: function success() {
            resolve();
          },
          error: function error() {
            reject();
          }
        });
      });
    },
    _deleteSessionURL: function _deleteSessionURL(sessionID) {
      return ENV.api.host + '/' + ENV.api.namespace + '/sessions/' + sessionID;
    }
  });
});