define('clever-sparky-frontend/services/reinstate-fitting', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = _ember.default.Service,
      inject = _ember.default.inject,
      ENV = _ember.default.ENV;
  exports.default = Service.extend({
    requestService: inject.service('request-service'),

    _reinstateMethod: 'POST',

    _reinstateURL: function _reinstateURL(fittingId) {
      return ENV.api.host + '/' + ENV.api.namespace + '/fittings/' + fittingId + '/reinstate';
    },
    reinstate: function reinstate(fitting) {
      var url = this._reinstateURL(fitting.get('id'));
      var method = this.get('_reinstateMethod');
      var data = {};

      return this.get('requestService').sendAuthenticatedRequest(url, method, data);
    }
  });
});