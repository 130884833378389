define('clever-sparky-frontend/validators/organisation-validator', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.OrganisationValidations = undefined;
  var OrganisationValidations = exports.OrganisationValidations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please enter your business name',
      ignoreBlank: true
    }),

    country: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please select your country',
      ignoreBlank: true
    }),

    state: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please select your state',
      ignoreBlank: true
    })

  });

  exports.default = { OrganisationValidations: OrganisationValidations };
});