define('clever-sparky-frontend/components/checkout-confirmation', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend({
    // Attributes

    isQueryPending: false,

    classNames: ['checkout-confirmation'],

    // Properties

    batchLength: computed.alias('batch.length'),

    actions: {
      checkout: function checkout(batch) {
        this.set('isQueryPending', true);
        this.attrs.confirmCheckout(batch);
      }
    }

  });
});