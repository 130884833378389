define('clever-sparky-frontend/adapters/fitting', ['exports', 'ember', 'clever-sparky-frontend/adapters/application'], function (exports, _ember, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ENV = _ember.default.ENV;
  exports.default = _application.default.extend({
    urlForFindRecord: function urlForFindRecord() {
      return this._super.apply(this, arguments) + '?include=latest-test,maintenances,group';
    },
    urlForQuery: function urlForQuery(query) {
      var url = '';

      if (query.allfittingsb === 'allfittings') {
        url = ENV.api.host + '/' + ENV.api.namespace + '/sites/' + query.siteid + '/allfittings';
      }
      if (query.group) {
        url = ENV.api.host + '/' + ENV.api.namespace + '/groups/' + query.group + '/fittings';
      } else {
        if (query.type === 'faulty-fittings') {
          url = ENV.api.host + '/' + ENV.api.namespace + '/sites/' + query.site + '/faulty-fittings';
        } else if (query.type === 'deleted-fittings') {
          url = ENV.api.host + '/' + ENV.api.namespace + '/sites/' + query.site + '/deleted-fittings';
        }
      }
      return url;
    }
  });
});