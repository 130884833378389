define('clever-sparky-frontend/services/invite-accepttransfer', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = _ember.default.Service,
      inject = _ember.default.inject,
      ENV = _ember.default.ENV;
  exports.default = Service.extend({
    requestService: inject.service('request-service'),

    _acceptMethod: 'POST',

    _inviteAcceptURL: function _inviteAcceptURL(inviteId) {
      return ENV.api.host + '/' + ENV.api.namespace + '/invitetransfer/' + inviteId + '/accepttransfer';
    },
    accepttransfer: function accepttransfer(invite, id, newOwnerId) {
      var url = this._inviteAcceptURL(id);
      var method = this.get('_acceptMethod');
      var newOrgId = newOwnerId;
      var data = {
        data: {
          type: 'invites',
          attributes: {
            id: id,
            newOrgId: newOrgId
          }
        }
      };

      return this.get('requestService').sendAuthenticatedRequest(url, method, data);
    }
  });
});