define('clever-sparky-frontend/components/shopping-cart-dropdown-menu', ['exports', 'ember', 'clever-sparky-frontend/components/dropdown-menu'], function (exports, _ember, _dropdownMenu) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed;
  exports.default = _dropdownMenu.default.extend({

    clickOutsideParentElementSelector: '.shopping-cart-dropdown-menu',

    classNames: ['dropdown-menu', 'shopping-cart-dropdown-menu'],

    hasNoOrderItems: computed.empty('currentOrderGroups'),

    actions: {
      downloadCartReport: function downloadCartReport() {
        this.attrs.downloadCartReport();
        this.send('close');
      }
    }
  });
});