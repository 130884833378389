define('clever-sparky-frontend/routes/users', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'clever-sparky-frontend/mixins/show-nav-bar', 'clever-sparky-frontend/mixins/handle-error', 'ember-infinity/mixins/route', 'ember-local-storage', 'ember-can', 'clever-sparky-frontend/mixins/setup-tour-sequence'], function (exports, _ember, _authenticatedRouteMixin, _showNavBar, _handleError, _route, _emberLocalStorage, _emberCan, _setupTourSequence) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP,
      inject = _ember.default.inject;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _route.default, _showNavBar.default, _handleError.default, _emberCan.CanMixin, _setupTourSequence.default, {
    // Services

    inviteDelete: inject.service('invite-delete'),

    viewUsersTour: inject.service('tours/view-users'),

    features: inject.service(),

    // Local storage

    currentOrganisation: (0, _emberLocalStorage.storageFor)('current-organisation'),

    perPageParam: 'page[size]', // instead of 'per_page'

    pageParam: 'page[number]', // instead of 'page'

    totalPagesParam: 'meta.total-pages', // instead of "meta.total_pages"

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      var _modelFor = this.modelFor('application'),
          currentEmployment = _modelFor.currentEmployment;

      if (!this.can('view user-management', currentEmployment)) {
        this.replaceWith('sites');
      }
      if (this.get('features').isEnabled('guidedTour')) {
        return RSVP.hash({
          viewUsersTooltipEvent: this.setupTourSequence('view_users_list_web', this.get('viewUsersTour'))
        });
      }
    },
    model: function model() {
      var _modelFor2 = this.modelFor('application'),
          currentOrganisation = _modelFor2.currentOrganisation;

      var currentOrganisationId = currentOrganisation ? currentOrganisation.get('id') : null;

      return RSVP.hash({
        organisation: currentOrganisation,
        employments: this.infinityModel('employment', {
          perPage: 100,
          startingPage: 1,
          modelPath: 'controller.model.employments',
          currentOrganisationId: currentOrganisationId
        }),
        invite: this.store.createRecord('invite', {
          organisation: currentOrganisation
        })
      });
    },


    actions: {
      invite: function invite(_invite) {
        var _this = this;

        _invite.set('organisation', this.currentModel.organisation);
        _invite.save().then(function () {
          _this.refresh();
          _this.controller.set('displayInviteUserModal', false);
          _this.showSuccessFlash('Invitation Sent');
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        }).finally(function () {
          _this.controller.set('isQueryPending', false);
        });
      },
      showUserDetail: function showUserDetail(employment) {
        if (employment.get('user.id')) {
          this.transitionTo('user', employment.id);
        }
      },
      cancelInvite: function cancelInvite(invite) {
        var _this2 = this;

        this.get('inviteDelete').delete(invite.get('id')).then(function () {
          _this2.refresh();
          _this2.showSuccessFlash('Invitation Cancelled');
        }).catch(function (reason) {
          _this2.handleRailsError(reason);
        }).finally(function () {
          _this2.controller.set('isQueryPending', false);
        });
      },
      changeUserType: function changeUserType(employment) {
        var _this3 = this;

        employment.save().then(function () {
          _this3.showSuccessFlash('User Type has been updated');
        }).catch(function (reason) {
          employment.rollbackAttributes();
          _this3.handleRailsError(reason);
        }).finally(function () {
          _this3.controller.set('isQueryPending', false);
          _this3.refresh();
        });
      }
    }

  });
});