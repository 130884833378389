define('clever-sparky-frontend/routes/site', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'clever-sparky-frontend/mixins/show-nav-bar', 'clever-sparky-frontend/mixins/handle-error'], function (exports, _ember, _authenticatedRouteMixin, _showNavBar, _handleError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP,
      inject = _ember.default.inject;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _showNavBar.default, _handleError.default, {
    holdvaribles: inject.service('holdvaribles'),
    model: function model(params) {
      var _modelFor = this.modelFor('application'),
          currentUser = _modelFor.currentUser,
          currentEmployment = _modelFor.currentEmployment;

      var testevents = this.get('store').query('test-event', {
        site: params.site_id
      });
      this.get('holdvaribles').setupAllTestEvents(testevents);
      return RSVP.hash({
        currentUser: currentUser,
        currentEmployment: currentEmployment,
        site: this.get('store').findRecord('site', params.site_id, { reload: true })
      });
    },
    afterModel: function afterModel(model, transition) {
      var _this = this;

      return new RSVP.Promise(function (resolve) {

        RSVP.hash({
          sitesGroups: model.site.get('groups'),
          testEvents: _this.get('store').query('test-event', {
            site: model.site.get('id')
          })
        }).then(function (hash) {
          model.sitesGroups = hash.sitesGroups;
          model.testEvents = hash.testEvents;

          resolve();
          if (transition.targetName === 'site.index') {
            _this.transitionTo('site.fittings');
          }
        });
      });
    },


    actions: {
      error: function error(reason) {
        // called when an error occurs in model query
        this.handleRailsError(reason);
        this.replaceWith('sites');
      },
      refreshRoute: function refreshRoute() {
        this.refresh();
      }
    }

  });
});