define('clever-sparky-frontend/controllers/order-item-group/order-items', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      computed = _ember.default.computed;
  exports.default = Controller.extend({

    // Attributes
    displayBatchDeleteConfirmationModal: false,

    isDeletingItems: false,

    selectedItems: [],

    isQueryPending: false,

    displayCheckoutConfirmationModal: false,

    // Properties
    orderItems: computed.alias('model.orderItems'),

    site: computed.alias('model.site'),

    group: computed.alias('model.group'),

    batchLength: computed.alias('selectedItems.length'),

    selectedGroup: computed('group', function () {
      return [this.get('group')];
    }),

    currentUser: computed.alias('model.currentUser'),

    currentOrganisation: computed.alias('model.currentOrganisation'),

    currentEmployment: computed.alias('model.currentEmployment'),

    hasItemsToCheckout: computed.notEmpty('orderItems'),

    isViewingAsOrganisation: computed.notEmpty('currentOrganisation'),

    isViewingAsSelf: computed.not('isViewingAsOrganisation'),

    isOrgNotAuthenticatedWithWholesaler: computed.not('currentOrganisation.wholesalerAuthenticated'),

    cannotCheckoutAsOrganisation: computed.and('isViewingAsOrganisation', 'isOrgNotAuthenticatedWithWholesaler'),

    isUserNotAuthenticatedWithWholesaler: computed.not('currentUser.wholesalerAuthenticated'),

    cannotCheckoutAsSelf: computed.and('isViewingAsSelf', 'isUserNotAuthenticatedWithWholesaler'),

    hasCheckoutPermissionsForOrg: computed.and('isViewingAsOrganisation', 'currentEmployment.checkoutPermission'),

    hasPurchasingRights: computed.or('isViewingAsSelf', 'hasCheckoutPermissionsForOrg'),

    hasNoPurchasingRights: computed.not('hasPurchasingRights'),

    shouldRestrictCheckout: computed.or('cannotCheckoutAsOrganisation', 'cannotCheckoutAsSelf', 'hasNoPurchasingRights'),

    isCheckoutDisabled: computed.and('hasItemsToCheckout', 'shouldRestrictCheckout'),

    shouldDisplayInfoBanner: computed.alias('isCheckoutDisabled'),

    actions: {
      batchDeleteItems: function batchDeleteItems() {
        this.set('displayBatchDeleteConfirmationModal', true);
      },
      closeBatchDeleteConfirmationModal: function closeBatchDeleteConfirmationModal() {
        this.set('displayBatchDeleteConfirmationModal', false);
        this.set('selectedItems', []);
        this.set('isDeletingItems', false);
      },
      closeCheckoutConfirmationModal: function closeCheckoutConfirmationModal() {
        this.set('displayCheckoutConfirmationModal', false);
      },
      showCheckoutConfirmationModal: function showCheckoutConfirmationModal() {
        this.set('displayCheckoutConfirmationModal', true);
      }
    }
  });
});