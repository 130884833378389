define('clever-sparky-frontend/services/file-upload', ['exports', 'ember', 'clever-sparky-frontend/uploaders/csv', 'clever-sparky-frontend/mixins/handle-error'], function (exports, _ember, _csv, _handleError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = _ember.default.Service,
      computed = _ember.default.computed,
      ENV = _ember.default.ENV,
      run = _ember.default.run,
      inject = _ember.default.inject,
      RSVP = _ember.default.RSVP;
  exports.default = Service.extend(_handleError.default, {
    // Services

    session: inject.service('session'),

    // Attributes

    fileName: '',

    uploadErrors: [],

    requestProgress: 0,

    intervalProgress: 0,

    prepareProgress: 0,

    intervalDuration: 15, // ms

    flashDuration: 2500, // ms

    uploadStatus: 'waiting',

    // Properties

    isAborted: computed.equal('uploadStatus', 'aborted'),

    isError: computed.equal('uploadStatus', 'error'),

    isFinished: computed.equal('uploadStatus', 'finished'),

    isProgressFinished: computed.equal('progress', 100),

    isPreparedProgressFinished: computed.equal('prepareProgress', 100),

    progress: computed('requestProgress', 'intervalProgress', 'prepareProgress', function () {
      var progress = 0;

      if (this.get('prepareProgress') > 0) {
        progress += this.get('prepareProgress') * 0.2;
      }
      if (this.get('requestProgress') > 0) {
        progress += this.get('requestProgress') * (this.get('intervalProgress') / this.get('requestProgress')) * 0.8;
      }
      return progress;
    }),

    fittingsEndpoint: computed(function () {
      return ENV.api.host + '/' + ENV.api.namespace + '/fittings-uploads';
    }),

    // Methods
    upload: function upload(file, site) {
      var _this = this;

      return new RSVP.Promise(function (resolve, reject) {
        _this.set('site', site);
        _this.preparingUpload(file, resolve, reject);
      });
    },


    /*
     * uploadSuccessful and uploadFail must be callbacks
     */
    preparingUpload: function preparingUpload(file, uploadSuccessful, uploadFail) {
      this.set('fileName', file.name);
      this.set('uploadStatus', 'preparing');
      this.schedulePrepare(file, uploadSuccessful, uploadFail);
    },
    schedulePrepare: function schedulePrepare(file, uploadSuccessful, uploadFail) {
      run.later(this, function () {
        this.checkUploadReady(file, uploadSuccessful, uploadFail);
      }, this.get('intervalDuration'));
    },
    checkUploadReady: function checkUploadReady(file, uploadSuccessful, uploadFail) {
      if (this.get('isAborted')) {
        this.reset();
      } else if (!this.get('isPreparedProgressFinished')) {
        this.incrementProperty('prepareProgress');
        this.schedulePrepare(file, uploadSuccessful, uploadFail);
      } else {
        this.uploadFiles(file, uploadSuccessful, uploadFail);
      }
    },
    uploadFiles: function uploadFiles(file, uploadSuccessful, uploadFail) {
      var _this2 = this;

      var uploader = this.createUploader(this.get('fittingsEndpoint'));
      uploader.on('progress', function (e) {
        _this2.set('requestProgress', e.percent);
      });

      this.set('uploadStatus', 'uploading');
      this.scheduleIntervalProgress(uploadSuccessful);

      uploader.upload(file, {
        site: this.get('site'),
        credentials: this.get('session.data.authenticated.session')
      }).then(function () {
        _this2.set('uploadStatus', 'finished');

        if (_this2.get('isProgressFinished')) {
          window.location.reload(true);
          uploadSuccessful();
        }
      }).catch(function (reason) {
        _this2.set('uploadStatus', 'error');
        var errorMessages = reason.responseJSON.errors || reason;
        uploadFail(errorMessages);
      });
    },
    createUploader: function createUploader(url) {
      return _csv.default.create({
        url: url
      });
    },
    scheduleIntervalProgress: function scheduleIntervalProgress(uploadSuccessful) {
      run.later(this, function () {
        if (this.get('intervalProgress') < 100) {
          this.tickInterval(uploadSuccessful);
        } else if (this.get('isFinished')) {
          uploadSuccessful();
        }
      }, this.get('intervalDuration'));
    },
    tickInterval: function tickInterval(uploadSuccessful) {
      this.set('intervalProgress', this.get('intervalProgress') + 1);
      this.scheduleIntervalProgress(uploadSuccessful);
    },
    reset: function reset() {
      this.set('fileName', '');
      this.set('uploadErrors', []);
      this.set('requestProgress', 0);
      this.set('prepareProgress', 0);
      this.set('intervalProgress', 0);
      this.set('uploadStatus', 'waiting');
    },
    cancelUpload: function cancelUpload() {
      this.set('uploadStatus', 'aborted');
    }
  });
});