define('clever-sparky-frontend/components/report-modal-delete', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      inject = _ember.default.inject;
  exports.default = Component.extend({
    store: inject.service(),

    classNames: ['report-modal-delete'],

    selectedTemplate: null,

    actions: {
      closeModal: function closeModal() {
        this.sendAction('closeModal');
      },
      deleteTemplate: function deleteTemplate() {
        var temp1 = this.get('selectedTemplate');

        var temp = this.get('store').peekRecord('template', temp1.id);
        temp.destroyRecord();
        this.sendAction('closeModal');
      }
    }

  });
});