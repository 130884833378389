define('clever-sparky-frontend/adapters/user', ['exports', 'ember', 'clever-sparky-frontend/adapters/application'], function (exports, _ember, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ENV = _ember.default.ENV;
  exports.default = _application.default.extend({
    urlForFindRecord: function urlForFindRecord() {
      return this.host + '/' + this.namespace + '/users/me';
    },
    urlForUpdateRecord: function urlForUpdateRecord() {
      return this.host + '/' + this.namespace + '/users/me';
    },
    urlForDeleteRecord: function urlForDeleteRecord() {
      return this.host + '/' + this.namespace + '/users/me';
    },
    urlForQuery: function urlForQuery(query) {
      var url = ENV.api.host + '/' + ENV.api.namespace + '/organisations/' + query.currentOrganisationId + '/users';

      if (query.role && query.currentOrganisationId) {
        url = ENV.api.host + '/' + ENV.api.namespace + '/organisations/' + query.currentOrganisationId + '/users?filter[role]=' + query.role;
      }

      return url;
    }
  });
});