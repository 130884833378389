define('clever-sparky-frontend/mixins/setup-tour-sequence', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = _ember.default.Mixin,
      inject = _ember.default.inject;
  exports.default = Mixin.create({

    // services

    store: inject.service(),

    // methods

    setupTourSequence: function setupTourSequence(eventName, tour) {
      var _this = this;

      eventName = eventName.dasherize();

      return this.store.query('tooltip-event', {
        filter: {
          'event-name': eventName
        }
      }).then(function (tooltipEvents) {
        if (!tooltipEvents.get('length')) {
          tour.setup();
          _this.createTooltipEvent(eventName);
        }
      }).catch(function () {});
    },
    createTooltipEvent: function createTooltipEvent(eventName) {
      var tooltipEvent = this.store.createRecord('tooltip-event', {
        eventName: eventName
      });
      tooltipEvent.save();

      return tooltipEvent;
    },
    deactivateTour: function deactivateTour(tour) {
      tour.deactivate();
    }
  });
});