define('clever-sparky-frontend/routes/site/faults', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-infinity/mixins/route', 'clever-sparky-frontend/mixins/handle-error'], function (exports, _ember, _authenticatedRouteMixin, _route, _handleError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP,
      inject = _ember.default.inject;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _route.default, _handleError.default, {
    // Services

    batchDeleteOrderItems: inject.service(),

    // Attributes

    perPageParam: 'page[size]', // instead of 'per_page'

    pageParam: 'page[number]', // instead of 'page'

    totalPagesParam: 'meta.total-pages', // instead of "meta.total_pages"

    model: function model() {
      var _modelFor = this.modelFor('site'),
          site = _modelFor.site;

      return RSVP.hash({
        site: site,
        faults: this.infinityModel('fitting', {
          site: site.id,
          type: 'faulty-fittings',
          perPage: 10,
          startingPage: 1,
          modelPath: 'controller.model.faults'
        })
      });
    },


    actions: {
      showFittingDetails: function showFittingDetails(fitting) {
        this.transitionTo('site.faults.show.details', fitting.id);
      },
      createOrderItem: function createOrderItem(fitting, partType) {
        var _this = this;

        var orderItem = this.store.createRecord('order-item', {
          partType: partType,
          fitting: fitting
        });
        orderItem.save().then(function () {
          _this.currentModel.site.reload();
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        });
      },
      deleteOrderItem: function deleteOrderItem(fitting, partType) {
        var _this2 = this;

        var batch = [{
          partType: partType,
          fitting: fitting
        }];
        this.get('batchDeleteOrderItems').deleteBatch(batch).then(function () {
          _this2.currentModel.site.reload();
        }).catch(function (reason) {
          _this2.handleRailsError(reason);
        });
      },
      refreshRoute: function refreshRoute() {
        this.refresh();
      }
    }

  });
});