define('clever-sparky-frontend/routes/edit', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'clever-sparky-frontend/mixins/show-nav-bar'], function (exports, _ember, _authenticatedRouteMixin, _showNavBar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP,
      inject = _ember.default.inject;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _showNavBar.default, {
    session: inject.service('session'),

    model: function model() {
      return RSVP.hash({
        wholesalers: this.store.findAll('wholesaler', { include: 'wholesalerAuthResource' }),
        currentEmployment: this.modelFor('application').currentEmployment
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('wholesalers', model.wholesalers);
      controller.set('currentEmployment', model.currentEmployment);
    }
  });
});