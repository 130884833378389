define('clever-sparky-frontend/controllers/site/test-events', ['exports', 'ember', 'ember-local-storage'], function (exports, _ember, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      computed = _ember.default.computed,
      inject = _ember.default.inject;
  exports.default = Controller.extend({
    testeventx: (0, _emberLocalStorage.storageFor)('test-event'),
    holdvaribles: inject.service('holdvaribles'),

    testEvents: computed.alias('model.testEvents'),

    testEvent: computed.alias('model.testEvent'),

    selectedTestEvents: [],

    isDeletingTestEvents: false,

    displayAddTestEventModal: false,

    isQueryPending: false,

    displayBatchDeleteConfirmationModal: false,

    batchLength: computed.alias('selectedTestEvents.length'),

    actions: {
      openAddTestEventModal: function openAddTestEventModal() {
        this.set('displayAddTestEventModal', true);
      },
      closeAddTestEventModal: function closeAddTestEventModal() {
        this.set('displayAddTestEventModal', false);
      },
      showTestEvent: function showTestEvent(testEvent) {
        this.set('testeventx.testevent', testEvent);
        this.set('testeventx.id', testEvent.id);
        this.get('holdvaribles').setupCurrentTestEvent(testEvent);
        if (testEvent.data.viewb === 'allfittings') {
          this.get('holdvaribles').setupCurrentViewsetting('allfittings');
          this.transitionToRoute('test-event-group', testEvent.id);
        } else {
          this.get('holdvaribles').setupCurrentViewsetting('group');
          this.transitionToRoute('test-event', testEvent.id);
        }
      },
      closeBatchDeleteConfirmationModal: function closeBatchDeleteConfirmationModal() {
        this.set('displayBatchDeleteConfirmationModal', false);
        this.set('selectedTestEvents', []);
        this.set('isDeletingTestEvents', false);
      },
      batchDeleteTestEvents: function batchDeleteTestEvents(selectedTestEvents) {
        this.set('selectedTestEvents', selectedTestEvents);
        this.set('displayBatchDeleteConfirmationModal', true);
      }
    }

  });
});