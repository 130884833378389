define('clever-sparky-frontend/validators/forms/ledtest', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.LEDTestValidations = undefined;
  var LEDTestValidations = exports.LEDTestValidations = (0, _emberCpValidations.buildValidations)({
    testStatus: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
      in: ['valid_pass', 'test_active', 'failed_unknown', 'failed_battery', 'failed_lamp', 'failed_control', 'failed_no_output'],
      message: 'Test status can be one of either Valid Pass, Test Active, Failed Unknown, Failed Battery, Failed Lamp, Failed Control or Failed No Output'
    })],
    testDate: (0, _emberCpValidations.validator)('presence', true),
    runTime: (0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true,
      gte: 0,
      lte: 254
    }),
    requiredDuration: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: [30, 90, 120],
      message: 'Required duration can be either 90 or 120'
    }),
    batteryStatus: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: ['ok', 'faulty'],
      message: 'Battery status can be either OK or Faulty'
    }),
    lampStatus: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: ['ok', 'faulty'],
      message: 'Lamp status can be either OK or Faulty'
    }),
    controlGearStatus: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: ['ok', 'faulty'],
      message: 'Control gear status can be either OK or Faulty'
    })
  });

  exports.default = { LEDTestValidations: LEDTestValidations };
});