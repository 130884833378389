define('clever-sparky-frontend/mixins/click-outside', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = _ember.default.Mixin;
  exports.default = Mixin.create({

    clickOutsideParentElementSelector: '',

    bindOnClickOutside: function bindOnClickOutside() {
      this.set('boundOnClickOutside', function (e) {
        if (this.$(e.target).closest(this.get('clickOutsideParentElementSelector')).length === 0) {
          this.onClickOutside();
        }
      }.bind(this));
    },
    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);
      this.bindOnClickOutside();
      window.addEventListener('click', this.get('boundOnClickOutside'), false);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      window.removeEventListener('click', this.get('boundOnClickOutside'), false);
    }
  });
});