define('clever-sparky-frontend/routes/test-event-group', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'clever-sparky-frontend/mixins/show-nav-bar', 'clever-sparky-frontend/mixins/handle-error', 'ember-local-storage'], function (exports, _ember, _authenticatedRouteMixin, _showNavBar, _handleError, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP,
      inject = _ember.default.inject;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _showNavBar.default, _handleError.default, {
    holdvaribles: inject.service('holdvaribles'),
    testeventx: (0, _emberLocalStorage.storageFor)('test-event'),
    sitetmp: (0, _emberLocalStorage.storageFor)('site'),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },
    model: function model(params) {
      var _this = this;

      var _modelFor = this.modelFor('application'),
          currentUser = _modelFor.currentUser,
          currentEmployment = _modelFor.currentEmployment;

      var testevent = null;

      testevent = this.get('holdvaribles').showCurrentTestEvent();
      if (testevent == null) {

        var cat = this.get('testeventx');
        var testeventxx = cat.content.testevent;

        testevent = {
          data: testeventxx
        };
        if (testevent.data.viewb === 'allfittings') {
          return new RSVP.Promise(function (resolve) {
            RSVP.hash({

              testEvent: testevent
            }).then(function (model) {
              resolve(model);
            });
          });
        } else {

          return new RSVP.Promise(function (resolve, reject) {
            _this.get('store').findRecord('test-event-group', params.test_event_group_id, { reload: true }).then(function (testEventGroup) {
              RSVP.hash({
                testEventGroup: testEventGroup,
                currentUser: currentUser,
                currentEmployment: currentEmployment,
                testEvent: testEventGroup.get('testEvent')
              }).then(function (model) {
                resolve(model);
              });
            }).catch(function (reason) {

              reject(reason);
            });
          });
        }
      } else {

        if (testevent.data.viewb === 'allfittings') {
          return new RSVP.Promise(function (resolve) {
            RSVP.hash({

              testEvent: testevent
            }).then(function (model) {
              resolve(model);
            });
          });
        } else {

          return new RSVP.Promise(function (resolve, reject) {
            _this.get('store').findRecord('test-event-group', params.test_event_group_id, { reload: true }).then(function (testEventGroup) {
              RSVP.hash({
                testEventGroup: testEventGroup,
                currentUser: currentUser,
                currentEmployment: currentEmployment,
                testEvent: testEventGroup.get('testEvent')
              }).then(function (model) {
                resolve(model);
              });
            }).catch(function (reason) {

              reject(reason);
            });
          });
        }
      }
    },
    afterModel: function afterModel(model, transition) {
      var _this2 = this;

      var testevent = this.get('holdvaribles').showCurrentTestEvent();

      if (testevent == null) {

        var cat = this.get('testeventx');
        var testeventxx = cat.content.testevent;

        testevent = {
          data: testeventxx
        };
      }
      var allgroups = this.get('store').peekAll('group');
      var alltestevents = this.get('store').peekAll('test-event');
      if (testevent.data.viewb === 'allfittings') {
        this.get('holdvaribles').setupallfittingpagebool(true);

        return new RSVP.Promise(function (resolve) {
          model.testEvent.get('site').then(function (site) {
            RSVP.hash({
              site: site,
              sitesGroups: allgroups.content,
              testEvents: alltestevents.content
            }).then(function (hash) {
              model.site = hash.site;
              model.sitesGroups = hash.sitesGroups;
              model.testEvents = hash.testEvents;
              resolve();
              if (transition.targetName === 'test-event-group.index') {
                _this2.transitionTo('test-event-group.fittings');
              }
            });
          });
        });
      } else {
        this.get('holdvaribles').setupallfittingpagebool(false);

        return new RSVP.Promise(function (resolve) {
          model.testEvent.get('site').then(function (site) {
            RSVP.hash({
              site: site,
              sitesGroups: site.get('groups'),
              testEvents: _this2.get('store').query('test-event', {
                site: site.get('id')
              })
            }).then(function (hash) {
              model.site = hash.site;
              model.sitesGroups = hash.sitesGroups;
              model.testEvents = hash.testEvents;
              resolve();
              if (transition.targetName === 'test-event-group.index') {
                _this2.transitionTo('test-event-group.fittings');
              }
            });
          });
        });
      }
    },


    actions: {
      error: function error(reason) {
        // called when an error occurs in model query
        this.handleRailsError(reason);
        this.replaceWith('sites');
      },
      refreshRoute: function refreshRoute() {
        this.refresh();
      }
    }

  });
});