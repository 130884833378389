define('clever-sparky-frontend/controllers/test-event-group/fittings/show/test-and-status', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      computed = _ember.default.computed;
  exports.default = Controller.extend({

    displayAddLatestTestModal: false,

    shouldShowSeeLatestTest: computed('fittingLatestTest', 'latestTest', 'testEventFitting', function () {
      return !!this.get('fittingLatestTest') && !!this.get('latestTest.id') && (this.get('fittingLatestTest.id') !== this.get('latestTest.id') || !!this.get('testEventFitting.testOutOfRange'));
    }),

    actions: {
      showLatestTestModal: function showLatestTestModal() {
        this.set('displayAddLatestTestModal', true);
      },
      closeLatestTestModal: function closeLatestTestModal() {
        this.set('displayAddLatestTestModal', false);
      }
    }

  });
});