define('clever-sparky-frontend/adapters/template', ['exports', 'ember', 'clever-sparky-frontend/adapters/application'], function (exports, _ember, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ENV = _ember.default.ENV;
  exports.default = _application.default.extend({
    urlForQuery: function urlForQuery(query) {
      return ENV.api.host + '/' + ENV.api.namespace + '/templates/' + query.user_id;
    }
  });
});