define('clever-sparky-frontend/components/site-header', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed,
      ENV = _ember.default.ENV;
  exports.default = Component.extend({

    // Attributes

    uploadStatus: null,

    progress: 0,

    fileName: '',

    csvTemplatePath: ENV.csvTemplatePath,

    viewSiteTourNames: [],

    // Properties

    noGroups: computed.equal('site.groupsCount', 0),

    businessName: computed.reads('site.businessName')

  });
});