define('clever-sparky-frontend/validators/forms/invite-user', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.InviteUserValidations = undefined;
  var InviteUserValidations = exports.InviteUserValidations = (0, _emberCpValidations.buildValidations)({

    email: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please enter your email address',
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email',
      message: 'Please enter a valid email'
    })]

  });

  exports.default = { InviteUserValidations: InviteUserValidations };
});