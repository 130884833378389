define('clever-sparky-frontend/instance-initializers/ember-useragent', ['exports', 'ember-useragent/instance-initializers/ember-useragent'], function (exports, _emberUseragent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _emberUseragent.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _emberUseragent.initialize;
    }
  });
});