define('clever-sparky-frontend/mixins/handle-error', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = _ember.default.Mixin,
      inject = _ember.default.inject;
  exports.default = Mixin.create({
    flashMessages: inject.service('flashMessages'),

    handleRailsError: function handleRailsError(response) {
      var defaultMessage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

      var message = defaultMessage;

      var errorMessages = response.errors || response;

      if (errorMessages.length) {
        message = errorMessages[0].detail;
      }

      this.showErrorFlash(message);
    },
    showErrorFlash: function showErrorFlash(message) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (!message) {
        message = 'An error occurred performing this action.';
      }

      this.get('flashMessages').danger(message, options);
    },
    showSuccessFlash: function showSuccessFlash(message) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (!message) {
        message = 'Success.';
      }

      this.get('flashMessages').success(message, options);
    }
  });
});