define('clever-sparky-frontend/ajax-requests/service', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = _ember.default.Service,
      inject = _ember.default.inject,
      ENV = _ember.default.ENV,
      RSVP = _ember.default.RSVP;
  exports.default = Service.extend({
    holdvaribles: inject.service('holdvaribles'),
    ajax: _ember.default.inject.service(),
    _fittingLookupMethod: 'POST',
    getUrl: function getUrl(fileName, fileType) {
      var _this = this;

      return new RSVP.Promise(function (resolve) {

        var url = ENV.api.host + '/api/v1/signed_request';

        var bucketbool = _this.get('holdvaribles').showCurrentPhotoUploadSignatureBool();
        var tmpvar = '';
        if (bucketbool) {
          tmpvar = 'signature';
        } else {
          tmpvar = 'logo';
        }

        _this.get('ajax').post(url, { method: 'POST', data: { file: fileName, type: fileType, bucket: tmpvar } }).then(function (response) {
          // `response` is the data from the server

          return response;
        }).catch(function (error) {
          resolve(error);
        });
      });
    }
  });
});