define('clever-sparky-frontend/models/order', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    // Relationships
    site: belongsTo('site'),

    orderItems: hasMany('orderItems'),

    // Attributes
    checkedOut: attr('boolean'),

    orderItemsCount: attr('number')
  });
});