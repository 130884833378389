define('clever-sparky-frontend/models/group', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    // Relationships
    site: belongsTo('site'),

    fittings: hasMany('fitting'),

    // Attributes
    name: attr('string'),

    fittingsCount: attr('number'),

    faultyFittingsCount: attr('number'),

    createdAt: attr('date'),

    commissionedFittingsCount: attr('number'),

    notCommissionedFittingsCount: attr('number'),

    // Properties
    hasFittings: computed.gte('fittingsCount', 1),

    canDelete: computed.not('hasFittings')

  });
});