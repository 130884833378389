define('clever-sparky-frontend/services/batch-move-fittings', ['exports', 'ember', 'clever-sparky-frontend/services/batch-move'], function (exports, _ember, _batchMove) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ENV = _ember.default.ENV;
  exports.default = _batchMove.default.extend({
    _groupDeleteURL: function _groupDeleteURL(groupId) {
      return ENV.api.host + '/' + ENV.api.namespace + '/groups/' + groupId + '/relationships/fittings';
    },


    _type: 'fittings'
  });
});