define('clever-sparky-frontend/controllers/application', ['exports', 'ember', 'ember-local-storage'], function (exports, _ember, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      computed = _ember.default.computed;
  exports.default = Controller.extend({

    queryParams: ['organisation-id'],

    'organisation-id': null,

    invite: (0, _emberLocalStorage.storageFor)('invite'),

    currentUser: computed.alias('model.currentUser'),

    currentEmployment: computed.alias('model.currentEmployment'),

    currentOrganisation: computed.reads('model.currentOrganisation'),

    currentUsersOrganisations: computed.reads('model.currentUsersOrganisations'),

    hasCurrentOrganisations: computed.gt('currentUsersOrganisations.length', 0),

    hasJustLoggedIn: false,

    hasJustSignedUp: false,

    isNotComingFromEmail: computed.empty('organisation-id'),

    hasNotComeFromInvite: computed.empty('invite.id'),

    shouldShowSwitchAccountModal: computed.and('hasJustLoggedIn', 'isNotComingFromEmail', 'hasNotComeFromInvite', 'hasCurrentOrganisations'),

    displaySwitchAccountModal: false,

    showNavBar: false,

    isMobileDevice: computed.not('media.isDesktop'),

    Maintenance: false,

    // showScreenSizeWarning: computed.and('isMobileDevice', 'showNavBar'),
    showScreenSizeWarning: false,

    selectedAccount: computed('currentOrganisation', function () {
      var currentOrganisation = this.get('currentOrganisation');
      return currentOrganisation ? currentOrganisation.get('id') : null;
    }),

    formattedOrganisations: computed.map('currentUsersOrganisations', function (item) {
      return { label: item.get('name'), value: item.get('id') };
    }),

    displayOrganisations: computed('currentOrganisation', function () {
      var displayOrgs = this.get('formattedOrganisations');

      displayOrgs.unshift({ label: this.get('currentUser.fullName'), value: null });
      return displayOrgs;
    }),

    actions: {
      openSwitchAccountModal: function openSwitchAccountModal() {
        this.set('displaySwitchAccountModal', true);
      },
      closeSwitchAccountModal: function closeSwitchAccountModal() {
        this.set('displaySwitchAccountModal', false);
        this.set('hasJustLoggedIn', false);
      },
      transitionAction: function transitionAction(routeName) {
        this.transitionToRoute(routeName);
      },
      resetTooltips: function resetTooltips() {
        this.send('resetTooltipEvents');
      }
    }
  });
});