define('clever-sparky-frontend/components/maintenance-row', ['exports', 'ember', 'moment', 'clever-sparky-frontend/mixins/toggle-label-focus', 'clever-sparky-frontend/validators/forms/maintenance', 'clever-sparky-frontend/mixins/handle-error', 'clever-sparky-frontend/mixins/option-lookup'], function (exports, _ember, _moment, _toggleLabelFocus, _maintenance, _handleError, _optionLookup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend(_maintenance.MaintenanceValidations, _toggleLabelFocus.default, _handleError.default, _optionLookup.default, {

    // Attributes

    canEdit: false,

    didValidate: false,

    maintenance: null,

    showUpdateFittingModal: false,

    showDeleteMaintenanceModal: false,

    isQueryPending: false,

    maxDate: (0, _moment.default)(),

    repairDate: null,

    repair: '',

    notes: '',

    testEvent: null,

    testEventFitting: null,

    // Properties

    inputsDisabled: computed.not('canEdit'),

    isTestEventContextAndNotArchived: computed.not('testEvent.archived'),

    showEdit: computed.and('save', 'inputsDisabled', 'isTestEventContextAndNotArchived'),

    shouldShowTestEventWarning: computed('repairDate', 'testEvent.startDate', 'testEvent.endDate', function () {
      var repairDate = this.get('repairDate');
      var startDate = this.get('testEvent.startDate');
      var endDate = this.get('testEvent.endDate');
      return repairDate && startDate && !(0, _moment.default)(repairDate).isBetween(startDate, endDate, null, '[]');
    }),

    // Hooks

    init: function init() {
      this._super.apply(this, arguments);
      this.setStaticAttributes(this.get('maintenance'));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.toggleLabelFocus(this.$('.maintenance-row'));
    },


    // Methods

    setStaticAttributes: function setStaticAttributes(maintenance) {
      this.set('repairDate', maintenance.get('repairDate'));
      this.set('repair', maintenance.get('repair'));
      this.set('notes', maintenance.get('notes'));
    },
    updateMaintenance: function updateMaintenance() {
      this.set('maintenance.repairDate', this.get('repairDate'));
      this.set('maintenance.repair', this.get('repair'));
      this.set('maintenance.notes', this.get('notes'));
    },
    resetMaintenance: function resetMaintenance() {
      this.set('repairDate', this.get('maintenance.repairDate'));
      this.set('repair', this.get('maintenance.repair'));
      this.set('notes', this.get('maintenance.notes'));
    },


    actions: {
      edit: function edit() {
        this.set('canEdit', true);
      },
      save: function save() {
        this.set('didValidate', true);

        if (this.get('validations.isValid')) {
          if (this.get('repair') === 'fitting') {
            this.set('showUpdateFittingModal', true);
          } else {
            this.updateMaintenance();
            this.set('isQueryPending', true);
            this.attrs.save(this.get('maintenance'));
            this.set('canEdit', false);
          }
        }
      },
      showDeleteMaintenanceModal: function showDeleteMaintenanceModal() {
        this.set('showDeleteMaintenanceModal', true);
      },
      closeDeleteMaintenanceModal: function closeDeleteMaintenanceModal() {
        this.set('showDeleteMaintenanceModal', false);
      },
      delete: function _delete() {
        var _this = this;

        this.set('isQueryPending', true);
        this.get('maintenance').destroyRecord().then(function () {
          _this.set('canEdit', false);
          _this.set('showDeleteMaintenanceModal', false);
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        }).finally(function () {
          _this.set('isQueryPending', false);
        });
      },
      update: function update(fitting) {
        var _this2 = this;

        if (this.get('testEvent')) {
          fitting.set('testEvent', this.get('testEvent'));
        }
        fitting.save().then(function (fitting) {
          _this2.set('canEdit', false);
          _this2.set('showUpdateFittingModal', false);
          if (_this2.get('testEventFitting')) {
            var currentTestEventFittings = fitting.get('testEventFittings').toArray().map(function (testEventFitting) {
              return testEventFitting.id;
            });
            if (!currentTestEventFittings.includes(_this2.get('testEventFitting.id'))) {
              // testEventFitting no longer exists i.e. case where fitting.installDate is updated to outside test event range
              _this2.updateMaintenance();
              _this2.attrs.save(_this2.get('maintenance'), true);
            }
          } else {
            _this2.updateMaintenance();
            _this2.attrs.save(_this2.get('maintenance'));
          }
        }).catch(function (reason) {
          _this2.handleRailsError(reason);
        }).finally(function () {
          _this2.set('isQueryPending', false);
        });
      },
      closeUpdateFittingModal: function closeUpdateFittingModal() {
        this.get('fitting').rollbackAttributes();
        this.set('repair', '');
        this.set('showUpdateFittingModal', false);
      },
      cancel: function cancel() {
        this.set('canEdit', false);
        this.set('didValidate', false);
        this.resetMaintenance();
      }
    }

  });
});