define('clever-sparky-frontend/components/fault-table', ['exports', 'ember', 'clever-sparky-frontend/mixins/handle-error'], function (exports, _ember, _handleError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed,
      inject = _ember.default.inject,
      observer = _ember.default.observer,
      $ = _ember.default.$;
  exports.default = Component.extend(_handleError.default, {
    // Services

    features: inject.service(),

    // Attributes

    isOrderingReplacements: false,

    isSelectAllFittings: null,

    isSelectAllComponents: null,

    isSelectAllDecals: null,

    // Properties

    hasNoRecords: computed.equal('faultyFittings.length', 0),

    isShoppingCartEnabled: computed(function () {
      return this.get('features').isEnabled('shoppingCart');
    }),

    // Observers

    isSelectAllFittingsObserver: observer('isSelectAllFittings', function () {
      if (this.get('isSelectAllFittings')) {
        $.each($('.replacement-fitting-option .replacement-option-button .button.unselected'), function (index, replacementOptionButton) {
          replacementOptionButton.click();
        });
      } else if (this.get('isSelectAllFittings') === false) {
        $.each($('.replacement-fitting-option .replacement-option-button .button.selected'), function (index, replacementOptionButton) {
          replacementOptionButton.click();
        });
      }
    }),

    isSelectAllComponentsObserver: observer('isSelectAllComponents', function () {
      if (this.get('isSelectAllComponents')) {
        $.each($('.replacement-faulty-component-option .replacement-option-button .button.unselected:visible'), function (index, replacementOptionButton) {
          replacementOptionButton.click();
        });
      } else if (this.get('isSelectAllComponents') === false) {
        $.each($('.replacement-faulty-component-option .replacement-option-button .button.selected:visible'), function (index, replacementOptionButton) {
          replacementOptionButton.click();
        });
      }
    }),

    isSelectAllDecalsObserver: observer('isSelectAllDecals', function () {
      if (this.get('isSelectAllDecals')) {
        $.each($('.replacement-decal-option .replacement-option-button .button.unselected'), function (index, replacementOptionButton) {
          replacementOptionButton.click();
        });
      } else if (this.get('isSelectAllDecals') === false) {
        $.each($('.replacement-decal-option .replacement-option-button .button.selected'), function (index, replacementOptionButton) {
          replacementOptionButton.click();
        });
      }
    }),

    actions: {
      showFitting: function showFitting(fitting) {
        this.sendAction('showFittingDetails', fitting);
      },
      showOrderReplacements: function showOrderReplacements() {
        this.attrs.backToFaultFittingsIndex();
        this.set('isOrderingReplacements', true);
      },
      hideOrderReplacements: function hideOrderReplacements() {
        this.set('isOrderingReplacements', false);
        this.set('isSelectAllFittings', null);
        this.set('isSelectAllComponents', null);
        this.set('isSelectAllDecals', null);
        this.showSuccessFlash('Cart successfully updated', { timeout: 1000 });
        this.attrs.refreshRoute();
      }
    }

  });
});