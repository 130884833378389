define('clever-sparky-frontend/validators/forms/report-form', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ReportValidations = undefined;
  var computed = _ember.default.computed;
  var ReportValidations = exports.ReportValidations = (0, _emberCpValidations.buildValidations)({

    selectedTestEvent: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: computed.not('model.isSelectingTestEvents'),
      message: 'Please select a test event',
      ignoreBlank: true
    }),

    selectedGroups: (0, _emberCpValidations.validator)('array-length', {
      min: 1,
      disabled: computed.not('model.isSelectingGroups'),
      dependentKeys: ['model.selectedGroups.[]'],
      message: 'Please select one or more groups'
    })

  });

  exports.default = { ReportValidations: ReportValidations };
});