define('clever-sparky-frontend/components/dropdown-menu', ['exports', 'ember', 'clever-sparky-frontend/mixins/click-outside', 'clever-sparky-frontend/mixins/escape-keypress'], function (exports, _ember, _clickOutside, _escapeKeypress) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      ENV = _ember.default.ENV,
      $ = _ember.default.$,
      computed = _ember.default.computed,
      inject = _ember.default.inject;
  exports.default = Component.extend(_clickOutside.default, _escapeKeypress.default, {

    // Services

    features: inject.service(),

    // Attributes

    triggerContent: '',

    clickOutsideParentElementSelector: '.dropdown-menu',

    isOpen: false,

    csvTemplatePath: ENV.csvTemplatePath,

    dropdownMenuTourNames: ['userDropdownMenu', 'userDropdownMenuTechnician'],

    // properties

    isGuidedTourEnabled: computed(function () {
      return this.get('features').isEnabled('guided-tour');
    }),

    // Methods

    onClickOutside: function onClickOutside() {
      if (!$('#shepherdOverlay').is(':visible')) {
        // don't close dropdown menu when user-dropdown-menu tour is active
        this.send('close');
      }
    },
    onEscKeypress: function onEscKeypress() {
      this.send('close');
    },


    actions: {
      trigger: function trigger() {
        this.set('isOpen', true);
      },
      close: function close() {
        this.set('isOpen', false);
      },
      transitionTo: function transitionTo(routeName) {
        this.attrs.transitionAction(routeName);
        this.send('close');
      },
      openSwitchAccountModal: function openSwitchAccountModal() {
        this.attrs.openSwitchAccountModal();
        this.send('close');
      },
      resetTooltips: function resetTooltips() {
        this.attrs.resetTooltips();
        this.send('close');
      }
    }
  });
});