define('clever-sparky-frontend/serializers/user', ['exports', 'clever-sparky-frontend/serializers/application', 'ember-data-save-relationships'], function (exports, _application, _emberDataSaveRelationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberDataSaveRelationships.default, {
    attrs: {
      organisations: { serialize: true }
    },
    datatmp: '',
    normalizeSaveResponse: function normalizeSaveResponse(store, modelName, obj) {
      var rels = obj.data.relationships;

      var gettmp = this.get('datatmp');
      // Guard against null relationships allowd by JSON/api
      Object.keys(rels).forEach(function (rel) {
        if (!rel || !rel.data) {
          delete rels[rel];
        }
      });

      obj.data.relationships = rels;
      if (gettmp.data.attributes.editpage == null) {
        this.set('datatmp', '');
        return this._super(store, modelName, obj);
      } else {

        obj.data.attributes.editpage = null;
        obj.data.attributes = gettmp.data.attributes;
        this.set('datatmp', '');
        return this._super(store, modelName, obj);
      }
    },


    userInfoFields: ['first-name', 'last-name', 'email', 'phone', 'country', 'state', 'wholesaler-auth-details', 'admin', 'postcode', 'rfn', 'abn', 'qbcc', 'reportphone', 'editpage', 'imgurl', 'signatureurl', 'sigfilename'],
    passwordFields: ['password', 'password-confirmation', 'current-password'],

    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      json = this.filterAttributes(snapshot, json);
      //  if (json.data.attributes.editpage == true){

      this.set('datatmp', json);
      //  }

      return json;
    },
    serializeRelationship: function serializeRelationship(snapshot, data, rel) {

      var relKind = rel.kind;
      var relKey = rel.key;

      if (this.get('attrs.' + relKey + '.serialize') === true) {

        data.relationships = data.relationships || {};
        var key = this.keyForRelationship(relKey, relKind, 'serialize');
        data.relationships[key] = data.relationships[key] || {};

        if (relKind === 'belongsTo') {
          data.relationships[key].data = this.serializeRecord(snapshot.belongsTo(relKey));
        }

        if (relKind === 'hasMany') {
          data.relationships[key].data = {};
          if (snapshot.hasMany(relKey)) {
            data.relationships[key].data = snapshot.hasMany(relKey).map(this.serializeRecord);
          }
        }
      }
    },
    filterAttributes: function filterAttributes(snapshot, json) {
      if (!snapshot.record.get('isNew')) {
        if (snapshot.changedAttributes()['password']) {
          var gettmp = this.get('datatmp');
          if (gettmp) {
            if (gettmp.data.attributes.editpage == true) {
              json = this.filterUserInfoAttributes(json);
            } else {
              json = this.filterPasswordAttributes(json);
            }
          } else {
            json = this.filterPasswordAttributes(json);
          }
        } else {

          json = this.filterUserInfoAttributes(json);
        }
      }
      return json;
    },
    filterUserInfoAttributes: function filterUserInfoAttributes(json) {
      var roadblock = this.get('userInfoFields');

      if (json.data.attributes.imgurl === 'https://cleversparkyusermedia.s3.amazonaws.com/') {
        var filteredItems = roadblock.filter(function (item) {
          return item !== 'imgurl';
        });
        return this.filterAttributesByWhitelist(json, filteredItems);
      }

      if (json.data.attributes.signatureurl === 'https://cleversparkysignatures.s3.amazonaws.com/') {
        var _filteredItems = roadblock.filter(function (item) {
          return item !== 'signatureurl';
        });
        return this.filterAttributesByWhitelist(json, _filteredItems);
      }

      return this.filterAttributesByWhitelist(json, roadblock);
    },
    filterPasswordAttributes: function filterPasswordAttributes(json) {
      return this.filterAttributesByWhitelist(json, this.get('passwordFields'));
    },
    filterAttributesByWhitelist: function filterAttributesByWhitelist(json, whitelist) {
      var attrs = json.data.attributes;
      for (var key in attrs) {
        if (!whitelist.includes(key)) {
          delete attrs[key];
        }
      }
      return json;
    },


    // eslint-disable-next-line no-unused-vars
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var json = this._super.apply(this, arguments);

      return json;
    }
  });
});