define('clever-sparky-frontend/services/request-service', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = _ember.default.Service,
      RSVP = _ember.default.RSVP,
      inject = _ember.default.inject,
      $ = _ember.default.$;
  exports.default = Service.extend({

    session: inject.service(),

    _contentType: 'application/json',

    sendRequest: function sendRequest(url, method) {
      var _this = this;

      var data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var headers = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

      return new RSVP.Promise(function (resolve, reject) {
        _this._ajax(url, method, data, headers, resolve, reject);
      });
    },
    sendAuthenticatedRequest: function sendAuthenticatedRequest(url, method, data) {
      var credentials = this.get('session.data.authenticated');

      var headers = {
        'X-Session-id': credentials.session.session_id,
        'X-Session-Token': credentials.session.token
      };

      return this.sendRequest(url, method, data, headers);
    },
    onSuccess: function onSuccess(result) {
      return result;
    },
    _ajax: function _ajax(url, method, data, headers, resolve, reject) {
      var _this2 = this;

      if (this.get('_contentType') === 'application/json') {
        data = JSON.stringify(data);
      }
      return $.ajax({
        url: url,
        contentType: this.get('_contentType'),
        headers: headers,
        method: method,
        data: data,
        success: function success(result) {
          result = _this2.onSuccess(result);
          resolve(result);
        },
        error: function error(_error) {
          reject(_error.responseJSON);
        }
      });
    }
  });
});