define('clever-sparky-frontend/components/forms/invite-to-site-form', ['exports', 'ember', 'clever-sparky-frontend/validators/forms/invite-to-site-form-validator'], function (exports, _ember, _inviteToSiteFormValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend(_inviteToSiteFormValidator.InviteToSiteFormValidations, {
    technicianId: null,

    isQueryPending: false,

    didValidate: false,

    grantPermissionType: 'copy-site-permission',

    isCopyingSitePermission: computed.equal('grantPermissionType', 'copy-site-permission'),

    notCurrentTechnicians: computed.filter('technicians', function (technician) {
      return technician !== this.get('user');
    }),

    displayTechnicians: computed.map('notCurrentTechnicians', function (technician) {
      return { label: technician.get('fullName'), value: technician.get('id') };
    }),

    actions: {
      grantAccess: function grantAccess() {
        this.set('didValidate', true);

        if (this.get('validations.isValid')) {
          this.set('isQueryPending', true);

          if (this.get('isCopyingSitePermission')) {
            this.attrs.copyPermissions(this.get('currentOrganisation'), this.get('technicianId'), this.get('user.id'));
          } else {
            this.attrs.grantAccess(this.get('user'), this.get('selectedSites'));
          }
        }
      },
      selectionTypeChanged: function selectionTypeChanged() {
        this.set('didValidate', false);
      }
    }
  });
});