define('clever-sparky-frontend/services/batch-create-ledtests', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = _ember.default.Service,
      inject = _ember.default.inject,
      ENV = _ember.default.ENV;
  exports.default = Service.extend({
    requestService: inject.service('request-service'),

    _type: 'tests',

    _batchCreateMethod: 'POST',

    _batchCreateURL: function _batchCreateURL() {
      return ENV.api.host + '/' + ENV.api.namespace + '/tests';
    },
    createLedTests: function createLedTests(batch, testAttributes) {
      var url = this._batchCreateURL();
      var method = this.get('_batchCreateMethod');
      var type = this.get('_type');

      var data = {
        data: []
      };

      batch.forEach(function (item) {
        var testData = {
          type: type,
          attributes: {
            'test-status': testAttributes.testStatus,
            'test-date': testAttributes.testDate,
            'run-time': testAttributes.runTime,
            'next-test-date': testAttributes.nextTestDate,
            'required-duration': testAttributes.requiredDuration
          },
          relationships: {
            fitting: {
              data: {
                type: 'fittings',
                id: item.get('fitting.id')
              }
            }
          }
        };
        data.data.push(testData);
      });

      return this.get('requestService').sendAuthenticatedRequest(url, method, data);
    }
  });
});