define('clever-sparky-frontend/controllers/user', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      computed = _ember.default.computed;
  exports.default = Controller.extend({

    user: computed.alias('model.user'),

    employment: computed.alias('model.employment'),

    technicians: computed.alias('model.technicians'),

    currentOrganisation: computed.alias('model.currentOrganisation'),

    usersRestrictedSites: computed.alias('model.usersRestrictedSites'),

    sitePermissions: computed.alias('model.sitePermissions'),

    selectedSites: [],

    displayDeleteUserModal: false,

    displayInviteToSitesModal: false,

    isQueryPending: false,

    viewUserDetailsTourNames: ['viewUserDetails'],

    actions: {
      showDeleteUserModal: function showDeleteUserModal() {
        this.set('displayDeleteUserModal', true);
      },
      closeDeleteUserModal: function closeDeleteUserModal() {
        this.set('displayDeleteUserModal', false);
      },
      showInviteToSitesModal: function showInviteToSitesModal() {
        this.set('displayInviteToSitesModal', true);
      },
      closeInviteToSitesModal: function closeInviteToSitesModal() {
        this.set('selectedSites', []);
        this.set('displayInviteToSitesModal', false);
      }
    }

  });
});