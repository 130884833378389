define('clever-sparky-frontend/validators/change-password-validator', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ChangePasswordValidations = undefined;
  var ChangePasswordValidations = exports.ChangePasswordValidations = (0, _emberCpValidations.buildValidations)({
    currentPassword: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please enter your current password'
    }), (0, _emberCpValidations.validator)('length', {
      min: 8,
      message: 'Password must be at least 8 characters long'
    })],

    password: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please enter your password'
    }), (0, _emberCpValidations.validator)('length', {
      min: 8,
      message: 'Password must be at least 8 characters long'
    })],

    passwordConfirmation: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please confirm your password'
    }), (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: 'Your passwords don’t match, please try again '
    })]
  });

  exports.default = { ChangePasswordValidations: ChangePasswordValidations };
});