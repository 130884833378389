define('clever-sparky-frontend/components/tours/sequence-element', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed,
      getOwner = _ember.default.getOwner;
  exports.default = Component.extend({

    // class attributes

    classNames: 'sequence-element',

    classNameBindings: ['stopClassName'],

    // attributes

    tourNames: [],

    stopName: '',

    // properties

    stopClassName: computed('stopName', function () {
      return 'sequence-element-' + this.get('stopName').dasherize();
    }),

    // hooks

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('tourNames').forEach(function (tourName) {
        var tour = getOwner(_this).lookup('service:tours/' + tourName.dasherize());
        tour.setHasRenderedSequenceElement(_this.get('stopName').dasherize());
      });
    },
    didDestroyElement: function didDestroyElement() {
      var _this2 = this;

      this._super.apply(this, arguments);
      this.get('tourNames').forEach(function (tourName) {
        var tour = getOwner(_this2).lookup('service:tours/' + tourName.dasherize());
        tour.resetHasRenderedSequenceElement(_this2.get('stopName').dasherize());
      });
    }
  });
});