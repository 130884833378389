define('clever-sparky-frontend/routes/test-event-group/fittings/show', ['exports', 'ember', 'clever-sparky-frontend/mixins/handle-error'], function (exports, _ember, _handleError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP;
  exports.default = Route.extend(_handleError.default, {
    model: function model(params) {
      var _this = this;

      var _modelFor = this.modelFor('test-event-group'),
          testEvent = _modelFor.testEvent;

      return new RSVP.Promise(function (resolve) {
        _this.get('store').findRecord('test-event-fitting', params.test_event_fitting_id, { reload: true }).then(function (testEventFitting) {
          RSVP.hash({
            testEvent: testEvent,
            testEventFitting: testEventFitting,
            fitting: _this.get('store').findRecord('fitting', testEventFitting.get('fitting.id'), { reload: true }),
            testEventGroup: testEventFitting.get('testEventGroup'),
            latestTest: testEventFitting.get('latestTest'),
            newTest: _this.store.createRecord('ledtest'),
            maintenances: testEventFitting.get('maintenances'),
            newMaintenance: _this.store.createRecord('maintenance')
          }).then(function (model) {
            resolve(model);
          });
        });
      });
    },
    afterModel: function afterModel(model) {
      return new RSVP.Promise(function (resolve) {
        RSVP.hash({
          group: model.testEventGroup.get('group'),
          fittingLatestTest: model.fitting.get('deletedAt') ? null : model.fitting.get('latestTest')
        }).then(function (hash) {
          model.group = hash.group;
          model.fittingLatestTest = hash.fittingLatestTest;
          resolve();
        });
      });
    },


    actions: {
      returnToTestEventFittings: function returnToTestEventFittings() {
        this.controller.set('showDeleteModal', false);
        this.transitionTo('test-event-group.fittings');
      },
      deleteFitting: function deleteFitting(fitting) {
        var _this2 = this;

        fitting.destroyRecord().then(function () {
          fitting.unloadRecord();
          _this2.showSuccessFlash('Fitting Deleted');
          // refresh the top most parent route
          _this2.send('refreshRoute');
          _this2.send('returnToTestEventFittings');
        }).catch(function (reason) {
          _this2.handleRailsError(reason);
        }).finally(function () {
          _this2.controllerFor('test-event-group').send('refreshRoute');
          _this2.controller.set('isQueryPending', false);
        });
      }
    }

  });
});