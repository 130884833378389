define('clever-sparky-frontend/routes/site/faults/show/maintenance', ['exports', 'clever-sparky-frontend/routes/base-routes/faulty-fittings-tab'], function (exports, _faultyFittingsTab) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _faultyFittingsTab.default.extend({
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      // overriding setupController function of BaseFaultyFittingsTabRoute
      controller.set('maintenances', model.maintenances);
    }
  });
});