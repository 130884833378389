define('clever-sparky-frontend/validators/reset-password-validator', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ResetPasswordValidations = undefined;
  var ResetPasswordValidations = exports.ResetPasswordValidations = (0, _emberCpValidations.buildValidations)({
    password: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please enter your password',
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('length', {
      min: 8
    })],

    passwordConfirmation: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please confirm your password',
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: 'Your passwords don’t match, please try again '
    })]
  });

  exports.default = { ResetPasswordValidations: ResetPasswordValidations };
});