define('clever-sparky-frontend/components/forms/organisation-form', ['exports', 'ember', 'clever-sparky-frontend/mixins/toggle-label-focus', 'clever-sparky-frontend/mixins/countries-and-states', 'clever-sparky-frontend/validators/organisation-validator', 'ember-macaroni'], function (exports, _ember, _toggleLabelFocus, _countriesAndStates, _organisationValidator, _emberMacaroni) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed,
      observer = _ember.default.observer;
  exports.default = Component.extend(_toggleLabelFocus.default, _countriesAndStates.default, _organisationValidator.OrganisationValidations, {
    didInsertElement: function didInsertElement() {
      this.toggleLabelFocus(this.$('#organisation-form'));
      this.toggleLabelFocus(this.$('#organisation-new-form'));
    },


    didValidate: false,

    isQueryPending: false,

    name: computed(function () {
      return this.get('organisation.name');
    }),

    country: computed(function () {
      return this.get('organisation.country');
    }),

    state: computed(function () {
      return this.get('organisation.state');
    }),

    updateOrganisation: function updateOrganisation() {
      this.set('organisation.name', this.get('name'));
      this.set('organisation.country', this.get('country'));
      this.set('organisation.state', this.get('state'));
    },
    resetOrganisation: function resetOrganisation() {
      this.set('name', this.get('organisation.name'));
      this.set('country', this.get('organisation.country'));
      this.set('state', this.get('organisation.state'));
    },


    hasConfirmedAuthorization: false,
    hasNotConfirmedAuthorization: computed.not('hasConfirmedAuthorization'),

    submitDisabled: computed.or('hasNotConfirmedAuthorization', 'isQueryPending'),

    isNewZealand: computed.equal('country', 'New Zealand'),

    states: (0, _emberMacaroni.ifThenElseWithKeys)('isNewZealand', 'newZealandStates', 'australianStates'),

    countryChanged: observer('country', function () {
      this.set('state', '');
    }),

    actions: {
      next: function next() {
        this.set('didValidate', true);

        if (this.get('validations.isValid')) {
          this.attrs.next();
        }
      }
    }
  });
});