define('clever-sparky-frontend/components/nav-bar', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed,
      inject = _ember.default.inject;
  exports.default = Component.extend({

    postOnboardingTourNames: ['postOnboarding'],

    features: inject.service(),

    triggerContent: computed('currentOrganisation', 'currentUser', function () {
      var currentOrganisation = this.get('currentOrganisation');
      var currentUser = this.get('currentUser');
      return currentOrganisation ? currentUser.get('truncatedName') + ' | ' + currentOrganisation.get('name') : currentUser.get('fullName');
    }),

    isAnalyticsEnabled: computed(function () {
      return this.get('features').isEnabled('analytics');
    })

  });
});