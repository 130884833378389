define('clever-sparky-frontend/components/forms/user-signup-form', ['exports', 'ember', 'clever-sparky-frontend/mixins/toggle-label-focus', 'clever-sparky-frontend/mixins/countries-and-states', 'clever-sparky-frontend/validators/user-validator', 'ember-macaroni'], function (exports, _ember, _toggleLabelFocus, _countriesAndStates, _userValidator, _emberMacaroni) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var service = _ember.default.inject.service;
  var get = _ember.default.get,
      set = _ember.default.set,
      Component = _ember.default.Component,
      computed = _ember.default.computed,
      observer = _ember.default.observer,
      ENV = _ember.default.ENV,
      inject = _ember.default.inject;
  exports.default = Component.extend(_toggleLabelFocus.default, _countriesAndStates.default, _userValidator.UserValidations, {
    holdvaribles: inject.service('holdvaribles'),
    didInsertElement: function didInsertElement() {
      this.toggleLabelFocus(this.$('#user-signup-form'));
      this.focusFilledInputs(this.$('#user-signup-form'));
    },

    filename: '',
    filename2: '',
    filenameSig: '',
    cstatement: '',
    imgupload: false,
    imgupload2: false,
    imginfo: '',
    sigimginfo: '',
    firstName: computed(function () {
      return this.get('user.firstName');
    }),

    lastName: computed(function () {
      return this.get('user.lastName');
    }),

    email: computed(function () {
      return this.get('user.email');
    }),

    phone: computed(function () {
      return this.get('user.phone');
    }),

    userCountry: computed(function () {
      return this.get('user.country');
    }),

    userState: computed(function () {
      return this.get('user.state');
    }),

    password: computed(function () {
      return this.get('user.password');
    }),

    passwordConfirmation: computed(function () {
      return this.get('user.passwordConfirmation');
    }),

    organisationName: computed(function () {
      return this.get('organisation.name');
    }),

    organisationCountry: computed(function () {
      return this.get('organisation.country');
    }),

    organisationState: computed(function () {
      return this.get('organisation.state');
    }),

    postcode: computed(function () {
      return this.get('user.postcode');
    }),

    rfn: computed(function () {
      return this.get('user.rfn');
    }),
    abn: computed(function () {
      return this.get('user.abn');
    }),
    qbcc: computed(function () {
      return this.get('user.qbcc');
    }),
    reportphone: computed(function () {
      return this.get('user.reportphone');
    }),

    businessName: computed.alias('organisationName'),

    businessNameFilled: computed.notEmpty('businessName'),

    hasConfirmedAuthorization: false,

    hasNotConfirmedAuthorization: computed.not('hasConfirmedAuthorization'),

    cannotSubmitBusiness: computed.and('businessNameFilled', 'hasNotConfirmedAuthorization'),

    cannotSubmit: computed.or('cannotSubmitBusiness', 'isQueryPending'),

    updateUser: function updateUser() {

      var getfilname = this.get('filename');
      var filename = 'https://' + ENV.uploadmediabucket + '.s3.amazonaws.com/' + getfilname;
      filename = filename.replace(/\s+/g, '%20');

      var getfilnameSig = this.get('filenameSig');
      var filenamesig = 'https://cleversparkysignatures.s3.amazonaws.com/' + getfilnameSig;
      filenamesig = filenamesig.replace(/\s+/g, '%20');

      this.set('user.firstName', this.get('firstName'));
      this.set('user.lastName', this.get('lastName'));
      this.set('user.email', this.get('email'));
      this.set('user.phone', this.get('phone'));
      this.set('user.country', this.get('userCountry'));
      this.set('user.state', this.get('userState'));
      this.set('user.password', this.get('password'));
      this.set('user.passwordConfirmation', this.get('passwordConfirmation'));

      this.set('user.postcode', this.get('postcode'));
      this.set('user.rfn', this.get('rfn'));
      this.set('user.abn', this.get('abn'));
      this.set('user.qbcc', this.get('qbcc'));
      this.set('user.reportphone', this.get('reportphone'));
      var filecheck = get(this, 'file');
      if (filecheck != null) {
        this.set('user.imgurl', filename);
      }

      var filecheckSig = get(this, 'fileSig');
      if (filecheckSig != null) {
        this.set('user.signatureurl', filenamesig);
        this.set('user.sigfilename', getfilnameSig);
      }
    },
    updateOrganisation: function updateOrganisation() {
      this.set('organisation.name', this.get('organisationName'));
      this.set('organisation.country', this.get('organisationCountry'));
      this.set('organisation.state', this.get('organisationState'));

      this.set('organisation.cstatement', this.get('cstatement'));
    },
    resetUser: function resetUser() {
      this.set('firstName', this.get('user.firstName'));
      this.set('lastName', this.get('user.lastName'));
      this.set('email', this.get('user.email'));
      this.set('phone', this.get('user.phone'));
      this.set('country', this.get('user.country'));
      this.set('state', this.get('user.state'));
      this.set('password', this.get('user.password'));
      this.set('passwordConfirmation', this.get('user.passwordConfirmation'));
    },


    isUserCountryNewZealand: computed.equal('userCountry', 'New Zealand'),

    userStates: (0, _emberMacaroni.ifThenElseWithKeys)('isUserCountryNewZealand', 'newZealandStates', 'australianStates'),

    userCountryChanged: observer('userCountry', function () {
      this.set('userState', '');
    }),

    isOrganisationCountryNewZealand: computed.equal('organisationCountry', 'New Zealand'),

    organisationStates: (0, _emberMacaroni.ifThenElseWithKeys)('isOrganisationCountryNewZealand', 'newZealandStates', 'australianStates'),

    organisationCountryChanged: observer('organisationCountry', function () {
      this.set('organisationState', '');
    }),

    resetOrganisationFields: observer('organisationName', function () {
      if (!this.get('organisationName')) {
        this.set('organisationCountry', '');
        this.set('organisationState', '');
        this.set('hasConfirmedAuthorization', false);
      }
    }),

    signedRequest: service(),
    s3Upload: service(),

    uploadImage: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
        var fileName, testint, fileType, signedData;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:

                // eslint-disable-next-line ember-suave/no-const-outside-module-scope
                fileName = '' + get(this, 'file.name');
                testint = this.getRandomInt(1, 100000);

                this.set('filename', testint + fileName);
                // eslint-disable-next-line ember-suave/no-const-outside-module-scope
                fileType = get(this, 'file.type');
                // eslint-disable-next-line ember-suave/no-const-outside-module-scope

                _context.next = 6;
                return get(this, 'signedRequest').getUrl(testint + fileName, fileType);

              case 6:
                signedData = _context.sent;
                _context.next = 9;
                return get(this, 's3Upload').uploadFile(get(this, 'file'), signedData);

              case 9:
                set(this, 'url', signedData.url);

                this.get('holdvaribles').setupCurrentPhotoUploadSignatureBool(true);

              case 11:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function uploadImage() {
        return _ref.apply(this, arguments);
      }

      return uploadImage;
    }(),
    uploadImageSig: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2() {
        var fileNamesig, testint, fileType, signedData;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                // eslint-disable-next-line ember-suave/no-const-outside-module-scope
                fileNamesig = '' + get(this, 'fileSig.name');
                testint = this.getRandomInt(1, 100000);

                this.set('filenameSig', testint + fileNamesig);
                // eslint-disable-next-line ember-suave/no-const-outside-module-scope
                fileType = get(this, 'fileSig.type');
                // eslint-disable-next-line ember-suave/no-const-outside-module-scope

                _context2.next = 6;
                return get(this, 'signedRequest').getUrl(testint + fileNamesig, fileType);

              case 6:
                signedData = _context2.sent;
                _context2.next = 9;
                return get(this, 's3Upload').uploadFile(get(this, 'fileSig'), signedData);

              case 9:
                set(this, 'url', signedData.url);

              case 10:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function uploadImageSig() {
        return _ref2.apply(this, arguments);
      }

      return uploadImageSig;
    }(),
    getRandomInt: function getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    actions: {
      fileLoaded: function fileLoaded(file) {
        this.set('imgupload', true);

        if (file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png') {
          set(this, 'file', file);
        } else {
          this.set('imginfo', ' logo image needs to be .jpg, .png, or .jpeg format');
        }
      },
      fileLoaded2: function fileLoaded2(file) {
        this.set('imgupload2', true);

        var filecopy = file;
        if (file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png') {
          set(this, 'fileSig', filecopy);
        } else {
          this.set('sigimginfo', 'Signature image needs to be .jpg, .png, or .jpeg format');
        }
      },
      submit: function submit() {
        this.set('didValidate', true);

        if (this.get('validations.isValid')) {
          this.set('isQueryPending', true);

          var filecheck = get(this, 'file');
          var filecheckSig = get(this, 'fileSig');

          if (typeof filecheck !== 'undefined') {
            get(this, 'uploadImage').bind(this)();
          }

          if (typeof filecheckSig !== 'undefined') {
            this.get('holdvaribles').setupCurrentPhotoUploadSignatureBool(true);
            get(this, 'uploadImageSig').bind(this)();
          }

          this.updateUser();

          this.set('user.organisations', []);
          if (this.get('businessNameFilled') && this.get('hasConfirmedAuthorization')) {
            this.updateOrganisation();
            this.set('user.organisations', [this.get('organisation')]);
          }
          this.attrs.submit(this.get('user'));
        }
      }
    }
  });
});