define('clever-sparky-frontend/routes/site/deleted-fittings', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-infinity/mixins/route', 'ember-can'], function (exports, _ember, _authenticatedRouteMixin, _route, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _route.default, _emberCan.CanMixin, {

    perPageParam: 'page[size]', // instead of 'per_page'
    pageParam: 'page[number]', // instead of 'page'
    totalPagesParam: 'meta.total-pages', // instead of "meta.total_pages"

    beforeModel: function beforeModel() {
      var _modelFor = this.modelFor('site'),
          currentEmployment = _modelFor.currentEmployment;

      if (!this.can('view deleted-fittings', currentEmployment)) {
        this.replaceWith('site.groups');
      }
    },
    model: function model() {
      var _modelFor2 = this.modelFor('site'),
          site = _modelFor2.site;

      return RSVP.hash({
        site: site,
        deletedFittings: this.infinityModel('fitting', {
          site: site.id,
          type: 'deleted-fittings',
          perPage: 10,
          startingPage: 1,
          modelPath: 'controller.model.deletedFittings'
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('deletedFittings', model.deletedFittings);
    },


    actions: {
      showFittingDetails: function showFittingDetails(fitting) {
        this.transitionTo('site.deleted-fittings.show.details', fitting.id);
      }
    }

  });
});