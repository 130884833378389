define('clever-sparky-frontend/controllers/site/deleted-fittings/show', ['exports', 'clever-sparky-frontend/controllers/fitting-panel-tab'], function (exports, _fittingPanelTab) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _fittingPanelTab.default.extend({

    showReinstate: true,

    displayReinstateModal: false,

    actions: {
      reinstate: function reinstate() {
        this.set('displayReinstateModal', true);
      },
      closeReinstateModal: function closeReinstateModal() {
        this.set('displayReinstateModal', false);
      }
    }

  });
});