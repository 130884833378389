define('clever-sparky-frontend/util/s3-client', ['module', '../config', 'aws-sdk'], function (module, config, aws) {
  'use strict';

  /* eslint-disable */
  ENV['s3'] = {
    accessKeyId: process.env['PRODUCTION_AWS_ACCESS_KEY_ID'],
    secretAccessKey: process.env['PRODUCTION_AWS_SECRET_ACCESS_KEY'],
    bucket: process.env['PRODUCTION_BUCKET_LOCATION_ASSETS'],
    region: process.env['PRODUCTION_BUCKET_REGION']
  };

  aws.config.update(config.s3Options);
  var client = new aws.S3();

  module.exports = client;
});