define('clever-sparky-frontend/components/form-validated-wholesaler-select', ['exports', 'ember', 'clever-sparky-frontend/components/validated-input'], function (exports, _ember, _validatedInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed;
  exports.default = _validatedInput.default.extend({

    nullValue: computed.equal('value', null),

    classNames: ['form-validated-wholesaler-select'],

    // For this particualr component we want a null value to represent "Other" so the label should be focused if so.
    hasContentOrNull: computed.or('hasContent', 'nullValue')

  });
});