define('clever-sparky-frontend/models/test-event-fitting', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    // Relationships

    fitting: belongsTo('fitting'),

    testEventGroup: belongsTo('test-event-group'),

    latestTest: belongsTo('ledtest'),

    maintenances: hasMany('maintenance'),

    // Attributes

    collected: attr('boolean'),

    testOutOfRange: attr('boolean')

  });
});