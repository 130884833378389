define('clever-sparky-frontend/routes/site/deleted-fittings/show', ['exports', 'ember', 'clever-sparky-frontend/routes/base-routes/show', 'clever-sparky-frontend/mixins/handle-error'], function (exports, _ember, _show, _handleError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = _ember.default.inject;
  exports.default = _show.default.extend(_handleError.default, {

    reinstateFitting: inject.service('reinstateFitting'),

    actions: {
      returnToFittings: function returnToFittings() {
        this.controller.set('displayReinstateModal', false);
        this.transitionTo('site.deleted-fittings');
      },
      reinstateFitting: function reinstateFitting(fitting) {
        var _this = this;

        this.get('reinstateFitting').reinstate(fitting).then(function () {
          _this.modelFor('site.deleted-fittings').deletedFittings.update();
          _this.showSuccessFlash('Fitting Reinstated');
          _this.send('returnToFittings');
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        }).finally(function () {
          _this.controller.set('isQueryPending', false);
        });
      }
    }

  });
});