define('clever-sparky-frontend/components/disabled-form-input', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component;
  exports.default = Component.extend({

    classNames: ['disabled-form-input']

  });
});