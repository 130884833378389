define('clever-sparky-frontend/validators/forms/batch-move-confirmation', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.BatchMoveValidations = undefined;
  var BatchMoveValidations = exports.BatchMoveValidations = (0, _emberCpValidations.buildValidations)({
    selectedGroup: (0, _emberCpValidations.validator)('presence', true)
  });

  exports.default = { BatchMoveValidations: BatchMoveValidations };
});