define('clever-sparky-frontend/services/user-agent', ['exports', 'ember-useragent/services/user-agent'], function (exports, _userAgent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _userAgent.default;
    }
  });
});