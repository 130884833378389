define('clever-sparky-frontend/routes/user', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'clever-sparky-frontend/mixins/show-nav-bar', 'clever-sparky-frontend/mixins/handle-error', 'ember-infinity/mixins/route', 'clever-sparky-frontend/mixins/setup-tour-sequence'], function (exports, _ember, _authenticatedRouteMixin, _showNavBar, _handleError, _route, _setupTourSequence) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP,
      inject = _ember.default.inject;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _route.default, _showNavBar.default, _handleError.default, _setupTourSequence.default, {
    // Services

    viewUserDetailsTour: inject.service('tours/view-user-details'),

    features: inject.service(),

    // Attributes

    perPageParam: 'page[size]', // instead of 'per_page'

    pageParam: 'page[number]', // instead of 'page'

    totalPagesParam: 'meta.total-pages', // instead of "meta.total_pages"

    // Hooks

    beforeModel: function beforeModel() {
      if (this.get('features').isEnabled('guidedTour')) {
        return RSVP.hash({
          viewUserDetailsTooltipEvent: this.setupTourSequence('view_user_details_web', this.get('viewUserDetailsTour'))
        });
      }
    },
    model: function model(params) {
      var _this = this;

      var _modelFor = this.modelFor('application'),
          currentOrganisation = _modelFor.currentOrganisation;

      var currentOrganisationId = currentOrganisation ? currentOrganisation.get('id') : null;

      return new RSVP.Promise(function (resolve) {
        _this.get('store').findRecord('employment', params.employment_id).then(function (employment) {
          RSVP.hash({
            employment: employment,
            technicians: _this.infinityModel('user', {
              perPage: 10,
              startingPage: 1,
              modelPath: 'controller.model.technicians',
              role: 'technician',
              currentOrganisationId: currentOrganisationId
            }),
            currentOrganisation: currentOrganisation,
            user: employment.get('user'),
            organisation: employment.get('organisation')
          }).then(function (model) {
            resolve(model);
          });
        });
      });
    },
    afterModel: function afterModel(model) {
      var _this2 = this;

      return new RSVP.Promise(function (resolve) {
        RSVP.hash({
          restrictedSites: _this2.store.query('site', {
            userId: model.user.get('id'),
            filter: {
              organisationId: model.organisation.get('id')
            }
          }),

          sitePermissions: _this2.infinityModel('site-permission', {
            perPage: 1000,
            startingPage: 1,
            modelPath: 'controller.model.sitePermissions',
            filter: {
              'user-id': model.user.get('id')
            },
            organisationId: model.organisation.get('id')
          })
        }).then(function (hash) {
          model.usersRestrictedSites = hash.restrictedSites;
          model.sitePermissions = hash.sitePermissions;
          resolve();
        });
      });
    },


    actions: {
      deleteUser: function deleteUser(employment) {
        var _this3 = this;

        this.controller.set('isQueryPending', true);
        employment.destroyRecord().then(function () {
          _this3.showSuccessFlash('User Deleted');
          _this3.controller.send('closeDeleteUserModal');
          _this3.transitionTo('users');
        }).catch(function (reason) {
          _this3.handleRailsError(reason);
        }).finally(function () {
          _this3.controller.set('isQueryPending', false);
        });
      },
      copyPermissions: function copyPermissions(currentOrganisation, sourceId, destinationId) {
        var _this4 = this;

        currentOrganisation.copySitePermissions({
          data: {
            type: 'site-permission-copies',
            relationships: {
              source: {
                data: {
                  type: 'users',
                  id: sourceId
                }
              },
              destination: {
                data: {
                  type: 'users',
                  id: destinationId
                }
              }
            }
          }
        }).then(function () {
          _this4.controller.send('closeInviteToSitesModal');
          _this4.showSuccessFlash('Permission Copied Successfully');
          _this4.refresh();
        }).catch(function (reason) {
          _this4.handleRailsError(reason);
        }).finally(function () {
          _this4.controller.set('isQueryPending', false);
        });
      },
      grantAccess: function grantAccess(user, sites) {
        var _this5 = this;

        var formatedSiteData = sites.map(function (site) {
          return { type: 'sites', id: site.get('id') };
        });
        user.grantAccess({
          data: formatedSiteData
        }).then(function () {
          _this5.showSuccessFlash('Permission Granted Successfully');
          _this5.controller.send('closeInviteToSitesModal');
          _this5.refresh();
        }).catch(function (reason) {
          _this5.handleRailsError(reason);
        }).finally(function () {
          _this5.controller.set('isQueryPending', false);
        });
      },
      removeAccess: function removeAccess(sitePermission) {
        var _this6 = this;

        sitePermission.destroyRecord().then(function () {
          _this6.showSuccessFlash('Site Access has been removed');
          _this6.refresh();
        }).catch(function (reason) {
          _this6.handleRailsError(reason);
        }).finally(function () {
          _this6.controller.set('isQueryPending', false);
        });
      },
      updatePurchasingRights: function updatePurchasingRights(employment) {
        var _this7 = this;

        employment.save().then(function () {
          _this7.showSuccessFlash('Purchasing rights has been updated', { timeout: 1000 });
        }).catch(function (reason) {
          _this7.handleRailsError(reason);
        });
      }
    }

  });
});