define('clever-sparky-frontend/services/wholesaler-auth', ['exports', 'ember', 'clever-sparky-frontend/services/request-service'], function (exports, _ember, _requestService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = _ember.default.$;
  exports.default = _requestService.default.extend({

    _contentType: 'application/x-www-form-urlencoded;charset=UTF-8',

    _method: 'POST',

    onSuccess: function onSuccess(result) {
      var resultObj = {};
      for (var key in result) {
        var dasherizedKey = key.toString().dasherize();
        resultObj[dasherizedKey] = result[key];
      }
      return resultObj;
    },
    sendWholesalerAuthRequest: function sendWholesalerAuthRequest(wholesaler, credentials) {
      var wholesalerAuthResource = wholesaler.get('wholesalerAuthResource');
      var url = wholesalerAuthResource.get('authUrl');
      var method = this.get('_method');
      var headers = {
        'Authorization': 'Basic ' + wholesalerAuthResource.get('authToken')
      };
      var username = credentials.username,
          password = credentials.password;

      var data = $.param({
        username: username,
        password: password,
        'grant_type': 'password'
      });
      return this.sendRequest(url, method, data, headers);
    }
  });
});