define('clever-sparky-frontend/services/download-report', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = _ember.default.Service,
      inject = _ember.default.inject,
      ENV = _ember.default.ENV;
  exports.default = Service.extend({
    requestService: inject.service('request-service'),

    _downloadMethod: 'POST',

    _downloadURL: function _downloadURL(category) {
      return ENV.api.host + '/' + ENV.api.namespace + '/reports/' + category;
    },
    downloadGroupsReport: function downloadGroupsReport(site, groups, category, reportFormat, cstatebool, cstatement) {
      var url = this._downloadURL(category);
      var method = this.get('_downloadMethod');

      var formattedGroupData = groups.map(function (group) {
        return { id: group.id, type: 'groups' };
      });

      var data = {
        data: {
          type: 'reports',
          attributes: {
            'file-format': reportFormat,
            cstatebool: cstatebool,
            cstatement: cstatement
          },
          relationships: {
            site: {
              data: {
                id: site.id,
                type: 'sites'
              }
            },
            groups: {
              data: formattedGroupData
            }
          }
        }
      };

      return this.get('requestService').sendAuthenticatedRequest(url, method, data);
    },
    downloadTestEventReport: function downloadTestEventReport(site, testEvent, category, reportFormat, cstatebool, cstatement) {
      var url = this._downloadURL(category);
      var method = this.get('_downloadMethod');

      var data = {
        data: {
          type: 'reports',
          attributes: {
            'file-format': reportFormat,
            cstatebool: cstatebool,
            cstatement: cstatement
          },
          relationships: {
            site: {
              data: {
                id: site.id,
                type: 'sites'
              }
            },
            'test-event': {
              data: {
                id: testEvent.id,
                type: 'test-events'
              }
            }
          }
        }
      };

      return this.get('requestService').sendAuthenticatedRequest(url, method, data);
    },
    downloadOrderItemsReport: function downloadOrderItemsReport(site, order) {
      var url = this._downloadURL('order-items');
      var method = this.get('_downloadMethod');

      var data = {
        data: {
          type: 'reports',
          relationships: {
            orders: {
              data: {
                id: order.id,
                type: 'orders'
              }
            },
            site: {
              data: {
                id: site.id,
                type: 'sites'
              }
            }
          }
        }
      };

      return this.get('requestService').sendAuthenticatedRequest(url, method, data);
    }
  });
});