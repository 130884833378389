define('clever-sparky-frontend/router', ['exports', 'ember', 'clever-sparky-frontend/config/environment'], function (exports, _ember, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberRouter = _ember.default.Router,
      inject = _ember.default.inject,
      run = _ember.default.run,
      get = _ember.default.get;


  if (_environment.default.environment !== 'test') {
    EmberRouter.reopen({
      metrics: inject.service()
    });
  }

  var Router = EmberRouter.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,

    didTransition: function didTransition() {
      this._super.apply(this, arguments);
      this._trackPage();
    },
    _trackPage: function _trackPage() {
      var _this = this;

      run.scheduleOnce('afterRender', this, function () {
        var page = _this.get('url');
        var title = _this.getWithDefault('currentRouteName', 'unknown');

        get(_this, 'metrics').trackPage({ page: page, title: title });
      });
    }
  });

  var isTestEventsEnabled = _environment.default.featureFlags.testEvents;
  var isAnalyticsEnabled = _environment.default.featureFlags.analytics;
  var isShoppingCartEnabled = _environment.default.featureFlags.shoppingCart;
  var isGuidedTourEnabled = _environment.default.featureFlags.guidedTour;

  Router.map(function () {
    this.route('login');

    this.route('invite');

    this.route('sites', function () {});

    this.route('site', { path: '/sites/:site_id' }, function () {
      this.route('details');
      this.route('fittings', function () {
        this.route('show', { path: '/:fitting_id' }, function () {
          this.route('details');
          this.route('test-and-status');
          this.route('maintenance');
        });
      });
      this.route('groups', function () {});
      this.route('faults', function () {
        this.route('show', { path: '/:fitting_id' }, function () {
          this.route('details');
          this.route('test-and-status');
          this.route('maintenance');
        });
      });
      this.route('deleted-fittings', function () {
        this.route('show', { path: '/:fitting_id' }, function () {
          this.route('details');
          this.route('test-and-status');
          this.route('maintenance');
        });
      });
      if (isTestEventsEnabled) {
        this.route('test-events');
      }
      if (isShoppingCartEnabled) {
        this.route('shopping-cart');
      }
    });

    this.route('groups', { path: '/groups/:group_id' }, function () {

      this.route('fittings', function () {
        this.route('delete-group');
        this.route('edit-group');

        this.route('show', { path: '/:fitting_id' }, function () {
          this.route('details');
          this.route('test-and-status');
          this.route('maintenance');
        });
      });
    });

    this.route('edit', function () {
      this.route('profile', function () {});
      this.route('organisation');
    });

    this.route('signup', function () {
      this.route('terms');
      this.route('user');
    });

    this.route('change-password', function () {
      this.route('success');
    });

    this.route('reset-password', function () {
      this.route('success');
    });

    this.route('forgot-password', function () {
      this.route('success');
    });

    this.route('logout');

    this.route('site-requests');

    this.route('new-organisation');

    this.route('users');

    this.route('user', { path: '/users/:employment_id' });

    this.route('terms');

    this.route('support');

    this.route('site-request', { path: '/site-requests/:access_request_id' });

    if (isTestEventsEnabled) {
      this.route('test-event', { path: '/test-events/:test_event_id' }, function () {
        this.route('groups');
      });

      this.route('test-event-group', { path: '/test-events/groups/:test_event_group_id' }, function () {
        this.route('fittings', function () {
          this.route('show', { path: '/:test_event_fitting_id' }, function () {
            this.route('details');
            this.route('test-and-status');
            this.route('maintenance');
          });
        });
      });
    }

    if (isAnalyticsEnabled) {
      this.route('analytics');
    }

    if (isGuidedTourEnabled) {
      this.route('onboarding');
    }

    if (isShoppingCartEnabled) {
      this.route('order-item-group', { path: '/shopping-cart/groups/:group_id' }, function () {
        this.route('order-items');
      });
    }
  });

  exports.default = Router;
});