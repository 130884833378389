define('clever-sparky-frontend/components/fitting-delete-confirmation', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component;
  exports.default = Component.extend({

    classNames: ['fitting-delete-confirmation'],

    isQueryPending: false,

    actions: {
      closeModal: function closeModal() {
        this.sendAction('closeModal');
      },
      deleteFitting: function deleteFitting() {
        this.set('isQueryPending', true);
        this.sendAction('deleteFitting');
      }
    }

  });
});