define('clever-sparky-frontend/components/search-bar', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      run = _ember.default.run,
      computed = _ember.default.computed;
  exports.default = Component.extend({

    // Attributes

    classNames: ['search-bar'],

    placeholder: 'Search',

    lastQuery: '',

    query: '',

    // Properties
    searchTerm: computed.reads('query'),

    // Methods

    checkQuery: function checkQuery(query) {
      return query !== this.get('lastQuery');
    },
    setSearchFilter: function setSearchFilter(query) {
      this.set('lastQuery', query);
      this.attrs.search(query);
    },


    actions: {
      query: function query(term) {
        if (this.checkQuery(term)) {
          run.debounce(this, this.setSearchFilter, term, 300);
        }
      }
    }

  });
});