define('clever-sparky-frontend/adapters/organisation', ['exports', 'ember', 'clever-sparky-frontend/adapters/application'], function (exports, _ember, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ENV = _ember.default.ENV;
  exports.default = _application.default.extend({
    urlForQuery: function urlForQuery(query) {
      var url = '';

      if (query.filter.role) {
        url = ENV.api.host + '/' + ENV.api.namespace + '/users/me/organisations';
      }

      return url;
    }
  });
});