define('clever-sparky-frontend/components/forms/update-fitting-form', ['exports', 'ember', 'clever-sparky-frontend/mixins/toggle-label-focus', 'clever-sparky-frontend/validators/forms/update-fitting-details', 'moment'], function (exports, _ember, _toggleLabelFocus, _updateFittingDetails, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      inject = _ember.default.inject,
      computed = _ember.default.computed;
  exports.default = Component.extend(_updateFittingDetails.FittingValidations, _toggleLabelFocus.default, {
    // Services

    fittingLookup: inject.service(),

    // Hooks

    didInsertElement: function didInsertElement() {
      this.toggleLabelFocus(this.$('#update-fitting-form'));
    },


    // Attributes

    didValidate: false,

    isQueryPending: false,

    serialNumber: '',

    fittingType: '',

    installDate: '',

    maxDate: (0, _moment.default)(),

    previousSerialNumber: '',

    shouldShowTestEventWarning: computed('installDate', 'testEvent.endDate', function () {
      var installDate = this.get('installDate');
      var endDate = this.get('testEvent.endDate');
      return installDate && endDate && !(0, _moment.default)(installDate).isSameOrBefore(endDate);
    }),

    // Methods

    updateFitting: function updateFitting() {
      this.set('fitting.serialNumber', this.get('serialNumber'));
      this.set('fitting.fittingType', this.get('fittingType'));
      this.set('fitting.installDate', this.get('installDate'));
    },


    actions: {
      update: function update() {
        this.set('didValidate', true);

        if (this.get('validations.isValid')) {
          this.set('isQueryPending', true);
          this.updateFitting();
          this.sendAction('update', this.get('fitting'));
        }
      },
      lookupFittingInfo: function lookupFittingInfo() {
        var _this = this;

        if (this.get('serialNumber') && this.get('serialNumber') !== this.get('previousSerialNumber')) {
          this.set('previousSerialNumber', this.get('serialNumber'));
          this.get('fittingLookup').lookupFittingInfo(this.get('serialNumber')).then(function (_ref) {
            var fittingType = _ref.fittingType;

            if (fittingType) {
              _this.set('fittingType', fittingType);
            } else {
              _this.set('fittingType', '');
            }
          });
        }
      }
    }

  });
});