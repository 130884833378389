define('clever-sparky-frontend/routes/order-item-group/order-items', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-infinity/mixins/route', 'clever-sparky-frontend/mixins/handle-error'], function (exports, _ember, _authenticatedRouteMixin, _route, _handleError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP,
      inject = _ember.default.inject;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _handleError.default, _route.default, {

    // Services
    batchDeleteOrderItems: inject.service('batchDeleteOrderItems'),

    cartCheckout: inject.service(),

    // Attributes
    perPageParam: 'page[size]', // instead of 'per_page'

    pageParam: 'page[number]', // instead of 'page'

    totalPagesParam: 'meta.total-pages', // instead of "meta.total_pages"

    model: function model() {
      var _modelFor = this.modelFor('order-item-group'),
          site = _modelFor.site,
          group = _modelFor.group;

      var _modelFor2 = this.modelFor('application'),
          currentUser = _modelFor2.currentUser,
          currentOrganisation = _modelFor2.currentOrganisation,
          currentEmployment = _modelFor2.currentEmployment;

      return RSVP.hash({
        site: site,
        currentUser: currentUser,
        currentOrganisation: currentOrganisation,
        currentEmployment: currentEmployment,
        group: group,
        order: site.get('currentOrder'),
        orderItems: this.infinityModel('order-item', {
          order: site.get('currentOrder.id'),
          perPage: 10,
          startingPage: 1,
          modelPath: 'controller.model.orderItems',
          filter: {
            'group-id': group.get('id')
          }
        })
      });
    },
    afterModel: function afterModel(model) {
      if (!model.orderItems.get('length')) {
        this.transitionTo('site.shopping-cart', model.site.get('id'));
      }
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('isDeletingItems', false);
      }
    },


    actions: {
      confirmBatchDeleteItems: function confirmBatchDeleteItems(selectedItems) {
        var _this = this;

        var batch = selectedItems.map(function (orderItem) {
          return {
            partType: orderItem.get('partType'),
            fitting: orderItem.get('fitting')
          };
        });

        this.get('batchDeleteOrderItems').deleteBatch(batch).then(function () {
          _this.controller.send('closeBatchDeleteConfirmationModal');
          _this.showSuccessFlash('Order Items Deleted');
          _this.currentModel.site.reload();
          // refresh the top most parent route
          _this.send('refreshRoute');
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        }).finally(function () {
          _this.controller.set('isQueryPending', false);
        });
      },
      confirmCheckout: function confirmCheckout(selectedGroups) {
        var _this2 = this;

        this.get('cartCheckout').checkout(this.currentModel.order, selectedGroups).then(function (result) {
          window.open(result.links.cart, '_blank');
          _this2.controller.send('closeCheckoutConfirmationModal');
          _this2.transitionTo('site.shopping-cart', _this2.currentModel.site.id);
        }).catch(function (reason) {
          _this2.handleRailsError(reason);
        }).finally(function () {
          _this2.controller.set('isQueryPending', false);
        });
      }
    }

  });
});