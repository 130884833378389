define('clever-sparky-frontend/uploaders/csv', ['exports', 'ember', 'ember-uploader/uploaders/base'], function (exports, _ember, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = _ember.default.$,
      get = _ember.default.get,
      set = _ember.default.set,
      RSVP = _ember.default.RSVP;
  exports.default = _base.default.extend({
    getBase64: function getBase64(file) {
      return new RSVP.Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          resolve(reader.result.split(',')[1]);
        };
        reader.onerror = function (error) {
          reject(error);
        };
      });
    },
    ajax: function ajax(url) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var _this = this;

      var method = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : this.method;
      var headers = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

      return this.ajaxPromise({
        headers: headers,
        xhr: function xhr() {
          var xhr = $.ajaxSettings.xhr();
          xhr.upload.onprogress = function (event) {
            _this.didProgress(event);
          };
          _this.one('isAborting', function () {
            return xhr.abort();
          });
          return xhr;
        },
        url: url,
        contentType: 'application/json',
        data: JSON.stringify(data),
        method: method
      });
    },
    upload: function upload(file) {
      var _this2 = this;

      var extra = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var url = get(this, 'url');
      var method = get(this, 'method');

      var site = extra.site;
      var credentials = extra.credentials;


      return this.getBase64(file).then(function (fileData) {
        var csvData = {
          data: {
            type: 'fittings-uploads',
            attributes: {
              'csv-file': fileData
            },
            relationships: {
              site: {
                data: {
                  type: 'sites',
                  id: site.get('id')
                }
              }
            }
          }
        };

        set(_this2, 'isUploading', true);

        return _this2.ajax(url, csvData, method, {
          'X-Session-id': credentials.session_id,
          'X-Session-Token': credentials.token
        });
      });
    }
  });
});