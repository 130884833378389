define('clever-sparky-frontend/mixins/file-upload-actions', ['exports', 'ember', 'clever-sparky-frontend/mixins/handle-error'], function (exports, _ember, _handleError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = _ember.default.Mixin,
      inject = _ember.default.inject,
      computed = _ember.default.computed;
  exports.default = Mixin.create(_handleError.default, {
    fileUpload: inject.service('file-upload'),

    csvUploadProgress: computed.reads('fileUpload.progress'),

    csvUploadStatus: computed.reads('fileUpload.uploadStatus'),

    csvFileName: computed.reads('fileUpload.fileName'),

    refreshRoute: function refreshRoute() {
      this.send('refreshRoute');
    },


    actions: {
      uploadCsv: function uploadCsv(file) {
        var _this = this;

        this.set('test', this.get('fileUpload').upload(file, this.get('site')).then(function () {
          _this.refreshRoute();
        }).catch(function (errors) {
          _this.set('displayErrorModal', true);
          _this.set('uploadErrors', errors);
        }));
      },
      cancelUpload: function cancelUpload() {
        this.get('fileUpload').cancelUpload();
      },
      dismissProgressBar: function dismissProgressBar() {
        this.get('fileUpload').reset();
      },
      closeErrorModal: function closeErrorModal() {
        this.get('fileUpload').reset();
        this.set('displayErrorModal', false);
      }
    }

  });
});