define('clever-sparky-frontend/abilities/deleted-fittings', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed;
  exports.default = _emberCan.Ability.extend({
    canView: computed('model', function () {
      return !this.get('model') || this.get('model') && !this.get('model.isTechnician');
    })
  });
});