define('clever-sparky-frontend/services/tours/view-site', ['exports', 'ember', 'clever-sparky-frontend/services/tours/base'], function (exports, _ember, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed,
      inject = _ember.default.inject;
  exports.default = _base.default.extend({

    // Services
    features: inject.service(),

    // Attributes

    namePrefix: 'view-site',

    // properties

    isTestEventsEnabled: computed(function () {
      return this.get('features').isEnabled('test-events');
    }),

    // sequence elements

    sequenceState: computed('isTestEventsEnabled', function () {
      var state = [{
        name: 'groups-tab',
        hasRendered: false
      }];

      if (this.get('isTestEventsEnabled')) {
        state.pushObject({
          name: 'test-events-tab',
          hasRendered: false
        });
      }

      state.pushObjects([{
        name: 'faults-tab',
        hasRendered: false
      }, {
        name: 'deleted-fittings-tab',
        hasRendered: false
      }, {
        name: 'site-details-tab',
        hasRendered: false
      }, {
        name: 'csv-download-template-action',
        hasRendered: false
      }, {
        name: 'report-action',
        hasRendered: false
      }]);

      return state;
    }),

    sequenceElements: computed('isTestEventsEnabled', function () {
      var elements = [{
        name: 'groups-tab',
        position: 'right',
        content: [this.get('i18n').t('tour.view-site.groups-tab')]
      }];

      if (this.get('isTestEventsEnabled')) {
        elements.pushObject({
          name: 'test-events-tab',
          position: 'right',
          content: [this.get('i18n').t('tour.view-site.test-events-tab')]
        });
      }

      elements.pushObjects([{
        name: 'faults-tab',
        position: 'right',
        content: [this.get('i18n').t('tour.view-site.faults-tab')]
      }, {
        name: 'deleted-fittings-tab',
        position: 'right',
        content: [this.get('i18n').t('tour.view-site.deleted-fittings-tab')]
      }, {
        name: 'site-details-tab',
        position: 'right',
        content: [this.get('i18n').t('tour.view-site.site-details-tab')]
      }, {
        name: 'csv-download-template-action',
        content: [this.get('i18n').t('tour.view-site.csv-download-template-action')]
      }, {
        name: 'report-action',
        position: 'left',
        content: [this.get('i18n').t('tour.view-site.report-action')]
      }]);

      return elements;
    })

  });
});