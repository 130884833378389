define('clever-sparky-frontend/components/metric-chart', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend({

    classNames: 'metric-chart',

    chartColor: computed('color', function () {
      var color = [];
      switch (this.get('color')) {
        case 'green':
          color = ['#95c11f', 'rgba(149,193,31,0.2)'];
          break;

        case 'red':
          color = ['#ba1312', 'rgba(186,19,18,0.1)'];
          break;

        case 'blue':
          color = ['#004a99', 'rgba(0,74,153,0.1)'];
          break;
      }

      return color;
    }),

    chartValues: computed('chartData', function () {
      var value = this.get('chartData.value');
      var total = this.get('chartData.total');
      var chartData = value === total ? [value] : [value, total - value];
      return {
        datasets: [{
          data: chartData,
          backgroundColor: this.get('chartColor')
        }]
      };
    }),

    options: {
      cutoutPercentage: 95,
      legend: false,

      tooltips: {
        enabled: false
      },

      events: []
    }

  });
});