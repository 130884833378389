define('clever-sparky-frontend/authenticators/cleversparky', ['exports', 'ember', 'ember-simple-auth/authenticators/devise'], function (exports, _ember, _devise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = _ember.default.RSVP,
      inject = _ember.default.inject,
      run = _ember.default.run,
      ENV = _ember.default.ENV;
  exports.default = _devise.default.extend({

    store: inject.service(),

    serverTokenEndpoint: ENV.api.host + '/' + ENV.api.namespace + '/sessions',

    resourceName: 'session',
    userId: 'user_id',

    identificationAttributeName: 'session_id',

    authenticate: function authenticate(identification, password) {
      var _this = this;

      return new RSVP.Promise(function (resolve, reject) {
        var session = _this.get('store').createRecord('session', {
          email: identification,
          password: password
        });

        return session.save().then(function (session) {
          var _getProperties = _this.getProperties('resourceName', 'identificationAttributeName', 'tokenAttributeName', 'userId'),
              resourceName = _getProperties.resourceName,
              identificationAttributeName = _getProperties.identificationAttributeName,
              tokenAttributeName = _getProperties.tokenAttributeName,
              userId = _getProperties.userId;

          var data = {};
          data[resourceName] = {};
          data[resourceName][userId] = session.get('user.id');
          data[resourceName][identificationAttributeName] = session.get('id');
          data[resourceName][tokenAttributeName] = session.get('authenticationToken');

          run(null, resolve, data);
        }, function (adapterError) {
          run(null, reject, adapterError.errors);
        });
      });
    },
    adminauthenticate: function adminauthenticate(identification, password, userid) {
      var _this2 = this;

      return new RSVP.Promise(function (resolve, reject) {
        var session = _this2.get('store').createRecord('session', {
          email: identification,
          password: password,
          userid: userid
        });

        return session.save().then(function (session) {
          var _getProperties2 = _this2.getProperties('resourceName', 'identificationAttributeName', 'tokenAttributeName', 'userId'),
              resourceName = _getProperties2.resourceName,
              identificationAttributeName = _getProperties2.identificationAttributeName,
              tokenAttributeName = _getProperties2.tokenAttributeName,
              userId = _getProperties2.userId;

          var data = {};
          data[resourceName] = {};
          data[resourceName][userId] = session.get('user.id');
          data[resourceName][identificationAttributeName] = session.get('id');
          data[resourceName][tokenAttributeName] = session.get('authenticationToken');

          run(null, resolve, data);
        }, function (adapterError) {
          run(null, reject, adapterError.errors);
        });
      });
    }
  });
});