define('clever-sparky-frontend/components/forms/test-event-form', ['exports', 'ember', 'clever-sparky-frontend/mixins/toggle-label-focus', 'clever-sparky-frontend/validators/forms/test-event'], function (exports, _ember, _toggleLabelFocus, _testEvent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend(_toggleLabelFocus.default, _testEvent.TestEventValidations, {
    // Hooks
    didInsertElement: function didInsertElement() {
      this.toggleLabelFocus(this.$('#test-event-form'));
    },


    // Attributes
    didValidate: false,

    isQueryPending: false,

    // Properties
    startDate: computed(function () {
      return this.get('testEvent.startDate');
    }),

    endDate: computed(function () {
      return this.get('testEvent.endDate');
    }),

    isEditMode: computed.equal('type', 'edit'),

    isDuplicateMode: computed.equal('type', 'duplicate'),

    isEditOrDuplicateMode: computed.or('isEditMode', 'isDuplicateMode'),

    canSubmit: computed.alias('validations.isValid'),

    // Methods

    updateTestEvent: function updateTestEvent() {
      if (!this.get('isEditMode')) {
        this.set('testEvent.label', this.get('label'));
      }

      this.set('testEvent.startDate', this.get('startDate'));
      this.set('testEvent.endDate', this.get('endDate'));
    },


    actions: {
      save: function save() {
        this.set('didValidate', true);

        if (this.get('canSubmit')) {
          this.updateTestEvent();
          this.set('isQueryPending', true);
          this.attrs.save(this.get('testEvent'));
        }
      },
      next: function next() {
        this.set('didValidate', true);

        if (this.get('canSubmit')) {
          this.updateTestEvent();
          this.attrs.submit(this.get('testEvent'));
        }
      }
    }
  });
});