define('clever-sparky-frontend/routes/site/groups', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'clever-sparky-frontend/mixins/handle-error', 'ember-infinity/mixins/route', 'clever-sparky-frontend/mixins/setup-tour-sequence', 'ember-can'], function (exports, _ember, _authenticatedRouteMixin, _handleError, _route, _setupTourSequence, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP,
      inject = _ember.default.inject;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _route.default, _handleError.default, _setupTourSequence.default, _emberCan.CanMixin, {
    // Services
    holdvaribles: inject.service('holdvaribles'),
    viewSiteTour: inject.service('tours/view-site'),

    viewSiteTechnicianTour: inject.service('tours/view-site-technician'),

    features: inject.service(),

    session: inject.service('session'),

    batchDeleteGroups: inject.service('batchDeleteGroups'),

    // Attributes

    perPageParam: 'page[size]', // instead of 'per_page'

    pageParam: 'page[number]', // instead of 'page'

    totalPagesParam: 'meta.total-pages', // instead of "meta.total_pages"

    // Hooks

    beforeModel: function beforeModel() {
      if (this.get('features').isEnabled('guidedTour')) {
        var _modelFor = this.modelFor('application'),
            currentEmployment = _modelFor.currentEmployment;

        var viewSiteTooltipEvent = void 0;
        if (this.can('view deleted-fittings', currentEmployment)) {
          this.deactivateTour(this.get('viewSiteTechnicianTour'));
          viewSiteTooltipEvent = this.setupTourSequence('view_site_web', this.get('viewSiteTour'));
        } else {
          this.deactivateTour(this.get('viewSiteTour'));
          viewSiteTooltipEvent = this.setupTourSequence('view_site_web_technician', this.get('viewSiteTechnicianTour'));
        }
        return RSVP.hash({
          viewSiteTooltipEvent: viewSiteTooltipEvent
        });
      }
    },
    model: function model() {
      this.get('holdvaribles').setupallfittingpagebool(false);

      var _modelFor2 = this.modelFor('site'),
          site = _modelFor2.site;

      return RSVP.hash({
        site: site,
        groups: this.infinityModel('group', {
          site: site.id,
          perPage: 10,
          startingPage: 1,
          modelPath: 'controller.model.groups'
        }),
        group: this.store.createRecord('group')
      });
    },
    resetController: function resetController(controller, isExiting) {
      var group = controller.model.group;


      controller.set('isDeletingGroups', false);
      if (isExiting && !group.get('isPersisted')) {
        group.unloadRecord();
      }
    },


    actions: {
      save: function save(group) {
        var _this = this;

        group.set('site', this.currentModel.site);

        group.save().then(function () {
          _this.controller.send('toggleAddGroup');
          _this.showSuccessFlash('Group Added');
          // refresh the top most parent route
          _this.send('refreshRoute');
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        }).finally(function () {
          _this.controller.set('isQueryPending', false);
        });
      },
      closeModal: function closeModal() {
        this.transitionTo('site.groups');
      },
      confirmBatchDeleteGroups: function confirmBatchDeleteGroups(selectedGroups) {
        var _this2 = this;

        this.get('batchDeleteGroups').deleteBatch(selectedGroups).then(function () {
          _this2.controller.send('closeBatchConfirmationModal');
          _this2.showSuccessFlash('Groups Deleted');
          // refresh the top most parent route
          _this2.send('refreshRoute');
        }).catch(function (reason) {
          _this2.handleRailsError(reason);
        }).finally(function () {
          _this2.controller.set('isQueryPending', false);
        });
      }
    }

  });
});