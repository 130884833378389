define('clever-sparky-frontend/components/employment-table', ['exports', 'ember', 'clever-sparky-frontend/mixins/option-lookup'], function (exports, _ember, _optionLookup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component;
  exports.default = Component.extend(_optionLookup.default, {

    actions: {
      showUserDetail: function showUserDetail(user) {
        this.sendAction('showUserDetail', user);
      },
      cancelInvite: function cancelInvite(invite) {
        this.set('isQueryPending', true);
        this.sendAction('cancelInvite', invite);
      },
      changeUserType: function changeUserType(employment, value) {
        if (value != employment.get('role')) {
          this.set('isQueryPending', true);
          employment.set('role', value);
          this.sendAction('changeUserType', employment);
        }
      }
    }

  });
});