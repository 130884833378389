define('clever-sparky-frontend/components/forms/fittings-form-new', ['exports', 'ember', 'moment', 'clever-sparky-frontend/mixins/handle-error', 'clever-sparky-frontend/validators/forms/fitting', 'clever-sparky-frontend/mixins/toggle-label-focus'], function (exports, _ember, _moment, _handleError, _fitting, _toggleLabelFocus) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed,
      inject = _ember.default.inject;
  exports.default = Component.extend(_fitting.FittingValidations, _toggleLabelFocus.default, _handleError.default, {
    // Attributes
    store: inject.service(),

    holdvaribles: inject.service('holdvaribles'),

    didValidate: false,

    isQueryPending: false,

    serialNumber: '',

    deviceReference: '',

    installDate: '',

    testEvent: null,

    maxDate: (0, _moment.default)(),

    selectedGroup: null,

    allfittings: null,

    displayGroups: null,

    // Properties

    shouldShowTestEventWarning: computed('installDate', 'testEvent.endDate', function () {
      var installDate = this.get('installDate');
      var endDate = this.get('testEvent.endDate');
      return installDate && endDate && !(0, _moment.default)(installDate).isSameOrBefore(endDate);
    }),

    // Hooks

    didInsertElement: function didInsertElement() {
      this.toggleLabelFocus(this.$('#fitting-form'));
    },


    // Methods

    updateFitting: function updateFitting() {
      var thisdf = this.get('selectedGroup');
      this.get('holdvaribles').setupHoldSelectedFittingGroup(thisdf);
      this.set('fitting.serialNumber', this.get('serialNumber'));
      this.set('fitting.deviceReference', this.get('deviceReference'));
      this.set('fitting.installDate', this.get('installDate'));
    },
    init: function init() {
      this._super.apply(this, arguments);
      var allgroups = this.get('store').peekAll('group');
      // eslint-disable-next-line ember-suave/no-direct-property-access
      var newgroupps = _ember.default.$.map(allgroups.content, function (item) {
        return { label: item.__data.name, value: item.id };
      });

      this.set('displayGroups', newgroupps);

      var test = this.get('holdvaribles').showallfittingpagebool();
      this.set('allfittings', test);
    },


    actions: {
      addFitting: function addFitting() {
        this.set('didValidate', true);
        var tmp = this.get('displayGroups');
        if (tmp.length === 0) {

          this.showErrorFlash('Please create a group first before adding a fitting');
          return;
        }

        if (this.get('validations.isValid')) {
          this.updateFitting();

          this.set('isQueryPending', true);
          this.sendAction('addFitting', this.get('fitting'));
        }
      }
    }

  });
});