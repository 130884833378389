define('clever-sparky-frontend/components/forms/organisation-new-form', ['exports', 'clever-sparky-frontend/components/forms/organisation-form'], function (exports, _organisationForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _organisationForm.default.extend({
    actions: {
      save: function save(organisation) {
        this.set('didValidate', true);

        if (this.get('validations.isValid')) {
          this.toggleProperty('isQueryPending');
          this.updateOrganisation();

          this.attrs.save(organisation);
        }
      }
    }
  });
});