define('clever-sparky-frontend/components/places-search-input', ['exports', 'ember', 'clever-sparky-frontend/mixins/click-outside'], function (exports, _ember, _clickOutside) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed,
      inject = _ember.default.inject,
      run = _ember.default.run;
  exports.default = Component.extend(_clickOutside.default, {

    // Services

    store: inject.service('store'),

    // Component Attributes

    classNames: ['places-search-input'],

    clickOutsideParentElementSelector: '.places-search-input',

    // Attributes

    value: '',

    places: [],

    isQueryPending: false,

    hasSearchResults: false,

    lastQuery: '',

    selectedPlaceName: '',

    // Properties

    placesDisplay: computed('places.[]', function () {
      return this.get('places').map(function (place) {
        return {
          name: place.get('addressPrimary') + ', ' + place.get('addressSecondary'),
          value: place
        };
      });
    }),

    showProgressBar: computed.alias('isQueryPending'),

    showSearchResults: computed('hasSearchResults', 'isQueryPending', function () {
      return this.get('hasSearchResults') && !this.get('isQueryPending');
    }),

    hasPlaces: computed.gt('places.length', 0),

    hasSelectedPlace: computed.bool('selectedPlace'),

    // Methods

    onClickOutside: function onClickOutside() {
      this.resetPlaces();
    },
    resetSelectedPlace: function resetSelectedPlace() {
      this.set('placeId', null);
    },
    resetPlaces: function resetPlaces() {
      this.set('places', []);
    },
    queryPlaces: function queryPlaces(query) {
      var _this = this;

      this.resetSelectedPlace();
      this.set('isQueryPending', true);

      this.get('store').query('place', {
        filter: {
          name: query
        }
      }).then(function (places) {
        _this.set('lastQuery', query);
        _this.set('isQueryPending', false);
        _this.set('places', places);
      });
    },
    checkQuery: function checkQuery(query) {
      var hasQuery = !!query;
      var isInputsDisabled = this.get('disabled');
      var isValid = query.length >= 3;
      var isNew = query !== this.get('lastQuery');
      var isNotMatchingCurrentPlace = query !== this.get('selectedPlaceName');

      return !isInputsDisabled && hasQuery && isValid && isNew && isNotMatchingCurrentPlace;
    },


    actions: {
      selectResult: function selectResult(place) {
        this.set('value', place.name);
        this.set('selectedPlaceName', place.name);

        this.set('placeId', place.value.get('id'));
        this.resetPlaces();
      },
      updateQuery: function updateQuery(query) {
        if (this.checkQuery(query)) {
          run.debounce(this, this.queryPlaces, query, 300, true);
        }
      },
      clearSearch: function clearSearch() {
        this.set('value', '');
        this.resetPlaces();
      }
    }

  });
});