define('clever-sparky-frontend/mixins/countries-and-states', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = _ember.default.Mixin;


  function getOptionsObject(optionsArray) {
    return optionsArray.reduce(function (result, item) {
      var optionObj = {
        label: item,
        value: item
      };
      result.push(optionObj);
      return result;
    }, []);
  }

  exports.default = Mixin.create({
    countries: getOptionsObject(['Australia', 'New Zealand']),

    australianStates: getOptionsObject(['Australian Capital Territory', 'New South Wales', 'Northern Territory', 'Queensland', 'South Australia', 'Tasmania', 'Victoria', 'Western Australia']),
    newZealandStates: getOptionsObject(['Auckland', 'Bay of Plenty', 'Christchurch', 'Dunedin', 'Hamilton', 'Hastings', 'Invercargill', 'Manawatu', 'Napier', 'Nelson', 'New Plymouth', 'Palmerston North', 'Queenstown', 'Rotorua', 'Tauranga', 'The South Island', 'Timaru', 'Wairarapa', 'Wanganui', 'Wellington', 'Whangarei'])
  });
});