define('clever-sparky-frontend/routes/site/fittings/delete-group', ['exports', 'ember', 'clever-sparky-frontend/mixins/handle-error'], function (exports, _ember, _handleError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP,
      computed = _ember.default.computed;
  exports.default = Route.extend(_handleError.default, {
    model: function model() {
      return RSVP.hash({
        group: this.modelFor('groups').group
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('canDeleteGroup', computed.reads('model.group.canDelete'));
      controller.set('group', model.group);
      controller.set('site', model.group.get('site'));
      controller.set('isQueryPending', false);
    },


    actions: {
      deleteGroup: function deleteGroup(group) {
        var _this = this;

        this.controller.set('isQueryPending', true);

        group.destroyRecord().then(function () {
          _this.transitionTo('site', _this.controller.get('site.id'));
          group.unloadRecord();
          _this.showSuccessFlash('Group Deleted');
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        }).finally(function () {
          _this.set('isQueryPending', false);
        });
      },
      returnToGroups: function returnToGroups(site) {
        this.transitionTo('site', site.get('id'));
      },
      closeModal: function closeModal() {
        this.transitionTo('site.groups');
      }
    }
  });
});