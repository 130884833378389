define('clever-sparky-frontend/routes/groups/fittings/show/maintenance', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP,
      computed = _ember.default.computed;
  exports.default = Route.extend({
    model: function model() {
      var _modelFor = this.modelFor('groups.fittings.show'),
          fitting = _modelFor.fitting,
          maintenances = _modelFor.maintenances;

      return RSVP.hash({
        fitting: fitting,
        maintenances: maintenances,
        newMaintenance: this.store.createRecord('maintenance')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('groups.fittings.show').set('showEdit', false);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('canEdit', false);
        this.controllerFor('groups.fittings.show').set('showEdit', computed.not('canEdit'));
      }
    },


    actions: {
      showSuccessFlashOnSave: function showSuccessFlashOnSave() {
        this.controllerFor('groups.fittings.show').set('showSuccess', true);
      }
    }

  });
});