define('clever-sparky-frontend/initializers/user-agent', ['exports', 'ember-useragent/initializers/user-agent'], function (exports, _userAgent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'user-agent',
    initialize: _userAgent.initialize
  };
});