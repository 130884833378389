define('clever-sparky-frontend/components/shopping-cart-group-table', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend({

    // Atributes
    currentOrderGroups: [],

    selectedGroups: [],

    isSelectingGroups: false,

    isCheckingOut: false,

    isCheckoutDisabled: false,

    // Properties
    hasNoRecords: computed.empty('currentOrderGroups'),

    noGroupsSelected: computed.empty('selectedGroups'),

    actions: {
      showOrderItems: function showOrderItems(group) {
        this.attrs.showOrderItems(group);
      },
      cancelCheckout: function cancelCheckout() {
        this.set('isCheckingOut', false);
        this.set('selectedGroups', []);
      },
      checkout: function checkout() {
        this.set('isCheckingOut', true);
      },
      confirmCheckout: function confirmCheckout(selectedGroups) {
        this.attrs.showCheckoutConfirmationModal(selectedGroups);
      }
    }

  });
});