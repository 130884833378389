define('clever-sparky-frontend/models/fitting', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    // Relationships
    group: belongsTo('group'),

    latestTest: belongsTo('ledtest'),

    maintenances: hasMany('maintenance'),

    testEvent: belongsTo('test-event'),

    testEventFittings: hasMany('test-event-fitting'),

    // Attributes
    serialNumber: attr('string'),

    fittingType: attr('string'),

    description: attr('string'),

    deviceReference: attr('string'),

    location: attr('string'),

    building: attr('string'),

    level: attr('string'),

    switchboard: attr('string'),

    circuit: attr('string'),

    assetCode: attr('string'),

    batteryStatus: attr('string'),

    controlGearStatus: attr('string'),

    lampStatus: attr('string'),

    installDate: attr('string'),

    faulty: attr('boolean'),

    deletedAt: attr('string'),

    commissioned: attr('boolean'),

    availablePartTypes: attr(),

    orderedPartTypes: attr(),

    // Properties

    rowLabel: computed('deviceReference', 'location', 'fittingType', function () {
      var location = this.get('location');
      var deviceReference = this.get('deviceReference');
      var fittingType = this.get('fittingType');

      return (location ? location.concat(' -') : '') + ' ' + deviceReference + ' ' + (fittingType ? '- '.concat(fittingType) : '');
    }),

    availablePartTypesArray: computed('availablePartTypes', function () {
      return this.get('availablePartTypes')['part-types'];
    }),

    orderedPartTypesArray: computed('orderedPartTypes', function () {
      return this.get('orderedPartTypes')['part-types'];
    })

  });
});