define('clever-sparky-frontend/components/forms/invite-user-form', ['exports', 'ember', 'clever-sparky-frontend/mixins/toggle-label-focus', 'clever-sparky-frontend/validators/forms/invite-user'], function (exports, _ember, _toggleLabelFocus, _inviteUser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend(_inviteUser.InviteUserValidations, _toggleLabelFocus.default, {

    didValidate: false,

    email: computed(function () {
      return this.get('invite.email');
    }),

    didInsertElement: function didInsertElement() {
      this.toggleLabelFocus(this.$('#invite-user-form'));
    },


    actions: {
      invite: function invite() {
        this.set('didValidate', true);

        if (this.get('validations.isValid')) {
          this.set('isQueryPending', true);
          this.set('invite.email', this.get('email'));
          this.attrs.save(this.get('invite'));
          this.set('didValidate', false);
        }
      }
    }

  });
});