define('clever-sparky-frontend/services/cart-checkout', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = _ember.default.Service,
      inject = _ember.default.inject,
      ENV = _ember.default.ENV;
  exports.default = Service.extend({
    requestService: inject.service('request-service'),

    _checkoutURL: function _checkoutURL(orderId) {
      return ENV.api.host + '/' + ENV.api.namespace + '/orders/' + orderId + '/checkout';
    },


    _checkoutMethod: 'POST',

    _type: 'groups',

    checkout: function checkout(order, batch) {
      var method = this.get('_checkoutMethod');
      var url = this._checkoutURL(order.get('id'));
      var type = this.get('_type');

      var data = {
        data: []
      };

      batch.forEach(function (item) {
        data.data.push({ type: type, id: item.id });
      });

      return this.get('requestService').sendAuthenticatedRequest(url, method, data);
    }
  });
});