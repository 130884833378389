define("clever-sparky-frontend/s3-upload/service", ["exports", "ember"], function (exports, _ember) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Service.extend({
    uploadFile: function uploadFile(file, signedRequest) {
      return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open("PUT", signedRequest);
        // xhr.setRequestHeader('x-amz-acl', 'public-read')
        xhr.onload = function () {
          resolve();
        };
        xhr.send(file);
      });
    }
  });
});