define('clever-sparky-frontend/components/site-admin', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend({
    hasNoRecords: computed.equal('sites.length', 0),

    isQueryPending: false,

    postOnboardingTourNames: [],

    viewRestrictedSitesTourNames: ['viewRestrictedSites'],

    actions: {
      showSite: function showSite(site) {
        this.sendAction('showSiteDetails', site);
      },
      showLogin: function showLogin() {
        // this.sendAction('showAdminLogin', userid);
      },
      requestAccess: function requestAccess(site) {
        this.set('isQueryPending', true);
        this.attrs.requestSiteAccess(site);
      },
      toggleSort: function toggleSort(column) {
        this.attrs.sort(column);
      }
    }

  });
});