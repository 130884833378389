define('clever-sparky-frontend/components/forms/test-event-groups-form', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component;
  exports.default = Component.extend({

    selectedGroups: [],

    showErrorMessage: false,

    actions: {
      updateSelection: function updateSelection(newSelection, value, operation) {
        if (value.name === 'All Groups' && operation === 'added') {
          this.set('selectedGroups', this.get('groupOptions').copy());
        } else if (value.name === 'All Groups' && operation === 'removed') {
          this.set('selectedGroups', []);
        } else if (operation === 'removed') {
          if (newSelection.length && newSelection[0].name === 'All Groups') {
            var selectedGroups = newSelection.slice(1);
            this.set('selectedGroups', selectedGroups);
          }
        }
        this.set('showErrorMessage', false);
      },
      actionClicked: function actionClicked() {
        var selectedGroups = this.get('selectedGroups');
        if (selectedGroups.length && selectedGroups[0].name === 'All Groups') {
          selectedGroups = selectedGroups.slice(1);
        }

        if (!selectedGroups.length) {
          this.set('showErrorMessage', true);
        } else {
          this.attrs.clickAction(selectedGroups);
        }
      }
    }

  });
});