define('clever-sparky-frontend/controllers/signup/user', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      computed = _ember.default.computed;
  exports.default = Controller.extend({
    token: null,

    user: computed.alias('model.user'),

    organisation: computed.alias('model.organisation'),

    hasToken: computed.bool('token'),

    isTechnicianSignup: computed.alias('hasToken'),

    isQueryPending: false
  });
});