define('clever-sparky-frontend/controllers/users', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      computed = _ember.default.computed;
  exports.default = Controller.extend({

    currentUser: computed.alias('model.user'),

    employments: computed.alias('model.employments'),

    invite: computed.alias('model.invite'),

    displayInviteUserModal: false,

    isQueryPending: false,

    viewUsersTourNames: ['viewUsers'],

    actions: {
      showInviteUserModal: function showInviteUserModal() {
        this.set('displayInviteUserModal', true);
      },
      closeInviteUserModal: function closeInviteUserModal() {
        this.set('displayInviteUserModal', false);
      }
    }

  });
});