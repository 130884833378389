define('clever-sparky-frontend/controllers/groups/fittings/show/maintenance', ['exports', 'ember', 'clever-sparky-frontend/mixins/handle-error'], function (exports, _ember, _handleError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      computed = _ember.default.computed;
  exports.default = Controller.extend(_handleError.default, {

    fitting: computed.alias('model.fitting'),

    maintenances: computed.alias('model.maintenances'),

    newMaintenance: computed.alias('model.newMaintenance'),

    canEdit: false,

    isQueryPending: false,

    showNew: computed.not('canEdit'),

    actions: {
      save: function save(maintenance) {
        var _this = this;

        maintenance.save().then(function () {
          _this.send('showSuccessFlashOnSave');
          _this.set('canEdit', false);
          // refresh the top most parent route
          _this.send('refreshRoute');
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        }).finally(function () {
          _this.set('isQueryPending', false);
        });
      }
    }

  });
});