define('clever-sparky-frontend/routes/analytics', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'clever-sparky-frontend/mixins/show-nav-bar', 'ember-can'], function (exports, _ember, _authenticatedRouteMixin, _showNavBar, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _showNavBar.default, _emberCan.CanMixin, {
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      var _modelFor = this.modelFor('application'),
          currentEmployment = _modelFor.currentEmployment;

      if (!this.can('view user-management', currentEmployment)) {
        this.replaceWith('sites');
      }
    },
    model: function model() {
      var _modelFor2 = this.modelFor('application'),
          currentOrganisation = _modelFor2.currentOrganisation;

      return RSVP.hash({
        organisationAnalytics: this.get('store').queryRecord('organisation-analytics', { currentOrganisationId: currentOrganisation.id })
      });
    }
  });
});