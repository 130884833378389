define('clever-sparky-frontend/serializers/admin', ['exports', 'clever-sparky-frontend/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      return payload;
    }
  });
});