define('clever-sparky-frontend/components/users-dropdown-menu', ['exports', 'clever-sparky-frontend/components/dropdown-menu'], function (exports, _dropdownMenu) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _dropdownMenu.default.extend({

    clickOutsideParentElementSelector: '.users-dropdown-menu',

    classNames: ['dropdown-menu users-dropdown-menu']

  });
});