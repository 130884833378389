define('clever-sparky-frontend/validators/forms/invite-to-site-form-validator', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.InviteToSiteFormValidations = undefined;
  var computed = _ember.default.computed;
  var InviteToSiteFormValidations = exports.InviteToSiteFormValidations = (0, _emberCpValidations.buildValidations)({
    technicianId: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: computed.not('model.isCopyingSitePermission'),
      message: 'Please select a user'
    }),

    selectedSites: (0, _emberCpValidations.validator)('array-length', {
      min: 1,
      disabled: computed.alias('model.isCopyingSitePermission'),
      dependentKeys: ['model.selectedSites.[]'],
      message: 'Please select a site'
    })

  });

  exports.default = { InviteToSiteFormValidations: InviteToSiteFormValidations };
});