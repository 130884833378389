define('clever-sparky-frontend/routes/groups/fittings/edit-group', ['exports', 'ember', 'clever-sparky-frontend/mixins/handle-error'], function (exports, _ember, _handleError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP;
  exports.default = Route.extend(_handleError.default, {
    model: function model() {
      return RSVP.hash({
        group: this.modelFor('groups').group
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('group', model.group);
    },


    actions: {
      save: function save(group) {
        var _this = this;

        group.save().then(function () {
          _this.transitionTo('groups.fittings');
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        }).finally(function () {
          _this.controller.set('isQueryPending', false);
        });
      }
    }
  });
});