define('clever-sparky-frontend/mixins/escape-keypress', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = _ember.default.Mixin;
  exports.default = Mixin.create({
    bindOnEscKeypress: function bindOnEscKeypress() {
      this.set('boundOnEscKeypress', function (e) {
        if (e.which === 27) {
          e.stopPropagation();
          e.preventDefault();
          this.onEscKeypress();
        }
      }.bind(this));
    },
    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);
      this.bindOnEscKeypress();
      window.addEventListener('keydown', this.get('boundOnEscKeypress'), false);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      window.removeEventListener('keydown', this.get('boundOnEscKeypress'), false);
    }
  });
});