define('clever-sparky-frontend/components/order-item-table', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend({

    // Atributes
    orderItems: [],

    selectedItems: [],

    isDeletingItems: false,

    isCheckoutDisabled: false,

    // Properties
    hasNoRecords: computed.equal('orderItems.length', 0),

    noItemsSelected: computed.equal('selectedItems.length', 0),

    actions: {
      cancelDeleteItems: function cancelDeleteItems() {
        this.set('isDeletingItems', false);
        this.set('selectedItems', []);
      },
      deleteItems: function deleteItems() {
        this.set('isDeletingItems', true);
      },
      confirmDeleteItems: function confirmDeleteItems(selectedItems) {
        this.attrs.batchDeleteItems(selectedItems);
      },
      checkout: function checkout() {
        this.attrs.showCheckoutConfirmationModal();
      }
    }

  });
});