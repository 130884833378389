define('clever-sparky-frontend/components/replacement-option-button', ['exports', 'ember', 'ember-macaroni', 'clever-sparky-frontend/mixins/option-lookup'], function (exports, _ember, _emberMacaroni, _optionLookup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed,
      run = _ember.default.run;
  exports.default = Component.extend(_optionLookup.default, {

    // Attributes
    classNames: 'replacement-option-button',

    classNameBindings: ['hideReplacementOption:hide-option'],

    partType: '',

    // Properties

    hasAlreadyOrderedReplacement: computed(function () {
      return this.get('fitting.orderedPartTypesArray').mapBy('type').includes(this.get('partType'));
    }),

    isSelected: computed.reads('hasAlreadyOrderedReplacement'),

    hideReplacementOption: computed('fitting.availablePartTypesArray.[]', 'hasAlreadyOrderedReplacement', 'partType', function () {
      var partType = this.get('partType');
      return !this.get('fitting.availablePartTypesArray').mapBy('type').includes(partType) && !this.get('hasAlreadyOrderedReplacement');
    }),

    partTypeObj: (0, _emberMacaroni.findFromCollectionByKey)('partTypeOptions', 'value', 'partType'),

    // Methods

    handleOrderItemRequest: function handleOrderItemRequest() {
      if (this.get('isSelected') && !this.get('hasAlreadyOrderedReplacement')) {
        this.set('hasAlreadyOrderedReplacement', true);
        this.attrs.addOrderItem(this.get('fitting'), this.get('partType'));
      } else if (!this.get('isSelected') && this.get('hasAlreadyOrderedReplacement')) {
        this.set('hasAlreadyOrderedReplacement', false);
        this.attrs.removeOrderItem(this.get('fitting'), this.get('partType'));
      }
    },


    actions: {
      buttonClicked: function buttonClicked() {
        this.toggleProperty('isSelected');
        run.debounce(this, this.handleOrderItemRequest, 300);
      }
    }

  });
});