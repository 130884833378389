define('clever-sparky-frontend/components/fitting-table', ['exports', 'ember', 'clever-sparky-frontend/mixins/handle-error'], function (exports, _ember, _handleError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed,
      observer = _ember.default.observer,
      $ = _ember.default.$,
      ENV = _ember.default.ENV,
      Emb = _ember.default.Emb,
      inject = _ember.default.inject;
  exports.default = Component.extend(_handleError.default, {

    // Attributes
    holdvaribles: inject.service('holdvaribles'),

    selectedFittings: [],

    isDeletingFittings: false,

    isListSorted: false,

    fitleredbynamebool: false,

    isMovingFittings: false,

    isOrderingReplacements: false,

    isSelectAllFittings: null,

    isSelectAllComponents: null,

    isSelectAllDecals: null,

    viewGroupTourNames: ['viewGroup'],

    viewFittingTourNames: ['viewFitting'],
    // Properties
    sortAscending: false,

    sortProperties: ['id'],
    sortPropertiesDR: ['updatedAt'],

    arrangedContent: _ember.default.computed('fittings', 'sortPropertiesDR', 'sortAscending', function () {
      var _this2 = this;

      return this.get('fittings').toArray().sort(function (a, b) {
        var sortProperty = _this2.get('sortProperties')[0];
        if (_this2.get('sortAscending')) {
          var newa = parseInt(a.get(sortProperty).match(/\d+/g));
          var newb = parseInt(b.get(sortProperty).match(/\d+/g));

          if (newa > newb) {
            return 1;
          } else {
            return -1;
          }
        } else {
          var _newa = parseInt(a.get(sortProperty).match(/\d+/g));
          var _newb = parseInt(b.get(sortProperty).match(/\d+/g));

          if (_newa < _newb) {
            return 1;
          } else {
            return -1;
          }
        }
      });
    }),

    noteTitle: '',

    hasNoRecords: computed.equal('fittings.length', 0),

    noFittingsSelected: computed.equal('selectedFittings.length', 0),

    isSelectingFittings: computed.or('isDeletingFittings', 'isMovingFittings'),

    isActioningFittings: computed.or('isSelectingFittings', 'isOrderingReplacements'),

    isCommissioned: computed.reads('commissioned'),

    isNotCommissioned: computed.not('isCommissioned'),

    isgroups: false,

    modelLength: _ember.default.computed('model', function () {
      return this.get('model').length;
    }),

    allfittings: false,
    mod: computed.alias('model.data'),

    count: 0,

    commissionedFittingsCount: computed.reads('group.commissionedFittingsCount'),

    notCommissionedFittingsCount: computed.reads('group.notCommissionedFittingsCount'),

    isEmptyGroup: computed('commissionedFittingsCount', 'notCommissionedFittingsCount', function () {
      return this.get('commissionedFittingsCount') + this.get('notCommissionedFittingsCount') === 0;
    }),
    didInsertElement: function didInsertElement() {
      var _this3 = this;

      var local = localStorage.getItem('ember_simple_auth-session');
      var test = JSON.parse(local);
      var tt = test['authenticated'];
      var tt2 = tt['session'];
      var sessionID = tt2['session_id'];
      var token = tt2['token'];

      var url = ENV.api.host + '/' + ENV.api.namespace + '/filtersapi';
      var groupid = this.get('group');
      if (groupid) {
        this.set('isgroups', true);
      }

      if (groupid) {

        $.ajax({
          url: url,
          method: 'POST',
          data: JSON.stringify({
            data: {
              attributes: {
                usage: 'get',
                groupid: groupid.id
              },
              type: 'sessions'
            }
          }),
          headers: {
            'Content-Type': 'application/json',
            'X-Session-Id': sessionID,
            'X-Session-Token': token
          },
          success: function success() {},

          error: function error(resp) {
            var thistxt = resp.responseText;

            if (thistxt === 'Decending') {
              _this3.set('sortAscending', false);
            } else {
              _this3.set('sortAscending', true);
            }

            _this3.set('sortProperties', ['deviceReference']);

            _this3.set('fittings', _this3.get('arrangedContent'));
          }
        });
      }
    },
    checkFilterMatch: function checkFilterMatch(theObject, str) {
      var match = void 0;
      match = false;
      if (theObject.get('deviceReference') == str) {
        return true;
      }
      return match;
    },

    fitleredbyname: function () {

      return this.get('fittings').filter(function (_this) {
        return function (theObject) {
          if (_this.get('noteTitle')) {
            return _this.checkFilterMatch(theObject, _this.get('noteTitle'));
          } else {
            return true;
          }
        };
      }(this));
    }.property('noteTitle', 'sortProperties'),

    hasFaultyComponents: computed('fittings.@each.faulty', function () {
      return this.get('fittings').isAny('faulty', true);
    }),

    // Observers

    isSelectAllFittingsObserver: observer('isSelectAllFittings', function () {
      if (this.get('isSelectAllFittings')) {
        $.each($('.replacement-fitting-option .replacement-option-button .button.unselected'), function (index, replacementOptionButton) {
          replacementOptionButton.click();
        });
      } else if (this.get('isSelectAllFittings') === false) {
        $.each($('.replacement-fitting-option .replacement-option-button .button.selected'), function (index, replacementOptionButton) {
          replacementOptionButton.click();
        });
      }
    }),

    isSelectAllComponentsObserver: observer('isSelectAllComponents', function () {
      if (this.get('isSelectAllComponents')) {
        $.each($('.replacement-faulty-component-option .replacement-option-button .button.unselected:visible'), function (index, replacementOptionButton) {
          replacementOptionButton.click();
        });
      } else if (this.get('isSelectAllComponents') === false) {
        $.each($('.replacement-faulty-component-option .replacement-option-button .button.selected:visible'), function (index, replacementOptionButton) {
          replacementOptionButton.click();
        });
      }
    }),

    isSelectAllDecalsObserver: observer('isSelectAllDecals', function () {
      if (this.get('isSelectAllDecals')) {
        $.each($('.replacement-decal-option .replacement-option-button .button.unselected'), function (index, replacementOptionButton) {
          replacementOptionButton.click();
        });
      } else if (this.get('isSelectAllDecals') === false) {
        $.each($('.replacement-decal-option .replacement-option-button .button.selected'), function (index, replacementOptionButton) {
          replacementOptionButton.click();
        });
      }
    }),

    willDestroyElement: function willDestroyElement() {
      this.set('selectedFittings', []);
      this.set('isMovingFittings', false);
      this.set('isDeletingFittings', false);
      this.set('fitleredbynamebool', false);
    },

    searchTextChanged: function () {
      this.set('fitleredbynamebool', true);
    }.observes('noteTitle'),

    checkValue: _ember.default.observer('rows.@each.value', function () {
      var unique = _ember.default.get(this, 'rows').uniqBy('value');

      var tmp = this.get('count');
      this.set('count', tmp + 1);
    }),

    runtest: function runtest() {},
    init: function init() {
      this._super.apply(this, arguments);
      var groupid = this.get('group');
      if (groupid == null) {
        var fittings = this.get('fittings');
        var totalfittings = this.get('mod');

        var notcommissioned = fittings.filter(function (item) {
          return item.data.commissioned === false;
        });

        var commissioned = totalfittings - notcommissioned.length;
        this.set('commissionedFittingsCount', commissioned);
        this.set('notCommissionedFittingsCount', notcommissioned.length);
      }
      var isallfittings = this.get('holdvaribles').showallfittingpagebool();
      this.set('allfittings', isallfittings);
    },


    actions: {
      sortBy: function sortBy(property) {
        this.set('sortProperties', [property]);
        this.toggleProperty('sortAscending');
        this.set('fittings', this.get('arrangedContent')); // set the model to the sorted array

        var groupid = this.get('group');
        var sortingmethodtemp = '';
        if (this.get('sortAscending')) {
          sortingmethodtemp = 'Ascending';
        } else {
          sortingmethodtemp = 'Decending';
        }

        var url = ENV.api.host + '/' + ENV.api.namespace + '/filtersapi';
        var local = localStorage.getItem('ember_simple_auth-session');
        var test = JSON.parse(local);
        var tt = test['authenticated'];
        var tt2 = tt['session'];
        var sessionID = tt2['session_id'];
        var token = tt2['token'];
        $.ajax({
          url: url,
          method: 'POST',
          data: JSON.stringify({
            data: {
              attributes: {
                usage: 'set',
                groupid: groupid.id,
                method: sortingmethodtemp

              },
              type: 'sessions'
            }
          }),
          headers: {
            'Content-Type': 'application/json',
            'X-Session-Id': sessionID,
            'X-Session-Token': token
          },
          success: function success() {},
          error: function error() {}
        });
      },
      textchangefilter: function textchangefilter() {
        this.set('noteTitle', 'Test!');
      },
      showFitting: function showFitting(fitting) {
        this.set('fitleredbynamebool', false);
        this.sendAction('showFittingDetails', fitting);
      },
      toggleDeleteFittings: function toggleDeleteFittings() {
        this.toggleProperty('isDeletingFittings');
      },
      sortFittings: function sortFittings() {
        this.toggleProperty('isDeletingFittings');
        this.toggleProperty('isListSorted');
      },
      toggleMoveFittings: function toggleMoveFittings() {
        this.toggleProperty('isMovingFittings');
      },
      deleteFittings: function deleteFittings(selectedFittings) {
        this.attrs.batchDeleteFittings(selectedFittings);
      },
      moveFittings: function moveFittings(selectedFittings) {
        this.attrs.batchMoveFittings(selectedFittings);
      },
      cancelSelectFittings: function cancelSelectFittings() {
        this.set('selectedFittings', []);
        this.set('isMovingFittings', false);
        this.set('isDeletingFittings', false);
      },
      showOrderReplacements: function showOrderReplacements() {
        this.attrs.backToFittingsIndex();
        this.set('isOrderingReplacements', true);
      },
      hideOrderReplacements: function hideOrderReplacements() {
        this.set('isOrderingReplacements', false);
        this.set('isSelectAllFittings', null);
        this.set('isSelectAllComponents', null);
        this.set('isSelectAllDecals', null);
        this.showSuccessFlash('Cart successfully updated', { timeout: 1000 });
        this.attrs.refreshRoute();
      }
    }
  });
});