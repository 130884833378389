define('clever-sparky-frontend/serializers/organisation-analytics', ['exports', 'clever-sparky-frontend/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'organisation-analytics';
    }
  });
});