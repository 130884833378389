define('clever-sparky-frontend/validators/user-validator', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.UserValidations = undefined;
  var computed = _ember.default.computed;
  var UserValidations = exports.UserValidations = (0, _emberCpValidations.buildValidations)({
    firstName: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please enter your first name',
      ignoreBlank: true
    }),

    lastName: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please enter your last name',
      ignoreBlank: true
    }),

    userState: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please select your state',
      ignoreBlank: true
    }),

    userCountry: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please select your country',
      ignoreBlank: true
    }),

    phone: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please enter your mobile number',
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      message: 'Phone number must only include numbers'
    })],

    password: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please enter your password',
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('length', {
      min: 8,
      message: 'Password must be at least 8 characters long'
    })],

    passwordConfirmation: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please confirm your password',
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: 'Your passwords don’t match, please try again '
    })],

    email: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please enter your email address',
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email',
      message: 'Please enter a valid email'
    })],

    organisationName: (0, _emberCpValidations.validator)('length', {
      min: 0,
      message: 'Please enter your business name',
      ignoreBlank: true
    }),

    organisationCountry: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: computed.empty('model.businessName'),
      message: 'Please select your business\'s country',
      ignoreBlank: true
    }),

    organisationState: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: computed.not('model.businessNameFilled'),
      message: 'Please select your business\'s state',
      ignoreBlank: true
    })
  });

  exports.default = { UserValidations: UserValidations };
});