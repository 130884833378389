define('clever-sparky-frontend/adapters/tooltip-event', ['exports', 'ember', 'clever-sparky-frontend/adapters/application'], function (exports, _ember, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ENV = _ember.default.ENV;
  exports.default = _application.default.extend({
    urlForQuery: function urlForQuery() {
      return ENV.api.host + '/' + ENV.api.namespace + '/users/me/tooltip-events';
    },
    urlForCreateRecord: function urlForCreateRecord() {
      return ENV.api.host + '/' + ENV.api.namespace + '/tooltip-events';
    }
  });
});