define('clever-sparky-frontend/app', ['exports', 'ember', 'clever-sparky-frontend/resolver', 'ember-load-initializers', 'clever-sparky-frontend/config/environment'], function (exports, _ember, _resolver, _emberLoadInitializers, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Application = _ember.default.Application;


  var App = void 0;

  App = Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    LOG_TRANSITIONS_INTERNAL: true,

    // LOG_ACTIVE_GENERATION: true,
    // LOG_VIEW_LOOKUPS: true,
    // LOG_RESOLVER: true,
    Resolver: _resolver.default
  });
  // RSVP.on('error', function(error) {
  //   // Ember.assert(error, false);
  // });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;
});