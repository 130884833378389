define('clever-sparky-frontend/routes/forgot-password/success', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route;
  exports.default = Route.extend({
    beforeModel: function beforeModel(transition) {
      if (!transition.queryParams.email) {
        this.transitionTo('forgot-password');
      }
    }
  });
});