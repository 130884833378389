define('clever-sparky-frontend/components/group-dropdown-menu', ['exports', 'ember', 'clever-sparky-frontend/components/dropdown-menu'], function (exports, _ember, _dropdownMenu) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed;
  exports.default = _dropdownMenu.default.extend({
    // Attributes

    clickOutsideParentElementSelector: '.group-dropdown-menu',

    classNames: ['dropdown-menu', 'group-dropdown-menu'],

    // Properties

    hasFittings: computed.bool('fittings.length'),

    hasNoFittings: computed.not('hasFittings'),

    hasOtherGroups: computed.gt('site.groupsCount', 1),

    noOtherGroups: computed.not('hasOtherGroups'),

    noFittingsOrGroups: computed.or('hasNoFittings', 'noOtherGroups'),

    isShoppingCartEnabled: computed(function () {
      return this.get('features').isEnabled('shoppingCart');
    }),

    actions: {
      toggleDeleteFittings: function toggleDeleteFittings() {
        this.attrs.toggleDeleteFittings();
        this.send('close');
      },
      toggleMoveFittings: function toggleMoveFittings() {
        this.attrs.toggleMoveFittings();
        this.send('close');
      },
      showOrderReplacements: function showOrderReplacements() {
        this.attrs.showOrderReplacements();
        this.send('close');
      }
    }
  });
});