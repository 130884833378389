define('clever-sparky-frontend/routes/site/fittings/show/details', ['exports', 'clever-sparky-frontend/routes/base-routes/site-fittings-tab', 'clever-sparky-frontend/mixins/handle-error'], function (exports, _siteFittingsTab, _handleError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _siteFittingsTab.default.extend(_handleError.default, {
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      // overriding setupController function of BaseGroupsFittingsTabRoute
      controller.set('site', model.site);
    },


    actions: {
      save: function save(fitting) {
        var _this = this;

        fitting.save().then(function () {
          _this.controllerFor('site.fittings.show').set('showSuccess', true);
          _this.controllerFor('site.fittings.show').set('canEdit', false);
          _this.controller.set('canEdit', false);
          // refresh the top most parent route
          _this.send('refreshRoute');
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        }).finally(function () {
          _this.controller.set('isQueryPending', false);
        });
      },
      commission: function commission(fitting) {
        var _this2 = this;

        fitting.save().then(function () {
          _this2.showSuccessFlash('Fitting commissioned');
          // refresh the top most parent route
          _this2.send('refreshRoute');
        }).catch(function (reason) {
          _this2.handleRailsError(reason);
        });
      }
    }

  });
});