define('clever-sparky-frontend/components/test-event-modal', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend({

    isEditMode: computed.equal('type', 'edit'),

    isDuplicateMode: computed.equal('type', 'duplicate'),

    actions: {
      closeModal: function closeModal() {
        this.attrs.closeModal();
      }
    }

  });
});