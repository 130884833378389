define('clever-sparky-frontend/components/forms/reset-password-form', ['exports', 'ember', 'clever-sparky-frontend/validators/reset-password-validator', 'clever-sparky-frontend/mixins/toggle-label-focus'], function (exports, _ember, _resetPasswordValidator, _toggleLabelFocus) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component;
  exports.default = Component.extend(_toggleLabelFocus.default, _resetPasswordValidator.ResetPasswordValidations, {
    didInsertElement: function didInsertElement() {
      this.toggleLabelFocus(this.$('#reset-password-form'));
    },


    isQueryPending: false,

    password: '',
    passwordConfirmation: '',

    actions: {
      resetPassword: function resetPassword(password, passwordConfirmation) {
        this.set('didValidate', true);

        if (this.get('validations.isValid')) {
          this.set('isQueryPending', true);
          this.attrs.resetPassword(this.get('token'), password, passwordConfirmation);
        }
      }
    }
  });
});