define('clever-sparky-frontend/components/forms/forgot-password-form', ['exports', 'ember', 'clever-sparky-frontend/mixins/toggle-label-focus', 'clever-sparky-frontend/validators/forgot-password-validator'], function (exports, _ember, _toggleLabelFocus, _forgotPasswordValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component;
  exports.default = Component.extend(_toggleLabelFocus.default, _forgotPasswordValidator.ForgotPasswordValidations, {
    didInsertElement: function didInsertElement() {
      this.toggleLabelFocus(this.$('#forgot-password-form'));
    },


    isQueryPending: false,

    email: '',

    actions: {
      requestPasswordReset: function requestPasswordReset(email) {
        this.set('didValidate', true);

        if (this.get('validations.isValid')) {
          this.set('isQueryPending', true);
          this.attrs.requestPasswordReset(email);
        }
      }
    }
  });
});