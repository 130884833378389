define('clever-sparky-frontend/components/onboarding-slide', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component;
  exports.default = Component.extend({

    // Attributes

    classNames: ['onboarding-slide']

  });
});