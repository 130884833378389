define('clever-sparky-frontend/serializers/ledtest', ['exports', 'clever-sparky-frontend/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      json.data.type = 'tests';
      return json;
    }
  });
});