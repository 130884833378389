define('clever-sparky-frontend/services/tours/base', ['exports', 'ember', 'ember-shepherd/services/tour'], function (exports, _ember, _tour) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed,
      observer = _ember.default.observer,
      set = _ember.default.set,
      inject = _ember.default.inject,
      $ = _ember.default.$;
  exports.default = _tour.default.extend({

    // services

    i18n: inject.service(),

    // attributes

    defaultElementClasses: 'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text shepherd-element shepherd-open sequence-tooltip',

    showCancel: true,

    copyStyles: false,

    highlightClass: 'highlight',

    sequenceElements: [],

    sequenceState: [],

    namePrefix: '',

    isActive: false,

    // button sets

    firstSequenceButtons: [{
      classes: 'button button-primary',
      text: 'Next',
      type: 'next'
    }],

    middleSequenceButtons: [{
      classes: 'button button-secondary',
      text: 'Back',
      type: 'back'
    }, {
      classes: 'button button-primary',
      text: 'Next',
      type: 'next'
    }],

    finalSequenceButtons: [{
      classes: 'button button-secondary',
      text: 'Back',
      type: 'back'
    }, {
      classes: 'button button-primary',
      text: 'Finish',
      type: 'cancel'
    }],

    singleSequenceButton: [{
      classes: 'button button-primary',
      text: 'Exit',
      type: 'cancel'
    }],

    // properties

    hasRenderedSequenceElements: computed('sequenceState.@each.hasRendered', function () {
      return this.get('sequenceState').isEvery('hasRendered', true);
    }),

    activeSequenceState: computed('sequenceState.[]', function () {
      var _this = this;

      return this.get('sequenceState').filter(function (element) {
        return _this.get('sequenceElements').mapBy('name').includes('' + element.name);
      });
    }),

    hasSteps: computed.bool('activeSequenceState.length'),

    // observers

    hasRenderedDidChange: observer('hasRenderedSequenceElements', 'isActive', 'hasSteps', function () {
      if (this.get('hasRenderedSequenceElements') && this.get('isActive') && this.get('hasSteps')) {
        this.start();
      }
    }).on('init'),

    // methods

    setup: function setup() {
      this.set('defaults', {
        classes: this.get('defaultElementClasses'),
        showCancelLink: this.get('showCancel')
      });
      this.set('modal', true);

      var steps = this.createSequence(this.get('sequenceElements'));
      this.set('steps', steps);

      this.set('isActive', true);
    },
    setHasRenderedSequenceElement: function setHasRenderedSequenceElement(elementName) {
      if (this.get('sequenceState').findBy('name', elementName)) {
        set(this.get('sequenceState').findBy('name', elementName), 'hasRendered', true);
      }
    },
    resetHasRenderedSequenceElement: function resetHasRenderedSequenceElement(elementName) {
      if (this.get('hasRenderedSequenceElements') && this.get('isActive')) {
        // stop tour that launches incorrectly before the sequenceElement hasRendered has been reset
        this.cancel();
      }
      if (this.get('sequenceState').findBy('name', elementName)) {
        set(this.get('sequenceState').findBy('name', elementName), 'hasRendered', false);
      }
    },
    deactivate: function deactivate() {
      this.set('isActive', false);
    },
    createSequence: function createSequence(elements) {
      var _this2 = this;

      var sequence = [];
      var totalElements = elements.length;

      elements.forEach(function (element, index) {
        sequence.pushObject(_this2.createSequenceElement(element, index, totalElements));
      });

      return sequence;
    },
    createSequenceElement: function createSequenceElement(element, index, totalItems) {
      var _this3 = this;

      var actionButtons = this.getActionButtons(index + 1, totalItems);

      var name = element.name;

      var attachTo = '.sequence-element-' + name;
      var position = element.position ? element.position : 'bottom';

      var content = this.createSequenceContent();
      element.content.forEach(function (text) {
        content.appendChild(_this3.createSequenceParagraph(text));
      });

      if (totalItems > 1) {
        var sequenceProgress = this.createSequenceProgress(index, totalItems);
        content.appendChild(sequenceProgress);
      }

      var sequenceElement = {
        id: this.get('namePrefix') + '-' + name,
        options: {
          attachTo: {
            element: attachTo,
            on: position
          },
          builtInButtons: actionButtons,
          text: content
        }
      };

      var tourPrefixNames = ['user-dropdown-menu', 'user-dropdown-menu-technician', 'view-site', 'view-site-technician', 'view-group', 'view-test-event-group', 'view-fitting', 'view-users', 'view-fitting-panel', 'test-event-dropdown-menu'];
      if (tourPrefixNames.includes(this.get('namePrefix'))) {
        if (!(name === 'new-user-action')) {
          sequenceElement.options.highlightClass = 'highlight';
          sequenceElement.options.copyStyles = true;
        }
      }

      if (totalItems === index + 1) {
        sequenceElement.options.when = {
          hide: function hide() {
            _this3.set('isActive', false);
          }
        };

        if (this.get('namePrefix') === 'user-dropdown-menu' || this.get('namePrefix') === 'user-dropdown-menu-technician') {
          sequenceElement.options.when = {
            hide: function hide() {
              _this3.set('isActive', false);
              setTimeout(function () {
                // keep the dropdown menu open after tour completes
                $('.user-account-dropdown-menu .dropdown-menu-trigger').click();
              }, 10);
            }
          };
        }

        if (this.get('namePrefix') === 'test-event-dropdown-menu') {
          sequenceElement.options.when = {
            hide: function hide() {
              _this3.set('isActive', false);
              setTimeout(function () {
                // keep the dropdown menu open after tour completes
                $('.test-event-group-dropdown-menu .dropdown-menu-trigger').click();
              }, 10);
            }
          };
        }
      }

      return sequenceElement;
    },
    createSequenceContent: function createSequenceContent() {
      return document.createElement('div');
    },
    createSequenceParagraph: function createSequenceParagraph(text) {
      var paragraph = document.createElement('p');
      var textNode = document.createTextNode(text);
      paragraph.appendChild(textNode);

      return paragraph;
    },
    createSequenceProgress: function createSequenceProgress(index, totalItems) {
      var sequenceProgress = document.createElement('div');
      sequenceProgress.className = 'sequence-progress';

      var sequenceProgressItem = null;

      for (var i = 0; i < totalItems; i++) {
        sequenceProgressItem = document.createElement('div');

        if (i === index) {
          sequenceProgressItem.className = 'sequence-progress-item active';
        } else {
          sequenceProgressItem.className = 'sequence-progress-item';
        }

        sequenceProgress.appendChild(sequenceProgressItem);
      }

      return sequenceProgress;
    },
    getActionButtons: function getActionButtons(currentItem, totalItems) {
      if (totalItems === 1) {
        return this.get('singleSequenceButton');
      } else if (currentItem === totalItems) {
        return this.get('finalSequenceButtons');
      } else if (currentItem === 1) {
        return this.get('firstSequenceButtons');
      } else {
        return this.get('middleSequenceButtons');
      }
    }
  });
});