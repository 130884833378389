define('clever-sparky-frontend/components/switch-account-modal', ['exports', 'ember', 'ember-macaroni'], function (exports, _ember, _emberMacaroni) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component;
  exports.default = Component.extend({
    hasJustLoggedIn: false,

    headingText: (0, _emberMacaroni.ifThenElseWithValues)('hasJustLoggedIn', 'Choose a business', 'Switch Account'),

    messageText: (0, _emberMacaroni.ifThenElseWithValues)('hasJustLoggedIn', 'Which business are you working for today?', 'Which account are you working on today?'),

    buttonText: (0, _emberMacaroni.ifThenElseWithValues)('hasJustLoggedIn', 'Go', 'Switch')

  });
});