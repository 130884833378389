define('clever-sparky-frontend/services/tours/test-event-dropdown-menu', ['exports', 'ember', 'clever-sparky-frontend/services/tours/base'], function (exports, _ember, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed;
  exports.default = _base.default.extend({

    // Attributes

    namePrefix: 'test-event-dropdown-menu',
    // sequence elements

    sequenceState: [{
      name: 'duplicate-action',
      hasRendered: false
    }, {
      name: 'archive-action',
      hasRendered: false
    }, {
      name: 'add-groups-action',
      hasRendered: false
    }],

    sequenceElements: computed(function () {
      return [{
        name: 'duplicate-action',
        position: 'left',
        content: [this.get('i18n').t('tour.test-event-dropdown-menu.duplicate-action')]
      }, {
        name: 'archive-action',
        position: 'left',
        content: [this.get('i18n').t('tour.test-event-dropdown-menu.archive-action')]
      }, {
        name: 'add-groups-action',
        position: 'left',
        content: [this.get('i18n').t('tour.test-event-dropdown-menu.add-groups-action')]
      }];
    })

  });
});