define('clever-sparky-frontend/models/organisation-analytics', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({

    fittingsCount: attr('number'),

    faultyFittingsCount: attr('number'),

    upcomingTestsCount: attr('number')

  });
});