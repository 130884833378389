define('clever-sparky-frontend/models/maintenance', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({

    // Relationships

    fitting: belongsTo('fitting'),

    // Attributes

    repairDate: attr('string'),

    repair: attr('string'),

    notes: attr('string')

  });
});