define('clever-sparky-frontend/routes/test-event-group/fittings/show/details', ['exports', 'clever-sparky-frontend/routes/base-routes/test-event-group-fittings-tab', 'clever-sparky-frontend/mixins/handle-error'], function (exports, _testEventGroupFittingsTab, _handleError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _testEventGroupFittingsTab.default.extend(_handleError.default, {
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      // overriding setupController function of BaseGroupsFittingsTabRoute
      controller.set('testEvent', model.testEvent);
      controller.set('group', model.group);
      controller.set('testEventFitting', model.testEventFitting);
    },


    actions: {
      save: function save(fitting) {
        var _this = this;

        fitting.set('testEvent', this.controller.get('testEvent'));
        fitting.save().then(function (fitting) {
          var currentTestEventFittings = fitting.get('testEventFittings').toArray().map(function (testEventFitting) {
            return testEventFitting.id;
          });
          if (!currentTestEventFittings.includes(_this.controller.get('testEventFitting.id'))) {
            // testEventFitting no longer exists i.e. case where fitting.installDate is updated to outside test event range
            _this.transitionTo('test-event-group.fittings');
          } else {
            _this.controllerFor('test-event-group.fittings.show').set('showSuccess', true);
            _this.controllerFor('test-event-group.fittings.show').set('canEdit', false);
            _this.controller.set('canEdit', false);
          }
          // refresh the top most parent route
          _this.send('refreshRoute');
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        }).finally(function () {
          _this.controller.set('isQueryPending', false);
        });
      }
    }

  });
});