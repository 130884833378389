define('clever-sparky-frontend/adapters/site', ['exports', 'ember', 'clever-sparky-frontend/adapters/application'], function (exports, _ember, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ENV = _ember.default.ENV;
  exports.default = _application.default.extend({
    urlForQuery: function urlForQuery(query) {
      var url = ENV.api.host + '/' + ENV.api.namespace + '/users/me/sites?include=test_events,fittings';

      if (query.userId) {
        url = ENV.api.host + '/' + ENV.api.namespace + '/users/' + query.userId + '/restricted-sites?';
      } else if (query.currentOrganisationId) {
        url = ENV.api.host + '/' + ENV.api.namespace + '/organisations/' + query.currentOrganisationId + '/sites?include=test_events,fittings';
      }

      return url;
    },
    urlForFindRecord: function urlForFindRecord() {
      return this._super.apply(this, arguments) + '?include=current-order';
    }
  });
});