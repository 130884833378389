define('clever-sparky-frontend/components/forms/user-form', ['exports', 'ember', 'clever-sparky-frontend/mixins/toggle-label-focus', 'clever-sparky-frontend/mixins/countries-and-states', 'clever-sparky-frontend/mixins/handle-error', 'ember-macaroni', 'clever-sparky-frontend/validators/forms/edit-user-validator'], function (exports, _ember, _toggleLabelFocus, _countriesAndStates, _handleError, _emberMacaroni, _editUserValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var service = _ember.default.inject.service;
  var Component = _ember.default.Component,
      get = _ember.default.get,
      set = _ember.default.set,
      computed = _ember.default.computed,
      observer = _ember.default.observer,
      inject = _ember.default.inject,
      ENV = _ember.default.ENV;
  exports.default = Component.extend(_toggleLabelFocus.default, _editUserValidator.EditUserValidations, _countriesAndStates.default, _handleError.default, {
    // Services
    holdvaribles: inject.service('holdvaribles'),
    filename: '',
    filenameSig: '',
    features: inject.service(),

    // Attributes

    didValidate: false,

    isEditing: false,

    imgupload: false,

    imguploadSig: false,

    inputsDisabled: computed.not('isEditing'),

    isQueryPending: false,

    wholesalers: [],

    username: '',

    password: '',

    imginfo: '',

    sigimginfo: '',

    authorizableWholesalers: ['MMEM', 'L&H'],

    // Hooks

    didInsertElement: function didInsertElement() {
      this.toggleLabelFocus(this.$('#user-form'));
    },


    // Properties
    imgbeeninguploadedalready: computed(function () {

      var tmpvalue = this.get('user.signatureurl');
      if (tmpvalue != null) {
        return true;
      } else {
        return false;
      }
    }),
    signatureurl: computed(function () {
      return this.get('user.signatureurl');
    }),

    logox: computed(function () {

      var tmpget = this.get('imgurl');

      if (tmpget === '' || tmpget == null) {
        return false;
      } else {
        return true;
      }
    }),
    nosignaturelogox: computed(function () {

      var tmpget = this.get('signatureurl');

      if (tmpget === '' || tmpget == null) {
        return true;
      } else {
        return false;
      }
    }),
    isShoppingCartEnabled: computed(function () {
      return this.get('features').isEnabled('shopping-cart');
    }),

    firstName: computed(function () {
      return this.get('user.firstName');
    }),

    lastName: computed(function () {
      return this.get('user.lastName');
    }),

    email: computed(function () {
      return this.get('user.email');
    }),

    phone: computed(function () {
      return this.get('user.phone');
    }),

    country: computed(function () {
      return this.get('user.country');
    }),

    state: computed(function () {
      return this.get('user.state');
    }),

    displayWholesalers: computed('wholesalers.[]', function () {
      var wholesalerObjects = this.get('wholesalers').map(function (wholesaler) {
        return { label: wholesaler.get('name'), value: wholesaler };
      });

      wholesalerObjects.push({ label: 'Other', value: null });

      return wholesalerObjects;
    }),

    wholesaler: computed(function () {
      return this.get('user.wholesaler.content');
    }),

    isNewZealand: computed.equal('country', 'New Zealand'),

    states: (0, _emberMacaroni.ifThenElseWithKeys)('isNewZealand', 'newZealandStates', 'australianStates'),

    hasSelectedWholesaler: computed.notEmpty('wholesaler'),

    isWholesalerAuthAllowed: computed('isShoppingCartEnabled', 'authorizableWholesalers.[]', 'wholesaler', function () {
      return this.get('isShoppingCartEnabled') && this.get('authorizableWholesalers').includes(this.get('wholesaler.name'));
    }),

    postcode: computed(function () {

      return this.get('user.postcode');
    }),

    RFN: computed(function () {
      return this.get('user.rfn');
    }),

    ABN: computed(function () {
      return this.get('user.abn');
    }),

    QBCC: computed(function () {
      return this.get('user.qbcc');
    }),

    Reportphone: computed(function () {
      return this.get('user.reportphone');
    }),

    imgurl: computed(function () {
      var tmp = this.get('user.imgurl');
      return tmp;
    }),

    showWholesalerAuthFields: computed.and('hasSelectedWholesaler', 'isWholesalerAuthAllowed', 'isEditing'),

    hasUsername: computed.notEmpty('username'),

    hasPassword: computed.notEmpty('password'),

    hasEnteredAuthDetails: computed.and('hasUsername', 'hasPassword'),

    shouldPerformWholesalerAuth: computed.and('isWholesalerAuthAllowed', 'hasEnteredAuthDetails'),

    // Observers

    countryChanged: observer('country', function () {
      this.set('state', '');
    }),

    hasShownWholesalerAuthFields: observer('showWholesalerAuthFields', function () {
      if (this.get('showWholesalerAuthFields')) {
        this.toggleLabelFocus(this.$('#user-form'));
      }
    }),

    // Methods

    updateUser: function updateUser() {
      var getfilname = this.get('filename');
      var filename = 'https://' + ENV.uploadmediabucket + '.s3.amazonaws.com/' + getfilname;
      filename = filename.replace(/\s+/g, '%20');

      var getfilnameSig = this.get('filenameSig');
      var filenamesig = 'https://cleversparkysignatures.s3.amazonaws.com/' + getfilnameSig;
      filenamesig = filenamesig.replace(/\s+/g, '%20');

      this.set('user.firstName', this.get('firstName'));
      this.set('user.lastName', this.get('lastName'));
      this.set('user.email', this.get('email'));
      this.set('user.phone', this.get('phone'));
      this.set('user.country', this.get('country'));
      this.set('user.state', this.get('state'));
      this.set('user.wholesaler', this.get('wholesaler'));
      this.set('user.postcode', this.get('postcode'));
      this.set('user.rfn', this.get('RFN'));
      this.set('user.abn', this.get('ABN'));
      this.set('user.qbcc', this.get('QBCC'));
      this.set('user.reportphone', this.get('Reportphone'));
      this.set('user.editpage', true);
      var filecheck = get(this, 'file');
      var filecheckSig = get(this, 'fileSig');
      if (filecheck != null) {
        this.set('user.imgurl', filename);
      }

      if (filecheckSig != null) {
        this.set('user.signatureurl', filenamesig);
        this.set('user.sigfilename', getfilnameSig);
      }
    },
    resetUser: function resetUser() {
      this.set('firstName', this.get('user.firstName'));
      this.set('lastName', this.get('user.lastName'));
      this.set('email', this.get('user.email'));
      this.set('phone', this.get('user.phone'));
      this.set('country', this.get('user.country'));
      this.set('state', this.get('user.state'));
      this.set('wholesaler', this.get('user.wholesaler.content'));
    },
    clearWholesalerAuthFields: function clearWholesalerAuthFields() {
      this.set('username', '');
      this.set('password', '');
    },

    signedRequest: service(),
    s3Upload: service(),

    uploadImage: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
        var fileName, testint, fileType, signedData;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                // eslint-disable-next-line ember-suave/no-const-outside-module-scope
                fileName = '' + get(this, 'file.name');
                testint = this.getRandomInt(1, 100000);

                this.set('filename', testint + fileName);
                // eslint-disable-next-line ember-suave/no-const-outside-module-scope
                fileType = get(this, 'file.type');
                // eslint-disable-next-line ember-suave/no-const-outside-module-scope

                _context.next = 6;
                return get(this, 'signedRequest').getUrl(testint + fileName, fileType);

              case 6:
                signedData = _context.sent;
                _context.next = 9;
                return get(this, 's3Upload').uploadFile(get(this, 'file'), signedData);

              case 9:
                set(this, 'url', signedData.url);
                _context.next = 12;
                return window.location.reload(true);

              case 12:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function uploadImage() {
        return _ref.apply(this, arguments);
      }

      return uploadImage;
    }(),
    uploadImageSig: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2() {
        var fileNamesig, testint, fileType, signedData;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                // eslint-disable-next-line ember-suave/no-const-outside-module-scope
                fileNamesig = '' + get(this, 'fileSig.name');
                testint = this.getRandomInt(1, 100000);


                this.set('filenameSig', testint + fileNamesig);
                // eslint-disable-next-line ember-suave/no-const-outside-module-scope
                fileType = get(this, 'fileSig.type');
                // eslint-disable-next-line ember-suave/no-const-outside-module-scope

                _context2.next = 6;
                return get(this, 'signedRequest').getUrl(testint + fileNamesig, fileType);

              case 6:
                signedData = _context2.sent;
                _context2.next = 9;
                return get(this, 's3Upload').uploadFile(get(this, 'fileSig'), signedData);

              case 9:
                set(this, 'url', signedData.url);
                _context2.next = 12;
                return window.location.reload(true);

              case 12:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function uploadImageSig() {
        return _ref2.apply(this, arguments);
      }

      return uploadImageSig;
    }(),
    uploadImageD: function () {
      var _ref3 = _asyncToGenerator(regeneratorRuntime.mark(function _callee3() {
        var fileName, fileType;
        return regeneratorRuntime.wrap(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                // eslint-disable-next-line ember-suave/no-const-outside-module-scope
                fileName = '' + get(this, 'file.name');


                this.set('filename', fileName);
                // eslint-disable-next-line ember-suave/no-const-outside-module-scope
                fileType = get(this, 'file.type');
                // eslint-disable-next-line ember-suave/no-const-outside-module-scope

                _context3.next = 5;
                return get(this, 'signedRequest').getUrl(fileName, fileType);

              case 5:
                _context3.next = 7;
                return window.location.reload(true);

              case 7:
              case 'end':
                return _context3.stop();
            }
          }
        }, _callee3, this);
      }));

      function uploadImageD() {
        return _ref3.apply(this, arguments);
      }

      return uploadImageD;
    }(),
    uploadImageSigD: function () {
      var _ref4 = _asyncToGenerator(regeneratorRuntime.mark(function _callee4() {
        var fileNamesig, fileType;
        return regeneratorRuntime.wrap(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                // eslint-disable-next-line ember-suave/no-const-outside-module-scope
                fileNamesig = '' + get(this, 'fileSig.name');


                this.set('filenameSig', fileNamesig);
                // eslint-disable-next-line ember-suave/no-const-outside-module-scope
                fileType = get(this, 'fileSig.type');
                // eslint-disable-next-line ember-suave/no-const-outside-module-scope

                _context4.next = 5;
                return get(this, 'signedRequest').getUrl(fileNamesig, fileType);

              case 5:
                _context4.next = 7;
                return window.location.reload(true);

              case 7:
              case 'end':
                return _context4.stop();
            }
          }
        }, _callee4, this);
      }));

      function uploadImageSigD() {
        return _ref4.apply(this, arguments);
      }

      return uploadImageSigD;
    }(),
    getRandomInt: function getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    init: function init() {
      this._super.apply(this, arguments);
    },


    actions: {
      fileLoaded: function fileLoaded(file) {
        this.set('imgupload', true);
        if (file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png') {
          set(this, 'file', file);
        } else {
          this.set('imginfo', ' logo image needs to be .jpg, .png, or .jpeg format');
        }
      },
      fileLoadedSig: function fileLoadedSig(file) {
        this.set('nosignaturelogox', false);

        this.set('imguploadSig', true);
        var filecopy = file;

        if (file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png') {
          set(this, 'fileSig', filecopy);
        } else {
          this.set('sigimginfo', 'Signature image needs to be .jpg, .png, or .jpeg format');
        }
      },
      edit: function edit() {
        this.set('isEditing', true);
      },
      cancel: function cancel() {
        this.resetUser();
        this.set('isEditing', false);
      },
      save: function save() {
        var _this = this;

        this.set('didValidate', true);

        if (this.get('validations.isValid')) {
          var filecheck = get(this, 'file');
          var filecheckSig = get(this, 'fileSig');

          if (typeof filecheck !== 'undefined') {
            get(this, 'uploadImage').bind(this)();
          }

          if (typeof filecheckSig !== 'undefined') {
            this.get('holdvaribles').setupCurrentPhotoUploadSignatureBool(true);
            get(this, 'uploadImageSig').bind(this)();
          }

          this.updateUser();
          if (this.get('shouldPerformWholesalerAuth')) {
            this.attrs.authenticateWholesaler(this.get('wholesaler'), this.get('username'), this.get('password')).then(function (wholesalerAuthDetails) {
              _this.set('user.wholesalerAuthDetails', wholesalerAuthDetails);
              _this.attrs.save(_this.get('user'));
              _this.clearWholesalerAuthFields();
            }).catch(function (reason) {
              _this.handleRailsError({}, reason.error_description);
            });
          } else {
            this.attrs.save(this.get('user'));
          }
          this.set('isQueryPending', true);
          //
        }
      },
      deletesig: function deletesig() {

        this.get('holdvaribles').setupCurrentPhotoUploadSignatureBool(true);
        this.get('holdvaribles').setupCurrentPhotoUploadDeleteBool(true);
        get(this, 'uploadImageSigD').bind(this)();
      },
      deletelogo: function deletelogo() {

        this.get('holdvaribles').setupCurrentPhotoUploadSignatureBool(false);
        this.get('holdvaribles').setupCurrentPhotoUploadDeleteBool(true);
        get(this, 'uploadImageD').bind(this)();
      }
    }
  });
});