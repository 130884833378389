define('clever-sparky-frontend/routes/new-organisation', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'clever-sparky-frontend/mixins/show-nav-bar', 'clever-sparky-frontend/mixins/handle-error', 'ember-cli-uuid'], function (exports, _ember, _authenticatedRouteMixin, _showNavBar, _handleError, _emberCliUuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _handleError.default, _showNavBar.default, {
    model: function model() {
      return RSVP.hash({
        user: this.modelFor('application').currentUser,
        organisation: this.store.createRecord('organisation', {
          id: (0, _emberCliUuid.uuid)()
        })
      });
    },
    afterModel: function afterModel(model) {
      model.organisation.user = model.user;
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('user', model.user);
      controller.set('organisation', model.organisation);
    },


    actions: {
      save: function save(organisation) {
        var _this = this;

        organisation.save().then(function (organisation) {
          _this.currentModel.user.get('organisations').pushObject(organisation);
          _this.send('setCurrentOrganisation', organisation.get('id'));
          _this.showSuccessFlash('Business Created');
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        }).finally(function () {
          _this.controller.set('isQueryPending', false);
        });
      }
    }
  });
});