define('clever-sparky-frontend/helpers/pluralizer', ['exports', 'ember', 'ember-inflector'], function (exports, _ember, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.pluralizer = pluralizer;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var pluralize = _emberInflector.default.pluralize;
  var Helper = _ember.default.Helper;
  function pluralizer(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        length = _ref2[0],
        value = _ref2[1];

    if (length > 1) {
      switch (value) {
        case 'this':
          return 'these';
        case 'has':
          return 'have';
        case 'its':
          return 'their';
        default:
          return pluralize(value);
      }
    } else if (length === null) {
      return pluralize(value);
    } else {
      return value;
    }
  }

  exports.default = Helper.helper(pluralizer);
});