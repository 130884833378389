define('clever-sparky-frontend/components/group-table', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend({

    groups: [],

    selectedGroups: [],

    isDeletingGroups: false,

    hasNoRecords: computed.equal('groups.length', 0),

    noGroupsSelected: computed.equal('selectedGroups.length', 0),

    actions: {
      toggleDeleteGroups: function toggleDeleteGroups() {
        this.toggleProperty('isDeletingGroups');
        this.set('selectedGroups', []);
      },
      deleteGroups: function deleteGroups(selectedGroups) {
        this.attrs.batchDeleteGroups(selectedGroups);
      },
      showGroupDetails: function showGroupDetails(group) {
        this.sendAction('showGroupDetails', group);
      }
    }

  });
});