define('clever-sparky-frontend/components/forms/report-form', ['exports', 'ember', 'clever-sparky-frontend/validators/forms/report-form'], function (exports, _ember, _reportForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Component = _ember.default.Component,
      computed = _ember.default.computed,
      inject = _ember.default.inject,
      observer = _ember.default.observer;
  var service = _ember.default.inject.service;
  exports.default = Component.extend(_reportForm.ReportValidations, {
    session: inject.service('session'),
    store: inject.service(),
    holdvaribles: inject.service('holdvaribles'),
    reportCategory: 'device-details',

    reportFormat: 'pdf',

    reportContent: 'groups',

    endstatement: 'To edit compliance statement, go to menu > update business> compliance statement',

    selctOnDisabled: false,
    sendreporttxt: 'Send Report',
    isTestreport: false,
    isCstatementEmpty: false,

    displayDeleteModal: false,

    newtempname: null,

    newbuttonpushed: false,

    selctOffDisabled: false,

    cstateContent: 'cstatebool',

    currentEmployment: computed.alias('model.currentEmployment'),

    currentUser: computed('session.data', function () {
      return this.get('session.data.authenticated.currentUser');
    }),
    currentSite: null,

    isSelectingTestEvents: computed.equal('reportContent', 'test-events'),

    isSelectingGroups: computed.equal('reportContent', 'groups'),

    selectedGroups: [],

    selectedTestEvent: null,
    selectedTemplate: null,

    selectedTemplatex: observer('selectedTemplate', function () {
      var re = this.selectedTemplate;
      this.set('cstatement', re.__data.fulltext);
    }),

    didValidate: false,

    cstatement: computed(function () {

      var text = null;

      if (text === '' || text == null) {
        this.set('isCstatementEmpty', true);
        text = 'This Emergency lighting duration test report has been collected as per the record keeping requirements of AS/NZS2293.2 sections 2.7.2 & 2.7.3.\n' + 'The information contained in this report demonstrates compliance with the 6 monthly inspection and record keeping tasks listed in AS/NZS2293.2- Appendix A- Table A2- Schedule 2- Parts 2.1, 2.7, 2.8 and 2.10.';
      }

      return text;
    }),

    textchangedcstatement: false,
    textchangedcstatementtmp: observer('cstatement', function () {
      var newbuttonpushed = this.get('newbuttonpushed');
      if (newbuttonpushed !== true) {
        this.set('textchangedcstatement', true);
      }
    }),

    cstatebool: false,

    isTestEventsEnabled: computed(function () {
      return this.get('features').isEnabled('test-events');
    }),
    testEventOptions: null,
    AlltestEventOptions: null,
    NamesofTemplatesOptions: null,
    testEventOptionstmp: computed.map('AlltestEventOptions', function (testEvent) {
      // eslint-disable-next-line ember-suave/prefer-destructuring
      var startDate = testEvent.__data.startDate;
      // eslint-disable-next-line ember-suave/prefer-destructuring
      var endDate = testEvent.__data.endDate;
      return { label: startDate + ' to ' + endDate, value: testEvent };
    }),
    templateOptionstmp: computed.map('NamesofTemplatesOptions', function (template) {
      // eslint-disable-next-line ember-suave/prefer-destructuring
      var name = template.__data.name;

      return { label: '' + name, value: template };
    }),
    ajaxRequests: service(),
    isQueryPending: false,
    init: function init() {
      this._super.apply(this, arguments);
      var site = this.get('holdvaribles').showCurrentSite();
      this.set('currentSite', site.data);
      if (this.get('session') != null) {

        this.get('store').query('template', {
          // eslint-disable-next-line camelcase
          user_id: '1'
        }).then(function () {});

        this.set('cstateContent', 'cstatebool');
        this.set('cstatebool', 'true');
        var tevent = this.get('holdvaribles').showAlltestevents();

        var result = tevent.content.content.filter(function (item) {
          if (item.__data !== null) {
            return true;
          } else {
            return false;
          }
        });
        this.set('AlltestEventOptions', result);

        var test = this.get('testEventOptionstmp');
        this.set('testEventOptions', test);
      }
    },


    actions: {
      toggleDeleteModal: function toggleDeleteModal() {
        var selected = this.get('selectedTemplate');
        if (selected !== null) {
          this.set('displayDeleteModal', true);
        }
      },
      closeDeleteModal: function closeDeleteModal() {
        var temps = this.get('store').peekAll('template');
        var result = temps.content.filter(function (item) {
          if (item.__data !== null) {
            return true;
          } else {
            return false;
          }
        });

        this.set('NamesofTemplatesOptions', result);
        var test = this.get('templateOptionstmp');
        this.set('templateOptions', test);
        this.set('displayDeleteModal', false);
        this.set('cstatement', '');
      },
      newButton: function newButton() {
        this.set('newbuttonpushed', true);
        this.set('textchangedcstatement', false);
        this.set('cstatement', 'This Emergency lighting duration test report has been collected as per the record keeping requirements of AS/NZS2293.2 sections 2.7.2 & 2.7.3.\n' + 'The information contained in this report demonstrates compliance with the 6 monthly inspection and record keeping tasks listed in AS/NZS2293.2- Appendix A- Table A2- Schedule 2- Parts 2.1, 2.7, 2.8 and 2.10.');
      },
      tamplateSave: function () {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
          var selected, cstate, newbuttonpushed, temp, temps, result, test;
          return regeneratorRuntime.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  this.set('textchangedcstatement', false);
                  selected = this.get('selectedTemplate');
                  cstate = this.get('cstatement');
                  newbuttonpushed = this.get('newbuttonpushed');

                  if (!(newbuttonpushed === true)) {
                    _context.next = 17;
                    break;
                  }

                  temp = this.get('store').createRecord('template', {
                    name: this.get('newtempname'),
                    fulltext: this.get('cstatement')
                  });
                  _context.next = 8;
                  return temp.save();

                case 8:
                  this.set('newbuttonpushed', false);

                  temps = this.get('store').peekAll('template');
                  result = temps.content.filter(function (item) {
                    if (item.__data !== null) {
                      return true;
                    } else {
                      return false;
                    }
                  });


                  this.set('NamesofTemplatesOptions', result);
                  test = this.get('templateOptionstmp');

                  this.set('templateOptions', test);
                  this.set('cstatement', '');
                  _context.next = 18;
                  break;

                case 17:

                  this.get('store').findRecord('template', selected.id).then(function (temp) {
                    // ...after the record has loaded
                    temp.set('fulltext', cstate);
                    temp.save();
                  });

                case 18:
                case 'end':
                  return _context.stop();
              }
            }
          }, _callee, this);
        }));

        function tamplateSave() {
          return _ref.apply(this, arguments);
        }

        return tamplateSave;
      }(),
      cstateContentChanged: function cstateContentChanged(cstateContent) {
        if (cstateContent === 'cstatebool') {
          this.set('cstateContent', '');
          this.set('cstateContent', 'cstatebool');
          this.set('cstatebool', 'true');
        } else {
          this.set('cstateContent', null);
          this.set('cstateContent', 'cstatebooloff');
          this.set('cstatebool', 'false');
        }
      },
      reportContentChanged: function reportContentChanged(reportContent) {
        this.set('didValidate', false);
        if (reportContent === 'groups') {
          this.set('selectedGroups', []);
        } else {

          if (this.get('reportCategory') === 'faulty-fittings') {
            this.set('reportCategory', 'device-details');
          }
        }
      },
      typereportChanged: function typereportChanged(typeContent) {
        if (typeContent === 'test-results') {
          this.set('isTestreport', true);
          var temps = this.get('store').peekAll('template');
          var result = temps.content.filter(function (item) {
            if (item.__data !== null) {
              return true;
            } else {
              return false;
            }
          });

          this.set('NamesofTemplatesOptions', result);
          var test = this.get('templateOptionstmp');
          this.set('templateOptions', test);
        } else {
          this.set('isTestreport', false);
          this.set('cstatebool', 'false');
        }
      },
      inputActionEventTrigger: function inputActionEventTrigger() {
        this.set('sendreporttxt', 'Finish Editing And Send Report');
      },
      downloadReport: function downloadReport() {
        this.set('didValidate', true);
        if (this.get('validations.isValid')) {
          var selectedItems = this.get('reportContent') === 'groups' ? this.get('selectedGroups') : this.get('selectedTestEvent');
          this.set('isQueryPending', true);
          var cat = this.get('reportCategory');
          if (cat == 'device-details') {
            this.attrs.downloadReport(this.get('reportContent'), selectedItems, this.get('reportCategory'), this.get('reportFormat'), 'false', this.get('cstatement'));
          } else {
            this.attrs.downloadReport(this.get('reportContent'), selectedItems, this.get('reportCategory'), this.get('reportFormat'), this.get('cstatebool'), this.get('cstatement'));
          }
        }
      }
    }
  });
});