define('clever-sparky-frontend/routes/site/deleted-fittings/show/test-and-status', ['exports', 'clever-sparky-frontend/routes/base-routes/deleted-fittings-tab'], function (exports, _deletedFittingsTab) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _deletedFittingsTab.default.extend({
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      // overriding setupController function of BaseFaultyFittingsTabRoute
      controller.set('latestTest', model.latestTest);
      controller.set('newTest', model.newTest);
    }
  });
});