define('clever-sparky-frontend/controllers/site/fittings', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      computed = _ember.default.computed;
  exports.default = Controller.extend({
    // Attributes
    sortProperties: ['deviceReference'],
    sortAscending: false,
    queryParams: ['commissioned'],

    commissioned: false,

    selectedFittings: [],

    isDeletingFittings: false,

    isMovingFittings: false,

    displayBatchConfirmationModal: false,

    displayAddFittingModal: false,

    // Properties

    group: computed.alias('model.group'),

    site: computed.alias('model.site'),

    fittings: computed.alias('model.fittings'),

    fitting: computed.alias('model.fitting'),

    sitesGroups: computed.reads('model.sitesGroups'),

    batchLength: computed.alias('selectedFittings.length'),

    actions: {
      batchDeleteFittings: function batchDeleteFittings() {
        this.set('displayBatchConfirmationModal', true);
      },
      batchMoveFittings: function batchMoveFittings() {
        this.set('displayBatchConfirmationModal', true);
      },
      closeBatchConfirmationModal: function closeBatchConfirmationModal() {
        this.set('displayBatchConfirmationModal', false);
        this.set('selectedFittings', []);
        this.set('isMovingFittings', false);
        this.set('isDeletingFittings', false);
      },
      toggleAddFitting: function toggleAddFitting() {
        this.toggleProperty('displayAddFittingModal', true);
      },
      SortList: function SortList() {
        this.toggleProperty('displayAddFittingModal', true);
      },
      setIsCommissionedFilter: function setIsCommissionedFilter() {
        if (!this.get('commissioned')) {
          this.set('commissioned', true);
        }
      },
      setIsNotCommissionedFilter: function setIsNotCommissionedFilter() {
        if (this.get('commissioned')) {
          this.set('commissioned', false);
        }
      },
      addOrderItem: function addOrderItem(fitting, partType) {
        this.send('createOrderItem', fitting, partType);
      },
      removeOrderItem: function removeOrderItem(fitting, partType) {
        this.send('deleteOrderItem', fitting, partType);
      },
      backToFittingsIndex: function backToFittingsIndex() {
        this.transitionToRoute('groups.fittings');
      }
    }

  });
});