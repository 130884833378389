define('clever-sparky-frontend/adapters/order-item', ['exports', 'ember', 'clever-sparky-frontend/adapters/application'], function (exports, _ember, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ENV = _ember.default.ENV;
  exports.default = _application.default.extend({
    urlForQuery: function urlForQuery(query) {
      var url = '';
      if (query.order) {
        url = ENV.api.host + '/' + ENV.api.namespace + '/orders/' + query.order + '/order-items';
      }
      return url;
    }
  });
});