define('clever-sparky-frontend/routes/groups/fittings/show/test-and-status', ['exports', 'ember', 'clever-sparky-frontend/routes/base-routes/groups-fittings-tab', 'clever-sparky-frontend/mixins/handle-error'], function (exports, _ember, _groupsFittingsTab, _handleError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed;
  exports.default = _groupsFittingsTab.default.extend(_handleError.default, {
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      // overriding setupController function of BaseGroupsFittingsTabRoute
      controller.set('latestTest', model.latestTest);
      controller.set('newTest', model.newTest);
      controller.set('canEdit', false);
      this.controllerFor('groups.fittings.show').set('canEdit', false);
      if (!model.latestTest) {
        this.controllerFor('groups.fittings.show').set('showEdit', false);
      }
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('canEdit', false);
        this.controllerFor('groups.fittings.show').set('showEdit', computed.not('canEdit'));
      }
    },


    actions: {
      save: function save(fitting, test) {
        var _this = this;

        if (test.get('isNew')) {
          test.set('fitting', fitting);
        }
        test.save().then(function () {
          _this.controllerFor('groups.fittings.show').set('showSuccess', true);
          _this.controllerFor('groups.fittings.show').set('canEdit', false);
          _this.controller.set('canEdit', false);
          _this.controllerFor('groups.fittings.show').set('showEdit', computed.not('canEdit'));
          fitting.save();
          // refresh the top most parent route
          _this.send('refreshRoute');
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        }).finally(function () {
          _this.controller.set('isQueryPending', false);
        });
      },
      deleteTest: function deleteTest(test) {
        var _this2 = this;

        test.destroyRecord().then(function () {
          _this2.controllerFor('groups.fittings.show').set('canEdit', false);
          _this2.controller.set('canEdit', false);
          _this2.controllerFor('groups.fittings.show').set('showEdit', computed.not('canEdit'));
          // refresh the top most parent route
          _this2.send('refreshRoute');
        }).catch(function (reason) {
          _this2.handleRailsError(reason);
        }).finally(function () {
          _this2.controller.set('isQueryPending', false);
        });
      }
    }

  });
});