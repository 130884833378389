define('clever-sparky-frontend/components/validated-textarea', ['exports', 'ember', 'clever-sparky-frontend/components/validated-input'], function (exports, _ember, _validatedInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed;
  exports.default = _validatedInput.default.extend({
    classNames: ['validated-textarea'],

    allowNullValue: false,

    hasContent: computed('value', function () {
      var value = this.get('value');
      return this.get('allowNullValue') ? !!value || value === null : !!value;
    })
  });
});