define('clever-sparky-frontend/routes/site-requests', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'clever-sparky-frontend/mixins/show-nav-bar', 'clever-sparky-frontend/mixins/handle-error', 'ember-infinity/mixins/route', 'ember-local-storage', 'ember-can'], function (exports, _ember, _authenticatedRouteMixin, _showNavBar, _handleError, _route, _emberLocalStorage, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _route.default, _showNavBar.default, _handleError.default, _emberCan.CanMixin, {

    currentOrganisation: (0, _emberLocalStorage.storageFor)('current-organisation'),

    perPageParam: 'page[size]', // instead of 'per_page'

    pageParam: 'page[number]', // instead of 'page'

    totalPagesParam: 'meta.total-pages', // instead of "meta.total_pages"

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      var _modelFor = this.modelFor('application'),
          currentEmployment = _modelFor.currentEmployment;

      if (!this.can('view user-management', currentEmployment)) {
        this.replaceWith('sites');
      }
    },
    model: function model() {
      var _modelFor2 = this.modelFor('application'),
          currentOrganisation = _modelFor2.currentOrganisation;

      var currentOrganisationId = currentOrganisation ? currentOrganisation.get('id') : null;

      return RSVP.hash({
        siteRequests: this.infinityModel('access-request', {
          perPage: 10,
          startingPage: 1,
          modelPath: 'controller.model.siteRequests',
          currentOrganisationId: currentOrganisationId
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('siteRequests', model.siteRequests);
      controller.set('currentUser', model.user);
      controller.set('isQueryPending', false);
    },


    actions: {
      approve: function approve(accessRequest) {
        var _this = this;

        accessRequest.approve().then(function () {
          _this.showSuccessFlash('Site Access Request Approved');
          _this.refresh();
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        }).finally(function () {
          _this.controller.set('isQueryPending', false);
        });
      },
      decline: function decline(accessRequest) {
        var _this2 = this;

        accessRequest.destroyRecord().then(function () {
          _this2.showSuccessFlash('Site Access Request Declined');
          _this2.refresh();
        }).catch(function (reason) {
          _this2.handleRailsError(reason);
        }).finally(function () {
          _this2.controller.set('isQueryPending', false);
        });
      }
    }

  });
});