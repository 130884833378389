define('clever-sparky-frontend/mixins/option-lookup', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = _ember.default.Mixin;
  exports.default = Mixin.create({

    testStatusOptions: [{ label: 'Valid Pass', value: 'valid_pass' }, { label: 'Test Active', value: 'test_active' }, { label: 'Failed Unknown', value: 'failed_unknown' }, { label: 'Failed Battery', value: 'failed_battery' }, { label: 'Failed Lamp', value: 'failed_lamp' }, { label: 'Failed Control', value: 'failed_control' }, { label: 'Failed No Output', value: 'failed_no_output' }],

    passTestStatusOptions: [{ label: 'Valid Pass', value: 'valid_pass' }],

    failTestStatusOptions: [{ label: 'Failed Unknown', value: 'failed_unknown' }, { label: 'Failed Battery', value: 'failed_battery' }, { label: 'Failed Lamp', value: 'failed_lamp' }, { label: 'Failed Control', value: 'failed_control' }, { label: 'Failed No Output', value: 'failed_no_output' }],

    durationOptions: [{ label: '30', value: 30 }, { label: '90', value: 90 }, { label: '120', value: 120 }],

    viewOptions: [{ label: 'All Fittings', value: 'allfittings' }, { label: 'Groups', value: 'groups' }],

    statusOptions: [{ label: 'OK', value: 'ok' }, { label: 'Faulty', value: 'faulty' }],

    repairOptions: [{ label: 'Replaced battery', value: 'battery' }, { label: 'Replaced fitting', value: 'fitting' }, { label: 'Replaced control gear', value: 'control_gear' }, { label: 'Replaced lamp', value: 'lamp' }, { label: 'Other', value: 'other' }],

    partTypeOptions: [{ label: 'Fitting', value: 'fitting' }, { label: 'Battery', value: 'battery' }, { label: 'Lamp', value: 'lamp' }, { label: 'Control Gear', value: 'control_gear' }, { label: 'Decal', value: 'decal' }],

    userTypeOptions: [{
      label: 'Owner',
      value: 'owner'
    }, {
      label: 'Admin',
      value: 'admin'
    }, {
      label: 'Technician',
      value: 'technician'
    }]

  });
});