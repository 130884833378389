define('clever-sparky-frontend/components/auth-validated-select', ['exports', 'clever-sparky-frontend/components/validated-select'], function (exports, _validatedSelect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _validatedSelect.default.extend({

    classNames: ['auth-validated-select'],

    layoutName: 'components/validated-select',

    focusOut: function focusOut() {
      this._super.apply(this, arguments);
      this.set('showValidations', true);
    }
  });
});