define('clever-sparky-frontend/adapters/invite', ['exports', 'clever-sparky-frontend/adapters/application', 'ember'], function (exports, _application, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ENV = _ember.default.ENV;
  exports.default = _application.default.extend({
    urlForFindRecord: function urlForFindRecord() {
      return this._super.apply(this, arguments) + '?include=organisation';
    },
    urlForQuery: function urlForQuery(query) {
      // eslint-disable-next-line quotes
      return ENV.api.host + '/' + ENV.api.namespace + '/invitetransfer/' + query;
    }
  });
});