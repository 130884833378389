define('clever-sparky-frontend/models/user', ['exports', 'ember', 'ember-data', 'clever-sparky-frontend/models/site-owner', 'ember-api-actions'], function (exports, _ember, _emberData, _siteOwner, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = _siteOwner.default.extend({

    // Relationships

    organisations: hasMany('organisation'),

    wholesaler: belongsTo('wholesaler'),

    sites: hasMany('site', { inverse: 'siteOwner' }),

    // Attributes

    firstName: attr('string'),

    lastName: attr('string'),

    email: attr('string'),

    phone: attr('string'),

    country: attr('string'),

    state: attr('string'),

    password: attr('string'),

    currentPassword: attr('string'),

    passwordConfirmation: attr('string'),

    wholesalerAuthDetails: attr(),

    wholesalerAuthenticated: attr('boolean'),

    admin: attr('boolean'),

    postcode: attr('string'),

    rfn: attr('string'),

    abn: attr('string'),

    qbcc: attr('string'),

    reportphone: attr('string'),

    imgurl: attr('string'),

    editpage: attr('boolean'),

    signatureurl: attr('string'),

    sigfilename: attr('string'),

    // eslint-disable-next-line camelcase
    wholesaler_local_branch: attr('string'),

    cstatement: attr('string'),

    // Properties

    fullName: computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    }),

    truncatedName: computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName')[0];
    }),

    // Actions

    grantAccess: (0, _emberApiActions.memberAction)({
      path: 'relationships/permitted-sites',
      type: 'post'
    }),

    clearPassword: function clearPassword() {
      this.set('password', null);
      this.set('passwordConfirmation', null);
      this.set('currentPassword', null);
    }
  });
});