define('clever-sparky-frontend/controllers/site/details', ['exports', 'ember', 'clever-sparky-frontend/mixins/handle-error'], function (exports, _ember, _handleError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      computed = _ember.default.computed;
  exports.default = Controller.extend(_handleError.default, {

    site: computed.alias('model.site'),

    currentUser: computed.alias('model.currentUser'),

    currentOrganisation: computed.alias('model.currentOrganisation'),

    currentEmployment: computed.alias('model.currentEmployment'),

    canEdit: false,

    displayDeleteConfirmationModal: false,

    displayTransferSitesModal: false,

    displayTransferSitesToUserModal: false,

    formattedOrganisations: computed.map('model.currentUsersAdminOrganisations', function (item) {
      return { label: item.get('name'), value: item.get('id') };
    }),

    availableOrganisations: computed.filter('formattedOrganisations', function (organisation) {
      return organisation.value !== this.get('currentOrganisation.id');
    }),

    displayOrganisations: computed('currentUser', 'currentOrganisation', function () {
      var displayOrgs = this.get('availableOrganisations');

      if (this.get('currentOrganisation')) {
        displayOrgs.unshift({ label: this.get('currentUser.fullName'), value: null });
      }

      return displayOrgs;
    }),

    hasOrganisationsToTransferTo: computed.gt('displayOrganisations.length', 0),

    actions: {
      save: function save(site) {
        var _this = this;

        site.save().then(function () {
          _this.showSuccessFlash('Site Updated');
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        }).finally(function () {
          _this.set('isQueryPending', false);
        });
      },
      delete: function _delete() {
        this.set('displayDeleteConfirmationModal', true);
      },
      openTransferSiteModal: function openTransferSiteModal() {
        this.set('displayTransferSitesModal', true);
      },
      closeTransferSiteModal: function closeTransferSiteModal() {
        this.set('displayTransferSitesModal', false);
      },
      openTransferSiteToUserModal: function openTransferSiteToUserModal() {
        this.set('displayTransferSitesToUserModal', true);
      },
      closeTransferSiteToUserModal: function closeTransferSiteToUserModal() {
        this.set('displayTransferSitesToUserModal', false);
      },
      edit: function edit() {
        this.set('canEdit', true);
      },
      confirmDeleteSite: function confirmDeleteSite(site) {
        var _this2 = this;

        site.destroyRecord().then(function () {
          _this2.set('displayDeleteConfirmationModal', false);
          _this2.transitionToRoute('sites');
          _this2.showSuccessFlash('Site Deleted');
        }).catch(function (reason) {
          _this2.handleRailsError(reason);
        });
      },
      cancel: function cancel() {
        this.set('displayDeleteConfirmationModal', false);
      }
    }

  });
});