define('clever-sparky-frontend/components/test-event-group-table', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend({

    testEventGroups: [],

    hasNoRecords: computed.equal('testEventGroups.length', 0)

  });
});