define('clever-sparky-frontend/routes/site/details', ['exports', 'ember', 'clever-sparky-frontend/mixins/handle-error'], function (exports, _ember, _handleError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP;
  exports.default = Route.extend(_handleError.default, {
    model: function model() {
      var _this = this;

      var _modelFor = this.modelFor('application'),
          currentUser = _modelFor.currentUser,
          currentOrganisation = _modelFor.currentOrganisation,
          currentEmployment = _modelFor.currentEmployment;

      var _modelFor2 = this.modelFor('site'),
          site = _modelFor2.site;

      return new RSVP.Promise(function (resolve) {
        RSVP.hash({
          site: site,
          currentUser: currentUser,
          currentOrganisation: currentOrganisation,
          currentEmployment: currentEmployment,
          currentUsersAdminOrganisations: _this.store.query('organisation', {
            filter: {
              role: 'owner,admin'
            }
          })
        }).then(function (model) {
          resolve(model);
        });
      });
    },
    deactivate: function deactivate() {
      this.controller.set('canEdit', false);
    },


    actions: {
      transferSite: function transferSite(site, newOwner) {
        var _this2 = this;

        var type = newOwner ? 'organisations' : 'users';
        var newOwnerId = newOwner ? newOwner : this.currentModel.currentUser.get('id');

        site.transferOwnership({
          data: { type: type, id: newOwnerId }
        }).then(function () {
          _this2.controller.send('closeTransferSiteModal');
          _this2.showSuccessFlash('Site Transferred');
          _this2.transitionTo('sites');
        }).catch(function (reason) {
          _this2.handleRailsError(reason);
        });
      },
      transferSiteOwner: function transferSiteOwner(site, newOwner, emailaddress) {
        var _this3 = this;

        var type = newOwner ? 'organisations' : 'users';
        var newOwnerId = newOwner ? newOwner : this.currentModel.currentUser.get('id');

        site.transferOwnership({
          data: { type: type, id: newOwnerId, newowner: emailaddress }
        }).then(function () {
          _this3.controller.send('closeTransferSiteModal');
          _this3.showSuccessFlash('Site Transfer Email Sent');
          // this.transitionTo('sites');
        }).catch(function (reason) {
          _this3.handleRailsError(reason);
        });
      }
    }

  });
});