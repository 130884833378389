define('clever-sparky-frontend/routes/signup/terms', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route;
  exports.default = Route.extend({
    model: function model() {
      return this.modelFor('signup');
    },
    afterModel: function afterModel() {
      this.controllerFor('signup').set('isTermsRoute', true);
    },
    deactivate: function deactivate() {
      this.controllerFor('signup').set('isTermsRoute', false);
    },


    actions: {
      accept: function accept() {
        this.transitionTo('signup.user');
      }
    }
  });
});