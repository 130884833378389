define('clever-sparky-frontend/validators/login-validator', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.LoginValidations = undefined;
  var LoginValidations = exports.LoginValidations = (0, _emberCpValidations.buildValidations)({
    password: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please enter your password',
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('length', {
      min: 8,
      message: 'Password must be at least 8 characters long'
    })],
    email: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please enter your email address',
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email',
      message: 'Please enter a valid email address'
    })]
  });

  exports.default = { LoginValidations: LoginValidations };
});