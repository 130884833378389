define('clever-sparky-frontend/components/forms/group-form', ['exports', 'ember', 'clever-sparky-frontend/mixins/toggle-label-focus', 'clever-sparky-frontend/validators/group-validator'], function (exports, _ember, _toggleLabelFocus, _groupValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend(_toggleLabelFocus.default, _groupValidator.GroupValidations, {
    didInsertElement: function didInsertElement() {
      this.toggleLabelFocus(this.$('#group-form'));
    },


    didValidate: false,

    isQueryPending: false,

    name: computed(function () {
      return this.get('group.name');
    }),

    updateGroup: function updateGroup() {
      this.set('group.name', this.get('name'));
    },
    resetGroup: function resetGroup() {
      this.set('name', this.get('group.name'));
    },


    actions: {
      cancel: function cancel() {
        this.resetGroup();
      },
      save: function save(group) {
        this.set('didValidate', true);

        if (this.get('validations.isValid')) {
          this.updateGroup();
          this.set('isQueryPending', true);
          this.attrs.save(group);
        }
      }
    }

  });
});