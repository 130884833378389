define('clever-sparky-frontend/models/organisation', ['exports', 'ember-data', 'clever-sparky-frontend/models/site-owner', 'ember-api-actions'], function (exports, _emberData, _siteOwner, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;
  exports.default = _siteOwner.default.extend({
    // Relationships
    employments: hasMany('employments'),

    sites: hasMany('site', { inverse: 'siteOwner' }),

    wholesaler: belongsTo('wholesaler'),

    // Attributes
    name: attr('string'),

    country: attr('string'),

    state: attr('string'),

    wholesalerAuthDetails: attr(),

    wholesalerAuthenticated: attr('boolean'),

    cstatement: attr('string'),

    // Actions
    copySitePermissions: (0, _emberApiActions.memberAction)({
      path: 'site-permissions/copy',
      type: 'post'
    })
  });
});