define('clever-sparky-frontend/routes/site/shopping-cart', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-infinity/mixins/route', 'clever-sparky-frontend/mixins/handle-error'], function (exports, _ember, _authenticatedRouteMixin, _route, _handleError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP,
      inject = _ember.default.inject;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _handleError.default, _route.default, {

    // Services
    batchDeleteOrderItems: inject.service('batchDeleteOrderItems'),

    cartCheckout: inject.service(),

    // Attributes
    perPageParam: 'page[size]', // instead of 'per_page'

    pageParam: 'page[number]', // instead of 'page'

    totalPagesParam: 'meta.total-pages', // instead of "meta.total_pages"

    model: function model() {
      var _modelFor = this.modelFor('site'),
          site = _modelFor.site;

      var _modelFor2 = this.modelFor('application'),
          currentUser = _modelFor2.currentUser,
          currentOrganisation = _modelFor2.currentOrganisation,
          currentEmployment = _modelFor2.currentEmployment;

      return RSVP.hash({
        site: site,
        currentUser: currentUser,
        currentOrganisation: currentOrganisation,
        currentEmployment: currentEmployment,
        order: site.get('currentOrder'),
        currentOrderGroups: site.get('currentOrder.id') ? this.infinityModel('group', {
          order: site.get('currentOrder.id'),
          perPage: 10,
          startingPage: 1,
          modelPath: 'controller.model.currentOrderGroups'
        }) : []
      });
    },


    actions: {
      confirmCheckout: function confirmCheckout(selectedGroups) {
        var _this = this;

        this.get('cartCheckout').checkout(this.currentModel.order, selectedGroups).then(function (result) {
          window.open(result.links.cart, '_blank');
          _this.controller.send('closeCheckoutConfirmationModal');
          // refresh the top most parent route
          _this.send('refreshRoute');
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        }).finally(function () {
          _this.controller.set('isQueryPending', false);
        });
      }
    }

  });
});