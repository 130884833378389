define('clever-sparky-frontend/components/batch-move-confirmation', ['exports', 'ember', 'clever-sparky-frontend/validators/forms/batch-move-confirmation'], function (exports, _ember, _batchMoveConfirmation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend(_batchMoveConfirmation.BatchMoveValidations, {

    classNames: ['batch-move-confirmation'],

    selectedGroup: null,

    didValidate: false,

    isQueryPending: false,

    otherGroups: computed.filter('groups', function (group) {
      return group != this.get('group');
    }),

    groupOptions: computed.map('otherGroups', function (group) {
      return { label: group.get('name'), value: group };
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('batchLength', this.get('batch.length'));
    },


    batchLength: null,

    actions: {
      confirmBatchMove: function confirmBatchMove(batch) {
        this.set('didValidate', true);
        if (this.get('validations.isValid')) {
          this.set('isQueryPending', true);
          this.attrs.confirmBatchMove(batch, this.get('selectedGroup.id'));
        }
      }
    }
  });
});