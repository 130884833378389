define('clever-sparky-frontend/routes/change-password/index', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP;
  exports.default = Route.extend({
    model: function model() {
      return RSVP.hash({
        user: this.modelFor('application').currentUser
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('user', model.user);
    }
  });
});