define('clever-sparky-frontend/services/tours/post-onboarding', ['exports', 'ember', 'clever-sparky-frontend/services/tours/base'], function (exports, _ember, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed,
      inject = _ember.default.inject;
  exports.default = _base.default.extend({

    // Services
    features: inject.service(),

    // Attributes

    namePrefix: 'post-onboarding',

    // properties

    isAnalyticsEnabled: computed(function () {
      return this.get('features').isEnabled('analytics');
    }),

    // sequence elements

    sequenceState: computed('isAnalyticsEnabled', function () {
      var state = [{
        name: 'sites-table',
        hasRendered: false
      }, {
        name: 'new-site-action',
        hasRendered: false
      }, {
        name: 'users-nav-item',
        hasRendered: false
      }];
      if (this.get('isAnalyticsEnabled')) {
        state.pushObject({
          name: 'analytics-nav-item',
          hasRendered: false
        });
      }

      return state;
    }),

    sequenceElements: computed('isAnalyticsEnabled', function () {
      var elements = [{
        name: 'sites-table',
        content: [this.get('i18n').t('tour.post-onboarding.sites-table')]
      }, {
        name: 'new-site-action',
        content: [this.get('i18n').t('tour.post-onboarding.new-site-action')]
      }, {
        name: 'users-nav-item',
        content: [this.get('i18n').t('tour.post-onboarding.users-nav-item')]
      }];
      if (this.get('isAnalyticsEnabled')) {
        elements.pushObject({
          name: 'analytics-nav-item',
          content: [this.get('i18n').t('tour.post-onboarding.analytics-nav-item')]
        });
      }

      return elements;
    })

  });
});