define('clever-sparky-frontend/components/form-validated-input', ['exports', 'ember', 'clever-sparky-frontend/components/validated-input'], function (exports, _ember, _validatedInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed;
  exports.default = _validatedInput.default.extend({

    classNames: ['form-validated-input'],

    disabled: false,

    isTextArea: computed.equal('type', 'textarea'),

    actions: {
      onFocusOut: function onFocusOut() {
        if (this.attrs.onFocusOut) {
          this.attrs.onFocusOut();
        }
      }
    }

  });
});