define('clever-sparky-frontend/validators/group-validator', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.GroupValidations = undefined;
  var GroupValidations = exports.GroupValidations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please enter a group name',
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 30,
      message: 'Group name is too long. Please reduce to 30 characters including spaces.'
    })]
  });

  exports.default = { GroupValidations: GroupValidations };
});