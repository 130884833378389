define('clever-sparky-frontend/services/request-site-access', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = _ember.default.Service,
      inject = _ember.default.inject,
      ENV = _ember.default.ENV;
  exports.default = Service.extend({
    requestService: inject.service('request-service'),

    _requestAccessMethod: 'POST',

    _requestAccessURL: function _requestAccessURL() {
      return ENV.api.host + '/' + ENV.api.namespace + '/access-requests';
    },
    requestAccess: function requestAccess(site) {
      var url = this._requestAccessURL();
      var method = this.get('_requestAccessMethod');
      var data = {
        data: {
          type: 'access-requests',
          relationships: {
            site: {
              data: {
                type: 'sites',
                id: site.id
              }
            }
          }
        }
      };

      return this.get('requestService').sendAuthenticatedRequest(url, method, data);
    }
  });
});