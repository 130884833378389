define('clever-sparky-frontend/components/site-table', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed,
      inject = _ember.default.inject;
  exports.default = Component.extend({
    store: inject.service(),

    organisation: computed.alias('model.organisation'),

    hasNoRecords: computed.equal('sites.length', 0),

    isQueryPending: false,

    postOnboardingTourNames: [],

    nexttestdates: [],

    nexttextDatetime: computed('sites.@each', function () {
      return true;
    }),

    viewRestrictedSitesTourNames: ['viewRestrictedSites'],

    actions: {
      showSite: function showSite(site) {
        this.sendAction('showSiteDetails', site);
      },
      requestAccess: function requestAccess(site) {
        this.set('isQueryPending', true);
        this.attrs.requestSiteAccess(site);
      },
      toggleSort: function toggleSort(column) {
        this.attrs.sort(column);
      }
    }

  });
});