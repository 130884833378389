define('clever-sparky-frontend/components/forms/maintenance-form', ['exports', 'ember', 'moment', 'clever-sparky-frontend/mixins/toggle-label-focus', 'clever-sparky-frontend/validators/forms/maintenance', 'clever-sparky-frontend/mixins/handle-error', 'clever-sparky-frontend/mixins/option-lookup'], function (exports, _ember, _moment, _toggleLabelFocus, _maintenance, _handleError, _optionLookup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend(_toggleLabelFocus.default, _maintenance.MaintenanceValidations, _handleError.default, _optionLookup.default, {

    // Attributes

    repairDate: null,

    repair: '',

    notes: '',

    didValidate: false,

    isShowingForm: false,

    rows: [],

    showUpdateFittingModal: false,

    isQueryPending: false,

    maxDate: (0, _moment.default)(),

    testEvent: null,

    testEventFitting: null,

    // Properties

    isTestEventContextAndNotArchived: computed.not('testEvent.archived'),

    showAddNewMaintenanceButton: computed.and('maintenance', 'isTestEventContextAndNotArchived'),

    hasNoRows: computed.equal('rows.length', 0),

    hasNoMaintenances: computed('fitting.maintenances', function () {
      return !this.get('fitting.maintenances.length');
    }),

    shouldShowTestEventWarning: computed('repairDate', function () {
      var repairDate = this.get('repairDate');
      var startDate = this.get('testEvent.startDate');
      var endDate = this.get('testEvent.endDate');
      return repairDate && this.get('testEvent.id') && !(0, _moment.default)(repairDate).isBetween(startDate, endDate, null, '[]');
    }),

    // Methods

    resetForm: function resetForm() {
      this.set('repairDate', null);
      this.set('repair', '');
      this.set('notes', '');
    },
    updateMaintenance: function updateMaintenance() {
      this.set('maintenance.fitting', this.get('fitting'));
      this.set('maintenance.repairDate', this.get('repairDate'));
      this.set('maintenance.repair', this.get('repair'));
      this.set('maintenance.notes', this.get('notes'));
    },


    actions: {
      cancel: function cancel() {
        this.set('didValidate', false);
        this.resetForm();
        this.set('isShowingForm', false);
      },
      save: function save() {
        this.set('didValidate', true);

        if (this.get('validations.isValid')) {
          if (this.get('repair') === 'fitting') {
            this.set('showUpdateFittingModal', true);
          } else {
            this.updateMaintenance();
            this.set('isQueryPending', true);
            this.attrs.save(this.get('maintenance'));
            this.resetForm();
            this.set('isShowingForm', false);
            this.set('didValidate', false);
          }
        }
      },
      update: function update(fitting) {
        var _this = this;

        if (this.get('testEvent')) {
          fitting.set('testEvent', this.get('testEvent'));
        }
        fitting.save().then(function (fitting) {
          _this.set('showUpdateFittingModal', false);
          if (_this.get('testEventFitting')) {
            var currentTestEventFittings = fitting.get('testEventFittings').toArray().map(function (testEventFitting) {
              return testEventFitting.id;
            });
            if (!currentTestEventFittings.includes(_this.get('testEventFitting.id'))) {
              // testEventFitting no longer exists i.e. case where fitting.installDate is updated to outside test event range
              _this.updateMaintenance();
              _this.attrs.save(_this.get('maintenance'), true);
            }
          } else {
            _this.updateMaintenance();
            _this.attrs.save(_this.get('maintenance'));
            _this.resetForm();
            _this.set('isShowingForm', false);
            _this.set('didValidate', false);
          }
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        }).finally(function () {
          _this.set('isQueryPending', false);
        });
      },
      closeUpdateFittingModal: function closeUpdateFittingModal() {
        this.get('fitting').rollbackAttributes();
        this.set('repair', '');
        this.set('showUpdateFittingModal', false);
      },
      show: function show() {
        this.set('isShowingForm', true);
        this.toggleLabelFocus(this.$('#maintenance-form'));
      }
    }

  });
});