define('clever-sparky-frontend/controllers/test-event-group', ['exports', 'ember', 'clever-sparky-frontend/mixins/report-actions', 'clever-sparky-frontend/mixins/file-upload-actions'], function (exports, _ember, _reportActions, _fileUploadActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      computed = _ember.default.computed;
  exports.default = Controller.extend(_reportActions.default, _fileUploadActions.default, {
    site: computed.alias('model.site'),

    currentUser: computed.alias('model.currentUser'),

    currentEmployment: computed.alias('model.currentEmployment'),

    sitesGroups: computed.reads('model.sitesGroups'),

    testEvents: computed.reads('model.testEvents'),

    address: computed.alias('site.address')
  });
});