define('clever-sparky-frontend/routes/reset-password/success', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      ENV = _ember.default.ENV;
  exports.default = Route.extend({
    setupController: function setupController(controller) {
      controller.set('loginRedirectUrl', ENV.branchLoginRedirectUrl);
    }
  });
});