define('clever-sparky-frontend/models/site', ['exports', 'ember', 'ember-data', 'clever-sparky-frontend/validators/site-validator', 'ember-api-actions'], function (exports, _ember, _emberData, _siteValidator, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend(_siteValidator.SiteValidations, {
    // Relationships

    groups: hasMany('group'),

    testEvents: hasMany('test-event', { async: true }),

    siteOwner: belongsTo('site-owner', { polymorphic: true }),

    currentOrder: belongsTo('order'),

    // Attributes

    unit: attr('string'),

    address: attr('string'),

    businessName: attr('string'),

    buildingName: attr('string'),

    contactName: attr('string'),

    contactEmail: attr('string'),

    adminEmail: attr('string'),

    placeId: attr('string'),

    createdAt: attr('date'),

    permitted: attr('boolean'),

    permissionRequested: attr('boolean'),

    groupsCount: attr('number'),

    nexttestdate: attr('string'),
    // Properties
    notPermitted: computed.not('permitted'),

    transferOwnership: (0, _emberApiActions.memberAction)({ path: 'relationships/site-owner' })

  });
});