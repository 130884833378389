define('clever-sparky-frontend/components/forms/fitting-details-form', ['exports', 'ember', 'moment', 'clever-sparky-frontend/mixins/toggle-label-focus', 'clever-sparky-frontend/validators/forms/fitting'], function (exports, _ember, _moment, _toggleLabelFocus, _fitting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed,
      inject = _ember.default.inject;
  exports.default = Component.extend(_fitting.FittingValidations, _toggleLabelFocus.default, {
    // Services

    fittingLookup: inject.service(),

    // Attributes

    didValidate: false,

    canEdit: false,

    isQueryPending: false,

    serialNumber: '',

    fittingType: '',

    description: '',

    groupName: '',

    groupID: '',

    deviceReference: '',

    location: '',

    installDate: '',

    building: '',

    level: '',

    switchboard: '',

    circuit: '',

    assetCode: '',

    previousSerialNumber: '',
    format: 'YYYYMMDD',
    date: null,

    // Hooks

    init: function init() {
      this._super.apply(this, arguments);
      this.setStaticAttributes();
      this.addObserver('fitting.id', this, 'setStaticAttributes');
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.toggleLabelFocus(this.$('#fitting-details-form'));
    },


    // Properties

    isNotCommissioned: computed.not('fitting.commissioned'),

    inputsDisabled: computed.not('canEdit'),

    isArchivedTestEvent: computed('testEvent.id', function () {
      return this.get('testEvent.id') && this.get('testEvent.archived');
    }),

    isNotArchivedTestEvent: computed.not('isArchivedTestEvent'),

    showMarkCommission: false,

    shouldShowTestEventWarning: computed('installDate', 'testEvent.endDate', function () {
      var installDate = this.get('installDate');
      var endDate = this.get('testEvent.endDate');
      return installDate && endDate && !(0, _moment.default)(installDate).isSameOrBefore(endDate);
    }),

    showFittingInfoBanner: computed.alias('canEdit'),

    // Methods

    setStaticAttributes: function setStaticAttributes() {
      this.set('serialNumber', this.get('fitting.serialNumber'));
      this.set('fittingType', this.get('fitting.fittingType'));
      this.set('description', this.get('fitting.description'));
      this.set('deviceReference', this.get('fitting.deviceReference'));
      this.set('location', this.get('fitting.location'));
      this.set('installDate', this.test());
      this.set('building', this.get('fitting.building'));
      this.set('level', this.get('fitting.level'));
      this.set('switchboard', this.get('fitting.switchboard'));
      this.set('circuit', this.get('fitting.circuit'));
      this.set('assetCode', this.get('fitting.assetCode'));
      this.set('groupName', this.get('group.name'));
      this.set('groupID', this.get('group.id'));
      this.set('previousSerialNumber', this.get('fitting.serialNumber'));
    },
    test: function test() {
      var currentdate = this.get('fitting.installDate');
      if (currentdate == 'Invalid date') {
        currentdate = (0, _moment.default)().format('YYYY-MM-DD');
      }
      return currentdate;
    },
    testupdatedinstalldate: function testupdatedinstalldate() {
      var returndate = this.get('installDate');
      var currentdate = (0, _moment.default)(this.get('installDate')).isValid();
      if (currentdate === false) {
        returndate = (0, _moment.default)().format('YYYY-MM-DD');
      }
      return returndate;
    },
    updateFitting: function updateFitting() {
      this.set('fitting.serialNumber', this.get('serialNumber'));
      this.set('fitting.fittingType', this.get('fittingType'));
      this.set('fitting.description', this.get('description'));
      this.set('fitting.deviceReference', this.get('deviceReference'));
      this.set('fitting.location', this.get('location'));
      this.set('fitting.installDate', this.testupdatedinstalldate());
      this.set('fitting.building', this.get('building'));
      this.set('fitting.level', this.get('level'));
      this.set('fitting.switchboard', this.get('switchboard'));
      this.set('fitting.circuit', this.get('circuit'));
      this.set('fitting.assetCode', this.get('assetCode'));
    },
    resetFitting: function resetFitting() {
      this.set('serialNumber', this.get('fitting.serialNumber'));
      this.set('fittingType', this.get('fitting.fittingType'));
      this.set('description', this.get('fitting.description'));
      this.set('deviceReference', this.get('fitting.deviceReference'));
      this.set('location', this.get('fitting.location'));
      this.set('installDate', this.get('fitting.installDate'));
      this.set('building', this.get('fitting.building'));
      this.set('level', this.get('fitting.level'));
      this.set('switchboard', this.get('fitting.switchboard'));
      this.set('circuit', this.get('fitting.circuit'));
      this.set('assetCode', this.get('fitting.assetCode'));
      this.set('previousSerialNumber', this.get('fitting.serialNumber'));
    },


    actions: {
      cancel: function cancel() {
        this.set('didValidate', false);
        this.resetFitting();
        this.sendAction('cancel');
      },
      save: function save() {
        this.set('didValidate', true);
        this.set('isQueryPending', true);

        if (this.get('validations.isValid')) {
          this.updateFitting();
          this.sendAction('save', this.get('fitting'));
        }
      },
      commission: function commission() {
        this.set('fitting.commissioned', true);
        this.sendAction('commission', this.get('fitting'));
      },
      lookupFittingInfo: function lookupFittingInfo() {
        var _this = this;

        if (this.get('serialNumber') && this.get('serialNumber') !== this.get('previousSerialNumber')) {
          this.set('previousSerialNumber', this.get('serialNumber'));
          this.get('fittingLookup').lookupFittingInfo(this.get('serialNumber')).then(function (_ref) {
            var fittingType = _ref.fittingType,
                description = _ref.description;

            if (fittingType) {
              _this.set('fittingType', fittingType);
              _this.set('description', description);
            } else {
              _this.set('fittingType', '');
              _this.set('description', '');
            }
          });
        }
      }
    }

  });
});