define('clever-sparky-frontend/controllers/sites', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      computed = _ember.default.computed;
  exports.default = Controller.extend({

    // Attributes

    queryParams: ['query', 'sort'],

    displayAddSiteModal: false,

    query: '',

    sort: '',

    // Properties

    sites: computed.alias('model.sites'),

    site: computed.alias('model.site'),

    hasSites: computed.gt('sites.length', 0),

    hasNoSites: computed.not('hasSites'),

    isPerformingSearch: computed.notEmpty('query'),

    noSearchResults: computed.and('isPerformingSearch', 'hasNoSites'),

    postOnboardingTourNames: ['postOnboarding', 'postOnboardingTechnician'],

    actions: {
      toggleAddSite: function toggleAddSite() {
        this.toggleProperty('displayAddSiteModal');
      },
      setSitesSearchParam: function setSitesSearchParam(term) {
        this.set('query', term);
      },
      setSitesSortParam: function setSitesSortParam(column) {
        var sortQuery = this.get('sort');
        var descOrderSortQuery = '-' + column;
        if (sortQuery && sortQuery.indexOf(column) === 0) {
          sortQuery = descOrderSortQuery;
        } else {
          sortQuery = column;
        }
        this.set('sort', sortQuery);
      }
    }

  });
});