define('clever-sparky-frontend/routes/base-routes/show', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP;
  exports.default = Route.extend({
    model: function model(params) {
      var _this = this;

      return new RSVP.Promise(function (resolve) {
        _this.get('store').findRecord('fitting', params.fitting_id, { reload: true }).then(function (fitting) {
          RSVP.hash({
            fitting: fitting,
            group: fitting.get('group'),
            latestTest: fitting.get('latestTest'),
            newTest: _this.store.createRecord('ledtest'),
            maintenances: fitting.get('maintenances')
          }).then(function (model) {
            resolve(model);
          });
        });
      });
    }
  });
});