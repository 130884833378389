define('clever-sparky-frontend/services/fitting-lookup', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = _ember.default.Service,
      inject = _ember.default.inject,
      ENV = _ember.default.ENV,
      RSVP = _ember.default.RSVP;
  exports.default = Service.extend({
    requestService: inject.service('request-service'),

    _fittingLookupMethod: 'POST',

    _fittingLookupURL: function _fittingLookupURL() {
      return ENV.api.host + '/' + ENV.api.namespace + '/fitting-lookups';
    },
    lookupFittingInfo: function lookupFittingInfo(serialNumber) {
      var _this = this;

      var url = this._fittingLookupURL();
      var method = this.get('_fittingLookupMethod');
      var data = {
        data: {
          type: 'fitting-lookups',
          attributes: {
            serialNumber: serialNumber
          }
        }
      };

      return new RSVP.Promise(function (resolve) {
        _this.get('requestService').sendAuthenticatedRequest(url, method, data).then(function (result) {
          var resultObj = {
            fittingType: result.data.attributes['fitting-type'],
            description: result.data.attributes['description']
          };
          resolve(resultObj);
        }).catch(function () {});
      });
    }
  });
});