define('clever-sparky-frontend/components/faults-dropdown-menu', ['exports', 'ember', 'clever-sparky-frontend/components/dropdown-menu'], function (exports, _ember, _dropdownMenu) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed;
  exports.default = _dropdownMenu.default.extend({
    // Attributes
    clickOutsideParentElementSelector: '.faults-dropdown-menu',

    classNames: ['dropdown-menu', 'faults-dropdown-menu'],

    // Properties

    hasFaultyFittings: computed.bool('faultyFittings.length'),

    hasNoFaultyFittings: computed.not('hasFaultyFittings'),

    actions: {
      orderReplacements: function orderReplacements() {
        this.attrs.showOrderReplacements();
        this.send('close');
      }
    }
  });
});