define('clever-sparky-frontend/routes/onboarding', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'clever-sparky-frontend/mixins/show-nav-bar'], function (exports, _ember, _authenticatedRouteMixin, _showNavBar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      inject = _ember.default.inject;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _showNavBar.default, {

    // services

    features: inject.service(),

    // Hooks

    beforeModel: function beforeModel() {
      if (!this.get('features').isEnabled('guidedTour')) {
        this.transitionTo('sites');
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      // Don't show switch account modal when on the onboarding screen
      this.controllerFor('application').set('displaySwitchAccountModal', false);
    },


    actions: {
      goToSites: function goToSites() {
        // reset the slides to the first one
        this.controller.set('currentSlide', 0);
        this.transitionTo('sites');
        if (this.controllerFor('application').get('hasJustSignedUp')) {
          this.controllerFor('application').set('hasJustSignedUp', false);
        }
        if (this.controllerFor('application').get('shouldShowSwitchAccountModal')) {
          this.controllerFor('application').set('displaySwitchAccountModal', true);
        }
      }
    }
  });
});