define('clever-sparky-frontend/validators/forms/edit-user-validator', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.EditUserValidations = undefined;
  var EditUserValidations = exports.EditUserValidations = (0, _emberCpValidations.buildValidations)({
    firstName: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please enter your first name'
    }),

    lastName: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please enter your last name'
    }),

    state: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please select your state'
    }),

    country: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please select your country'
    }),

    phone: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please enter your mobile number'
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      message: 'Phone number must only include numbers'
    })],

    email: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please enter your email address'
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email',
      message: 'Please enter a valid email'
    })]
  });

  exports.default = { EditUserValidations: EditUserValidations };
});