define('clever-sparky-frontend/controllers/test-event/groups', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      computed = _ember.default.computed;
  exports.default = Controller.extend({

    site: computed.alias('model.site'),

    testEvent: computed.alias('model.testEvent'),

    testEventGroups: computed.alias('model.testEventGroups'),

    missingGroups: computed.alias('model.missingGroups'),

    newTestEvent: computed.alias('model.newTestEvent'),

    duplicatedTestEvent: computed('newTestEvent', 'testEvent', function () {
      var newStartDate = (0, _moment.default)(this.get('testEvent.endDate')).add(1, 'days');
      var newEndDate = (0, _moment.default)(newStartDate).add(6, 'months');
      this.set('newTestEvent.startDate', newStartDate.format('YYYY-MM-DD'));
      this.set('newTestEvent.endDate', newEndDate.format('YYYY-MM-DD'));
      return this.get('newTestEvent');
    }),

    selectedGroups: [],

    displayAddGroupsModal: false,

    displayRemoveGroupsModal: false,

    displayEditDateRangeModal: false,

    displayDuplicateTestEventModal: false,

    displayArchiveConfirmationModal: false,

    displayQuickTestDefaultsModal: false,

    isQueryPending: false,

    formattedAddGroups: computed.map('missingGroups', function (item) {
      return { id: item.get('id'), name: item.get('name') };
    }),

    addGroupsOptions: computed('formattedAddGroups', function () {
      var formattedAddGroups = this.get('formattedAddGroups');
      formattedAddGroups.unshift({ id: 'all', name: 'All Groups' });
      return formattedAddGroups;
    }),

    formattedRemoveGroups: computed.map('testEventGroups', function (item) {
      return { id: item.get('group.id'), name: item.get('group.name') };
    }),

    removeGroupsOptions: computed('formattedRemoveGroups', function () {
      var formattedRemoveGroups = this.get('formattedRemoveGroups');
      formattedRemoveGroups.unshift({ id: 'all', name: 'All Groups' });
      return formattedRemoveGroups;
    }),

    actions: {
      showTestEventGroupDetails: function showTestEventGroupDetails(testEventGroup) {
        this.transitionToRoute('test-event-group', testEventGroup.id);
      },
      showAddGroupsModal: function showAddGroupsModal() {
        this.set('displayAddGroupsModal', true);
      },
      closeAddGroupsModal: function closeAddGroupsModal() {
        this.set('displayAddGroupsModal', false);
        this.set('selectedGroups', []);
      },
      showRemoveGroupsModal: function showRemoveGroupsModal() {
        this.set('displayRemoveGroupsModal', true);
      },
      closeRemoveGroupsModal: function closeRemoveGroupsModal() {
        this.set('displayRemoveGroupsModal', false);
        this.set('selectedGroups', []);
      },
      showEditDateRangeModal: function showEditDateRangeModal() {
        this.set('displayEditDateRangeModal', true);
      },
      closeEditDateRangeModal: function closeEditDateRangeModal() {
        this.set('displayEditDateRangeModal', false);
      },
      showDuplicateTestEventModal: function showDuplicateTestEventModal() {
        this.set('displayDuplicateTestEventModal', true);
      },
      closeDuplicateTestEventModal: function closeDuplicateTestEventModal() {
        this.set('displayDuplicateTestEventModal', false);
      },
      showArchiveConfirmationModal: function showArchiveConfirmationModal() {
        this.set('displayArchiveConfirmationModal', true);
      },
      closeArchiveConfirmationModal: function closeArchiveConfirmationModal() {
        this.set('displayArchiveConfirmationModal', false);
      },
      showQuickTestDefaultsModal: function showQuickTestDefaultsModal() {
        this.set('displayQuickTestDefaultsModal', true);
      },
      closeQuickTestDefaultsModal: function closeQuickTestDefaultsModal() {
        this.set('displayQuickTestDefaultsModal', false);
      }
    }

  });
});