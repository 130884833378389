define('clever-sparky-frontend/components/site-menu', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend({

    site: null,

    classNames: ['site-menu'],

    isAllfittingsActive: false,

    isGroupsActive: false,

    isTestEventsActive: false,

    isShoppingCartActive: false,

    viewSiteTourNames: [],

    isTestEventsEnabled: computed(function () {
      return this.get('features').isEnabled('test-events');
    }),

    isShoppingCartEnabled: computed(function () {
      return this.get('features').isEnabled('shopping-cart');
    }),

    orderItemsCount: computed('site.currentOrder.orderItemsCount', function () {
      return this.get('site.currentOrder.orderItemsCount') ? this.get('site.currentOrder.orderItemsCount') : 0;
    }),

    showOrderItemsCount: computed.gt('orderItemsCount', 0)

  });
});