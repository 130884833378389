define('clever-sparky-frontend/components/test-event-fitting-table', ['exports', 'ember', 'ember-local-storage'], function (exports, _ember, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed,
      ENV = _ember.default.ENV,
      inject = _ember.default.inject;
  exports.default = Component.extend({
    holdvaribles: inject.service('holdvaribles'),
    sitetmp: (0, _emberLocalStorage.storageFor)('site'),
    // Attributes
    selectedTestEventFittings: [],

    viewTestEventGroupTourNames: ['viewTestEventGroup'],

    isSelectingTestEventFittings: false,

    // Properties
    sortAscending: false,
    siteid: null,
    allfittings: false,
    sortProperties: ['id'],
    sortPropertiesDR: ['updatedAt'],

    arrangedContent: _ember.default.computed('testEventFittings', 'sortPropertiesDR', 'sortAscending', function () {
      var _this = this;

      return this.get('testEventFittings').toArray().sort(function (a, b) {
        var sortProperty = _this.get('sortProperties')[0];
        if (_this.get('sortAscending')) {
          var newa = parseInt(a.get(sortProperty).match(/\d+/g));
          var newb = parseInt(b.get(sortProperty).match(/\d+/g));

          if (newa > newb) {
            return 1;
          } else {
            return -1;
          }
        } else {
          var _newa = parseInt(a.get(sortProperty).match(/\d+/g));
          var _newb = parseInt(b.get(sortProperty).match(/\d+/g));

          if (_newa < _newb) {
            return 1;
          } else {
            return -1;
          }
        }
      });
    }),
    mod: computed.alias('model.data'),

    hasNoRecords: computed.equal('testEventFittings.length', 0),

    isCollected: computed.reads('collected'),

    isNotCollected: computed.not('isCollected'),

    collectedCount: computed.reads('testEventGroup.collectedTestEventFittingsCount'),

    notCollectedCount: computed.reads('testEventGroup.notCollectedTestEventFittingsCount'),

    isEmptyTestEventGroup: computed('collectedCount', 'notCollectedCount', function () {
      return this.get('collectedCount') + this.get('notCollectedCount') === 0;
    }),

    noFittingsSelected: computed.equal('selectedTestEventFittings.length', 0),

    // Methods
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      var local = localStorage.getItem('ember_simple_auth-session');
      var test = JSON.parse(local);
      var tt = test['authenticated'];
      var tt2 = tt['session'];
      var sessionID = tt2['session_id'];
      var token = tt2['token'];

      var url = ENV.api.host + '/' + ENV.api.namespace + '/filtersapi';
      var groupid = this.get('testEventGroup.group');
      if (groupid) {
        $.ajax({
          url: url,
          method: 'POST',
          data: JSON.stringify({
            data: {
              attributes: {
                usage: 'get',
                groupid: groupid.id
              },
              type: 'sessions'
            }
          }),
          headers: {
            'Content-Type': 'application/json',
            'X-Session-Id': sessionID,
            'X-Session-Token': token
          },
          success: function success() {},

          error: function error(resp) {
            var thistxt = resp.responseText;

            if (thistxt === 'Decending') {
              _this2.set('sortAscending', false);
            } else {
              _this2.set('sortAscending', true);
            }

            _this2.set('sortProperties', ['fitting.deviceReference']);

            _this2.set('testEventFittings', _this2.get('arrangedContent'));
          }
        });
      }
    },
    setIsNotCollectedFilter: function setIsNotCollectedFilter() {},
    setIsCollectedFilter: function setIsCollectedFilter() {},
    showTestEventFittingDetails: function showTestEventFittingDetails() {},
    showAddFittingModal: function showAddFittingModal() {},
    showQuickTestModal: function showQuickTestModal() {},
    backToTestEventFittingsIndex: function backToTestEventFittingsIndex() {},
    init: function init() {
      this._super.apply(this, arguments);
      var groupid = this.get('group');
      var isallfittings = this.get('holdvaribles').showallfittingpagebool();
      this.set('allfittings', isallfittings);
      if (isallfittings) {
        var fittings = this.get('testEventFittings');
        var totalfittings = this.get('mod');
        var notcollectioned = fittings.filter(function (item) {
          return item.data.collected === false;
        });

        var Collected = totalfittings - notcollectioned.length;
        if (Collected < 0) {
          Collected = 0;
        }
        this.set('notCollectedCount', notcollectioned.length);
        this.set('collectedCount', Collected);
      }
      // let siteidx = this.get('holdvaribles').showCurrentSite();
      var siteidx = this.get('sitetmp.site');
      this.set('siteid', siteidx.id);
    },


    actions: {
      SelectAll: function SelectAll() {
        this.set('selectedTestEventFittings', this.get('testEventFittings'));
      },

      sortBy: function sortBy(property) {
        this.set('sortProperties', [property]);
        this.toggleProperty('sortAscending');

        this.set('testEventFittings', this.get('arrangedContent')); // set the model to the sorted array

        var groupid = this.get('testEventGroup.group');
        var sortingmethodtemp = '';
        if (this.get('sortAscending')) {
          sortingmethodtemp = 'Ascending';
        } else {
          sortingmethodtemp = 'Decending';
        }

        var url = ENV.api.host + '/' + ENV.api.namespace + '/filtersapi';
        var local = localStorage.getItem('ember_simple_auth-session');
        var test = JSON.parse(local);
        var tt = test['authenticated'];
        var tt2 = tt['session'];
        var sessionID = tt2['session_id'];
        var token = tt2['token'];
        $.ajax({
          url: url,
          method: 'POST',
          data: JSON.stringify({
            data: {
              attributes: {
                usage: 'set',
                groupid: groupid.id,
                method: sortingmethodtemp

              },
              type: 'sessions'
            }
          }),
          headers: {
            'Content-Type': 'application/json',
            'X-Session-Id': sessionID,
            'X-Session-Token': token
          },
          success: function success() {},
          error: function error() {}
        });
      },
      cancelSelectTestEventFittings: function cancelSelectTestEventFittings() {
        this.set('selectedTestEventFittings', []);
        this.set('isSelectingTestEventFittings', false);
      },
      batchTestResults: function batchTestResults() {
        this.attrs.backToTestEventFittingsIndex();
        this.set('isSelectingTestEventFittings', true);
      },
      setIsNotCollectedFilter: function setIsNotCollectedFilter() {
        this.attrs.setIsNotCollectedFilter();
      },
      setIsCollectedFilter: function setIsCollectedFilter() {
        this.attrs.setIsCollectedFilter();
        this.send('cancelSelectTestEventFittings');
      },
      showQuickTestPassModal: function showQuickTestPassModal() {
        this.attrs.showQuickTestModal(true, this.get('selectedTestEventFittings'));
      },
      showQuickTestFailModal: function showQuickTestFailModal() {
        this.attrs.showQuickTestModal(false, this.get('selectedTestEventFittings'));
      }
    }

  });
});