define('clever-sparky-frontend/routes/sites', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'clever-sparky-frontend/mixins/show-nav-bar', 'ember-infinity/mixins/route', 'clever-sparky-frontend/mixins/handle-error', 'clever-sparky-frontend/mixins/setup-tour-sequence', 'ember-can'], function (exports, _ember, _authenticatedRouteMixin, _showNavBar, _route, _handleError, _setupTourSequence, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var later = _ember.default.run.later;
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP,
      inject = _ember.default.inject;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _showNavBar.default, _route.default, _handleError.default, _setupTourSequence.default, _emberCan.CanMixin, {
    // Services

    session: inject.service('session'),
    branch: inject.service('branch'),
    requestSiteAccess: inject.service('request-site-access'),

    postOnboardingTour: inject.service('tours/post-onboarding'),

    postOnboardingTechnicianTour: inject.service('tours/post-onboarding-technician'),

    viewRestrictedSitesTour: inject.service('tours/view-restricted-sites'),

    userDropdownMenuTour: inject.service('tours/user-dropdown-menu'),

    userDropdownMenuTechnicianTour: inject.service('tours/user-dropdown-menu-technician'),

    features: inject.service(),

    // Route attributes

    queryParams: {
      query: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },

    // Attributes
    perPageParam: 'page[size]', // instead of 'per_page'

    pageParam: 'page[number]', // instead of 'page'

    totalPagesParam: 'meta.total-pages', // instead of "meta.total_pages"

    // Hooks
    beforeModel: function beforeModel() {
      var _this = this;

      if (this.get('features').isEnabled('guidedTour')) {
        this.store.query('tooltip-event', {
          filter: {
            'event-name': 'onboarding-web'
          }
        }).then(function (onboardingTooltipEvents) {
          if (!onboardingTooltipEvents.get('length')) {
            if (_this.controllerFor('application').get('hasJustLoggedIn') || _this.controllerFor('application').get('hasJustSignedUp')) {
              var tooltipEvent = _this.store.createRecord('tooltip-event', {
                eventName: 'onboarding-web'
              });
              tooltipEvent.save();
              _this.transitionTo('onboarding');
            }
          }
          if (_this.controllerFor('application').get('hasJustLoggedIn')) {
            // prevents the tour starting when the organisation select modal is displayed after login in
            return;
          }

          var _modelFor = _this.modelFor('application'),
              currentEmployment = _modelFor.currentEmployment;

          var viewSiteListTooltipEvent = void 0,
              viewUserDropdownTooltipEvent = void 0,
              viewRestrictedSiteTooltipEvent = void 0;
          if (_this.can('view user-management', currentEmployment)) {
            _this.deactivateTour(_this.get('postOnboardingTechnicianTour'));
            _this.deactivateTour(_this.get('viewRestrictedSitesTour'));
            viewSiteListTooltipEvent = _this.setupTourSequence('view_site_list_web', _this.get('postOnboardingTour'));
          } else {
            _this.deactivateTour(_this.get('postOnboardingTour'));
            viewSiteListTooltipEvent = _this.setupTourSequence('view_site_list_web_technician', _this.get('postOnboardingTechnicianTour'));
            viewRestrictedSiteTooltipEvent = _this.setupTourSequence('restricted_site_web', _this.get('viewRestrictedSitesTour'));
          }
          if (_this.can('modify business-profile', currentEmployment)) {
            _this.deactivateTour(_this.get('userDropdownMenuTechnicianTour'));
            viewUserDropdownTooltipEvent = _this.setupTourSequence('user_dropdown_web', _this.get('userDropdownMenuTour'));
          } else {
            _this.deactivateTour(_this.get('userDropdownMenuTour'));
            viewUserDropdownTooltipEvent = _this.setupTourSequence('user_dropdown_web_technician', _this.get('userDropdownMenuTechnicianTour'));
          }
          return RSVP.hash({
            viewSiteListTooltipEvent: viewSiteListTooltipEvent,
            viewRestrictedSiteTooltipEvent: viewRestrictedSiteTooltipEvent,
            viewUserDropdownTooltipEvent: viewUserDropdownTooltipEvent
          });
        }).catch(function () {});
      }
    },
    model: function model(params) {
      this.store.unloadAll('group');

      var _modelFor2 = this.modelFor('application'),
          currentOrganisation = _modelFor2.currentOrganisation,
          currentUser = _modelFor2.currentUser;

      var currentOrganisationId = currentOrganisation ? currentOrganisation.get('id') : null;
      this.get('session').setupCurrentOrganisation(currentOrganisation);

      var filter = {};
      var sort = '';
      if (params.hasOwnProperty('query')) {
        filter.query = params.query;
      }
      if (params.hasOwnProperty('sort')) {
        sort = params.sort;
      }
      var newadminlist = null;
      if (currentUser.data.admin) {
        newadminlist = this.store.findAll('admin');
      }
      return RSVP.hash({
        userlist: newadminlist,
        admin: currentUser.data.admin,
        sites: this.infinityModel('site', {
          perPage: 30,
          startingPage: 1,
          modelPath: 'controller.model.sites',
          currentOrganisationId: currentOrganisationId,
          filter: filter,
          sort: sort
        }),
        site: this.store.createRecord('site', {
          siteOwner: currentOrganisation
        })
      });
    },


    // eslint-disable-next-line no-unused-vars
    afterModel: function afterModel(model, transition) {
      later(this, function () {
        this.get('branch').clearInvite();
      }, 10000);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.controller.set('query', '');
        this.controller.set('sort', '');
      }
    },


    actions: {
      save: function save(site) {
        var _this2 = this;

        site.save().then(function () {
          _this2.refresh();
          _this2.controller.send('toggleAddSite');
          _this2.showSuccessFlash('Site Added');
        }).catch(function (reason) {
          _this2.handleRailsError(reason);
        }).finally(function () {
          _this2.controller.set('isQueryPending', false);
        });
      },
      showAdminLogin: function showAdminLogin() {
        //  let password  = "testtest6";
        /*
          Ember.$.ajax({
            url: "http://localhost:5000/api/v1/admins/",
            type: "POST",
            headers: {
              'Content-Type':'application/json'
            },
            data: JSON.stringify({
              data:{attributes:{email:"k.mitchell@clevertronics.com.au",password:"testtest6",userid:"f1d5be8b-bcac-42e6-84a5-40d5038ccdfd"},type:"sessions"}
        })
        }).then(function(resp){
        })*/
      },
      showSiteDetails: function showSiteDetails(site) {
        this.transitionTo('site', site.id);
      },
      requestSiteAccess: function requestSiteAccess(site) {
        var _this3 = this;

        this.get('requestSiteAccess').requestAccess(site).then(function () {
          _this3.showSuccessFlash('Access Requested');
          _this3.controller.set('isQueryPending', false);
          _this3.refresh();
        }).catch(function (reason) {
          _this3.handleRailsError(reason);
        });
      }
    }

  });
});