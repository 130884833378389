define('clever-sparky-frontend/services/request-password-reset', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = _ember.default.Service,
      ENV = _ember.default.ENV,
      inject = _ember.default.inject;
  exports.default = Service.extend({

    requestService: inject.service('request-service'),

    _requestPasswordResetMethod: 'POST',

    _requestPasswordResetURL: function _requestPasswordResetURL() {
      return ENV.api.host + '/' + ENV.api.namespace + '/reset-password';
    },
    requestPasswordReset: function requestPasswordReset(email) {
      var url = this._requestPasswordResetURL();
      var method = this.get('_requestPasswordResetMethod');
      var data = {
        data: {
          type: 'passwords',
          attributes: {
            email: email
          }
        }
      };

      return this.get('requestService').sendRequest(url, method, data);
    }
  });
});