define('clever-sparky-frontend/serializers/site', ['exports', 'ember', 'clever-sparky-frontend/serializers/application'], function (exports, _ember, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isArray = _ember.default.isArray;
  exports.default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var json = this._super.apply(this, arguments);

      if (requestType === 'findRecord') {
        json.data.attributes['groupsCount'] = json.data.relationships.groups.data.length;
      } else if (isArray(json.data)) {
        json.data.forEach(function (site) {
          site.attributes['groups-count'] = site.relationships.groups.data.length;
        });
      }

      return json;
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      // groups count is only used frontend so doesn't need to be persisted
      delete json.data.attributes['groups-count'];

      if (json.data.relationships && !json.data.relationships['site-owner'].data) {
        delete json.data.relationships['site-owner'];
      }
      return json;
    }
  });
});