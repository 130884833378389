define('clever-sparky-frontend/components/forms/test-and-status-form', ['exports', 'ember', 'moment', 'clever-sparky-frontend/mixins/toggle-label-focus', 'clever-sparky-frontend/validators/forms/ledtest'], function (exports, _ember, _moment, _toggleLabelFocus, _ledtest) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed,
      observer = _ember.default.observer;
  exports.default = Component.extend(_ledtest.LEDTestValidations, _toggleLabelFocus.default, {

    // Attributes

    didValidate: false,

    canEdit: false,

    isQueryPending: false,

    isConfirmAddNewTest: false,

    hasConfirmedNewTest: false,

    isShowingLatestTest: false,

    currentLatestTest: null,

    showDeleteTestModal: false,

    testStatus: '',

    testDate: '',

    runTime: '',

    nextTestDate: '',

    requiredDuration: '',

    batteryStatus: '',

    lampStatus: '',

    controlGearStatus: '',

    // Properties

    inputsDisabled: computed.not('canEdit'),

    showAddNewTestButton: computed('testEvent.id', function () {
      return this.get('testEvent.id') ? !this.get('testEvent.archived') : true;
    }),

    isNotAddingNewTest: computed.not('test.isNew'),

    hasLatestTest: computed.notEmpty('latestTest'),

    showTestForm: computed.or('hasLatestTest', 'hasConfirmedNewTest'),

    hasNoTests: computed('fitting.latestTest', function () {
      return !this.get('fitting.latestTest.id');
    }),

    test: computed('latestTest.id', 'newTest.id', 'hasConfirmedNewTest', function () {
      return this.get('latestTest.id') && !this.get('hasConfirmedNewTest') ? this.get('latestTest') : this.get('newTest');
    }),

    shouldShowTestEventWarning: computed('testDate', function () {
      var testDate = this.get('testDate');
      var startDate = this.get('testEvent.startDate');
      var endDate = this.get('testEvent.endDate');
      return testDate && this.get('testEvent.id') && !(0, _moment.default)(testDate).isBetween(startDate, endDate, null, '[]');
    }),

    // Observers

    testIdDidChange: observer('test.id', function () {
      if (!this.get('test.isNew')) {
        this.set('currentLatestTest', this.get('latestTest'));
      }
      this.setStaticAttributes(this.get('fitting'), this.get('test'));
    }),

    // Component hooks

    didInsertElement: function didInsertElement() {
      this.toggleLabelFocus(this.$('#test-and-status-form'));
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.setStaticAttributes(this.get('fitting'), this.get('test'));
      this.set('currentLatestTest', this.get('latestTest'));
    },


    // Methods

    setStaticAttributes: function setStaticAttributes(fitting, test) {
      this.set('testStatus', test.get('testStatus'));
      this.set('testDate', test.get('testDate'));
      this.set('runTime', test.get('runTime'));
      this.set('nextTestDate', test.get('nextTestDate'));
      this.set('requiredDuration', test.get('requiredDuration'));
      this.set('batteryStatus', fitting.get('batteryStatus'));
      this.set('lampStatus', fitting.get('lampStatus'));
      this.set('controlGearStatus', fitting.get('controlGearStatus'));
    },
    updateTestAndFitting: function updateTestAndFitting() {
      this.set('test.testStatus', this.get('testStatus'));
      this.set('test.testDate', this.get('testDate'));
      this.set('test.runTime', this.get('runTime'));
      this.set('test.nextTestDate', this.get('nextTestDate'));
      this.set('test.requiredDuration', this.get('requiredDuration'));
      this.set('fitting.batteryStatus', this.get('batteryStatus'));
      this.set('fitting.lampStatus', this.get('lampStatus'));
      this.set('fitting.controlGearStatus', this.get('controlGearStatus'));
    },
    resetTestAndFitting: function resetTestAndFitting() {
      this.set('testStatus', this.get('test.testStatus'));
      this.set('testDate', this.get('test.testDate'));
      this.set('runTime', this.get('test.runTime'));
      this.set('nextTestDate', this.get('test.nextTestDate'));
      this.set('requiredDuration', this.get('test.requiredDuration'));
      this.set('batteryStatus', this.get('fitting.batteryStatus'));
      this.set('lampStatus', this.get('fitting.lampStatus'));
      this.set('controlGearStatus', this.get('fitting.controlGearStatus'));
    },
    resetTestFields: function resetTestFields() {
      this.set('testStatus', '');
      this.set('testDate', '');
      this.set('runTime', '');
      this.set('nextTestDate', '');
      this.set('requiredDuration', '');
    },


    actions: {
      cancel: function cancel() {
        this.set('didValidate', false);
        this.set('latestTest', this.get('currentLatestTest'));
        this.resetTestAndFitting();
        this.set('isConfirmAddNewTest', false);
        this.set('hasConfirmedNewTest', false);
        this.sendAction('cancel');
      },
      save: function save() {
        this.set('didValidate', true);

        if (this.get('validations.isValid')) {
          this.set('isQueryPending', true);
          this.updateTestAndFitting();
          this.set('isConfirmAddNewTest', false);
          this.sendAction('save', this.get('fitting'), this.get('test'));
          this.set('hasConfirmedNewTest', false);
          this.set('didValidate', false);
        }
      },
      addNewTest: function addNewTest() {
        this.set('isConfirmAddNewTest', true);
      },
      confirm: function confirm() {
        this.sendAction('edit');
        this.set('currentLatestTest', this.get('latestTest'));
        this.resetTestFields();

        this.set('hasConfirmedNewTest', true);
        this.set('isConfirmAddNewTest', false);
      },
      showDeleteTestModal: function showDeleteTestModal() {
        this.set('showDeleteTestModal', true);
      },
      closeDeleteTestModal: function closeDeleteTestModal() {
        this.set('showDeleteTestModal', false);
      },
      deleteTest: function deleteTest() {
        this.set('isQueryPending', true);
        this.sendAction('deleteTest', this.get('test'));
        this.set('showDeleteTestModal', false);
      }
    }

  });
});