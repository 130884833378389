define('clever-sparky-frontend/routes/terms', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'clever-sparky-frontend/mixins/show-nav-bar'], function (exports, _ember, _authenticatedRouteMixin, _showNavBar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _showNavBar.default, {
    model: function model() {
      return RSVP.hash({
        currentEmployment: this.modelFor('application').currentEmployment
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('currentEmployment', model.currentEmployment);
    }
  });
});