define('clever-sparky-frontend/components/site-details-dropdown-menu', ['exports', 'clever-sparky-frontend/components/dropdown-menu', 'ember-can'], function (exports, _dropdownMenu, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _dropdownMenu.default.extend(_emberCan.CanMixin, {
    clickOutsideParentElementSelector: '.site-details-dropdown-menu',
    classNames: ['dropdown-menu site-details-dropdown-menu'],

    actions: {
      edit: function edit() {
        this.attrs.edit();
        this.send('close');
      },
      delete: function _delete() {
        this.attrs.delete();
        this.send('close');
      },
      openTransferSiteModal: function openTransferSiteModal() {
        this.attrs.openTransferSiteModal();
        this.send('close');
      },
      openTransferSiteToUserModal: function openTransferSiteToUserModal() {
        this.attrs.openTransferSiteToUserModal();
        this.send('close');
      }
    }
  });
});