define('clever-sparky-frontend/components/load-more-button', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend({

    modelBeingPaginated: '',

    canLoad: computed.not('infinityModel.reachedInfinity'),

    loadText: computed(function () {
      return 'Load More ' + this.get('modelBeingPaginated');
    }),

    totalObjects: computed.reads('infinityModel.meta.total-objects'),

    numberOfItemsDisplayed: computed.reads('infinityModel.length'),

    numberOfItemsDisplayedText: computed('numberOfItemsDisplayed', function () {

      return '(Showing ' + this.get('numberOfItemsDisplayed') + ' of ' + this.get('totalObjects') + ')';
    }),

    actions: {
      loadMore: function loadMore() {
        this.infinityLoad(this.get('infinityModel'));
      }
    }
  });
});