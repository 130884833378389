define('clever-sparky-frontend/services/branch', ['exports', 'ember', 'ember-local-storage'], function (exports, _ember, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = _ember.default.Service;
  exports.default = Service.extend({

    invite: (0, _emberLocalStorage.storageFor)('invite'),

    setInviteToLocalStorage: function setInviteToLocalStorage() {
      var branchData = JSON.parse(window.localStorage.branch_session_first).data;
      var inviteObj = JSON.parse(branchData).invite;

      // eslint-disable-next-line no-console
      console.log(inviteObj);
      if (inviteObj) {
        this.set('invite.id', inviteObj.id);
        if (inviteObj.token) {
          this.set('invite.id', inviteObj.id);
          this.set('invite.token', inviteObj.token);
        } else {
          this.set('invite.sitename', inviteObj.sitename);
          this.set('invite.sitetransfertouser', inviteObj.sitetransfertouser);
        }
      }
    },
    getInvite: function getInvite() {
      return this.get('invite');
    },
    clearInvite: function clearInvite() {
      this.get('invite').clear();
      window.localStorage.removeItem('branch_session_first');
    },
    justInvite: function justInvite() {
      this.get('invite').clear();
    },
    justBranchfirst: function justBranchfirst() {
      window.localStorage.removeItem('branch_session_first');
    }
  });
});