define('clever-sparky-frontend/routes/application', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin', 'clever-sparky-frontend/mixins/handle-error', 'ember-local-storage'], function (exports, _ember, _applicationRouteMixin, _handleError, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP,
      inject = _ember.default.inject;
  exports.default = Route.extend(_applicationRouteMixin.default, _handleError.default, {

    invite: (0, _emberLocalStorage.storageFor)('invite'),
    // services

    session: inject.service('session'),

    branch: inject.service('branch'),

    resetTooltipEvents: inject.service('reset-tooltip-events'),

    // storage

    currentOrganisation: (0, _emberLocalStorage.storageFor)('current-organisation'),

    // attributes

    shouldTransitionToSites: false,

    // methods

    _checkInvite: function _checkInvite() {
      var _this = this;

      try {
        // eslint-disable-next-line no-undef
        $(window).on('branchDataSet', function () {

          // eslint-disable-next-line ember-suave/no-direct-property-access
          _this.get('branch').setInviteToLocalStorage();
        });
        var branchData = JSON.parse(window.sessionStorage.branch_session).data;
        var inviteObj = JSON.parse(branchData).invite;
        if (inviteObj) {
          // eslint-disable-next-line ember-suave/no-direct-property-access
          // this.get('branch').setInviteToLocalStorage();
          this.set('invite.id', inviteObj.id);
          this.set('invite.token', inviteObj.token);
          this.transitionTo('invite');
        } else {
          this.set('shouldTransitionToSites', true);
          this._super.apply(this, arguments);
        }
      } catch (error) {
        this._super.apply(this, arguments);
      }
    },


    // hooks

    sessionAuthenticated: function sessionAuthenticated() {
      this._super.apply(this, arguments);
      this.refresh();
    },
    beforeModel: function beforeModel(transition) {
      if (transition.queryParams['organisation-id']) {
        this.set('currentOrganisation.id', transition.queryParams['organisation-id']);
      }
    },
    model: function model() {
      var organisationId = this.get('currentOrganisation.id');

      return RSVP.hash({
        currentUser: this.get('session.currentUser'),
        currentEmployments: organisationId && this.get('session.isLoggedIn') ? this.get('store').query('employment', {}) : null
      });
    },
    afterModel: function afterModel(model) {
      var _this2 = this;

      var organisationId = this.get('currentOrganisation.id');
      model.currentEmployment = model.currentEmployments ? model.currentEmployments.findBy('organisation.id', organisationId) : null;

      return new RSVP.Promise(function (resolve) {
        model.currentUser.get('organisations').then(function (currentUsersOrganisations) {
          model.currentUsersOrganisations = currentUsersOrganisations;
          model.currentOrganisation = organisationId ? currentUsersOrganisations.findBy('id', organisationId) : null;
          if (_this2.get('shouldTransitionToSites')) {
            // transition only after currentOrganisation is set
            _this2.transitionTo('sites', { queryParams: { query: '' } });
            _this2.set('shouldTransitionToSites', false);
          } else {
            // eslint-disable-next-line ember-suave/no-direct-property-access
            _this2._checkInvite();
          }

          resolve();
        });
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      if (controller.get('shouldShowSwitchAccountModal')) {
        controller.set('displaySwitchAccountModal', true);
      }
    },


    actions: {
      logout: function logout() {
        window.localStorage.removeItem('invite');
        window.localStorage.clear();
        this.get('branch').clearInvite();
        this.get('session').invalidate();
        this.get('branch').clearInvite();
      },
      setCurrentOrganisation: function setCurrentOrganisation(organisationId) {
        this.set('currentOrganisation.id', organisationId);
        this.controller.send('closeSwitchAccountModal');
        this.set('shouldTransitionToSites', true);
        this.refresh();
      },
      resetTooltipEvents: function resetTooltipEvents() {
        var _this3 = this;

        this.get('resetTooltipEvents').reset().then(function () {
          _this3.showSuccessFlash('Tooltips successfully reset');
        });
      }
    }

  });
});