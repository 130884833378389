define('clever-sparky-frontend/routes/base-routes/faulty-fittings-tab', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route;
  exports.default = Route.extend({
    model: function model() {
      return this.modelFor('site.faults.show');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('fitting', model.fitting);
    }
  });
});