define('clever-sparky-frontend/validators/forgot-password-validator', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ForgotPasswordValidations = undefined;
  var ForgotPasswordValidations = exports.ForgotPasswordValidations = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please enter your email address',
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email',
      message: 'Please enter a valid email address'
    })]
  });

  exports.default = { ForgotPasswordValidations: ForgotPasswordValidations };
});