define('clever-sparky-frontend/util/s3-signed-url', ['exports', '../config', './s3-client'], function (exports, config, client) {
  'use strict';

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.getUrl = function () {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(fileName, fileType) {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              return _context.abrupt('return', new Promise(function (resolve, reject) {
                var bucket = config.s3Options.bucket;
                var params = {
                  Bucket: bucket,
                  Key: fileName,
                  Expires: 60,
                  ContentType: fileType,
                  ACL: 'public-read'
                };

                client.getSignedUrl('putObject', params, function (err, data) {
                  if (err) {
                    reject(err);
                  }
                  var returnData = {
                    signedRequest: data,
                    url: 'https://' + bucket + '.s3.amazonaws.com/' + fileName
                  };
                  resolve(returnData);
                });
              }));

            case 1:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, undefined);
    }));

    return function (_x, _x2) {
      return _ref.apply(this, arguments);
    };
  }();
});