define('clever-sparky-frontend/routes/edit/profile', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'clever-sparky-frontend/mixins/handle-error'], function (exports, _ember, _authenticatedRouteMixin, _handleError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP,
      inject = _ember.default.inject;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _handleError.default, {
    // Services

    wholesalerAuth: inject.service(),

    // Hooks

    model: function model() {
      return RSVP.hash({
        user: this.modelFor('application').currentUser,
        wholesalers: this.modelFor('edit').wholesalers
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, Array.prototype.slice.call(arguments).concat([model]));
      controller.set('user', model.user);
      controller.set('wholesalers', model.wholesalers);
    },


    actions: {
      save: function save(user) {
        var _this = this;

        user.save().then(function () {
          _this.controller.set('isEditing', false);
          _this.showSuccessFlash('Account Updated');
        }).catch(function (reason) {
          user.rollbackAttributes();
          _this.handleRailsError(reason);
        }).finally(function () {
          _this.controller.set('isQueryPending', false);
        });
      },
      deleteAccount: function deleteAccount(user) {
        var _this2 = this;

        this.controller.set('isQueryPending', true);

        user.destroyRecord().then(function () {
          _this2.get('session').invalidate();
          _this2.showSuccessFlash('Account Deleted');
        }).catch(function (reason) {
          _this2.handleRailsError(reason);
        }).finally(function () {
          _this2.controller.set('isQueryPending', false);
        });
      },
      authenticateWholesaler: function authenticateWholesaler(wholesaler, username, password) {
        var credentials = {
          username: username,
          password: password
        };
        return this.get('wholesalerAuth').sendWholesalerAuthRequest(wholesaler, credentials);
      },
      openDeleteAccountModal: function openDeleteAccountModal() {
        this.controller.set('displayDeleteAccountModal', true);
      },
      closeDeleteAccountModal: function closeDeleteAccountModal() {
        this.controller.set('displayDeleteAccountModal', false);
      }
    }
  });
});