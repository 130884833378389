define('clever-sparky-frontend/components/form-validated-textarea', ['exports', 'clever-sparky-frontend/components/validated-textarea'], function (exports, _validatedTextarea) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _validatedTextarea.default.extend({
    classNames: ['form-validated-textarea'],
    layoutName: 'components/validated-textarea'
  });
});