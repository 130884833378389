define('clever-sparky-frontend/components/forms/quick-test-defaults-form', ['exports', 'ember', 'clever-sparky-frontend/mixins/option-lookup', 'clever-sparky-frontend/validators/forms/quick-test-defaults', 'clever-sparky-frontend/mixins/toggle-label-focus', 'moment'], function (exports, _ember, _optionLookup, _quickTestDefaults, _toggleLabelFocus, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend(_optionLookup.default, _quickTestDefaults.QuickTestDefaultsValidations, _toggleLabelFocus.default, {
    // Attributes
    defaultLabel: '',

    defaultTestDate: '',

    defaultRunTime: '',

    nexttestdate: '',

    viewb: '',

    defaultRequiredDuration: '',

    didValidate: false,

    testEvent: null,

    isCreatingTestEvent: false,

    updatenexttestdate: '',

    updatenexttestdatefunc: function () {
      var testdate = this.get('defaultTestDate');
      var newval = (0, _moment.default)(testdate).add(182, 'day').format('YYYY-MM-DD');
      if (newval != 'Invalid date') {
        this.set('nexttestdate', newval);
        this.set('updatenexttestdate', newval);
      }
    }.observes('defaultTestDate'),

    // Properties
    canSubmit: computed.alias('validations.isValid'),

    // Hooks
    init: function init() {
      this._super.apply(this, arguments);
      this.setStaticAttributes();
    },
    didInsertElement: function didInsertElement() {
      this.toggleLabelFocus(this.$('#quick-test-defaults-form'));
    },


    // Methods
    setStaticAttributes: function setStaticAttributes() {

      var test = this.get('testEvent');

      this.set('defaultLabel', this.get('testEvent.label'));
      this.set('defaultTestDate', this.get('testEvent.defaultTestDate'));
      this.set('defaultRunTime', this.get('testEvent.defaultRunTime'));
      this.set('defaultRequiredDuration', this.get('testEvent.defaultRequiredDuration'));
      this.set('viewb', this.get('testEvent.viewb'));

      this.set('nexttestdate', test.data.nexttestdate);
      this.set('updatenexttestdate', test.data.nexttestdate);
    },
    updateTestEvent: function updateTestEvent() {
      this.set('testEvent.label', this.get('defaultLabel'));
      this.set('testEvent.defaultTestDate', this.get('defaultTestDate'));
      this.set('testEvent.defaultRunTime', this.get('defaultRunTime'));
      this.set('testEvent.defaultRequiredDuration', this.get('defaultRequiredDuration'));
      this.set('testEvent.nexttestdate', this.get('updatenexttestdate'));
      this.set('testEvent.viewb', this.get('viewb'));
    },
    submit: function submit() {},


    actions: {
      submit: function submit() {
        this.set('didValidate', true);
        if (this.get('canSubmit')) {
          this.updateTestEvent();
          this.attrs.submit(this.get('testEvent'));
        }
      }
    }

  });
});