define('clever-sparky-frontend/services/tours/view-fitting', ['exports', 'ember', 'clever-sparky-frontend/services/tours/base'], function (exports, _ember, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed;
  exports.default = _base.default.extend({

    // Attributes

    namePrefix: 'view-fitting',

    // sequence elements

    sequenceState: [{
      name: 'click-fitting-action',
      hasRendered: false
    }],

    sequenceElements: computed(function () {
      return [{
        name: 'click-fitting-action',
        content: [this.get('i18n').t('tour.view-fitting.click-fitting-action')]
      }];
    })

  });
});