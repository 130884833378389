define('clever-sparky-frontend/routes/invite', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'clever-sparky-frontend/mixins/handle-error'], function (exports, _ember, _authenticatedRouteMixin, _handleError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP,
      inject = _ember.default.inject,
      $ = _ember.default.$;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _handleError.default, {
    inviteAccept: inject.service('inviteAccept'),

    inviteAccepttransfer: inject.service('inviteAccepttransfer'),

    branch: inject.service('branch'),

    previousTransition: null,
    sitetransfer: false,
    sitename: null,

    tmpinviteid: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      // this.get('branch').clearInvite();
      $(window).on('branchDataSet', function () {
        _this.get('branch').setInviteToLocalStorage();
      });
    },


    // eslint-disable-next-line no-unused-vars
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      this.set('sitename', this.get('branch').getInvite().get('sitename'));

      // let fromuser = this.get('branch').getInvite().get('sitetransfertouser');
      // let token = this.get('branch').getInvite().get('token');
      // // if (!this.get('branch').getInvite().get('token') && fromuser == null) {
      // //   transition.abort();
      // //   this.set('previousTransition', transition);
      // // }
    },
    model: function model() {
      var inviteObj = this.get('branch').getInvite();
      this.set('tmpinviteid', inviteObj.get('id'));
      var invitedb = this.store.peekRecord('invite', inviteObj.get('id'));
      try {
        if (inviteObj) {
          if (!invitedb) {
            if (inviteObj.get('sitetransfertouser')) {
              this.set('sitetransfer', true);
              return RSVP.hash({
                invite: this.store.query('invite', inviteObj.get('id')),
                currentUsersAdminOrganisations: this.store.query('organisation', {
                  filter: {
                    role: 'owner,admin'
                  }
                })
              });
            } else {
              this.set('sitetransfer', false);
              return RSVP.hash({
                invite: this.store.findRecord('invite', inviteObj.get('id'))
              });
            }
          } else {
            // eslint-disable-next-line no-empty
            if (invitedb.status === 'accepted' && invitedb.id === inviteObj.get('id')) {} else {
              if (inviteObj.get('sitetransfertouser')) {

                this.set('sitetransfer', true);
                return RSVP.hash({

                  invite: this.store.query('invite', inviteObj.get('id')),
                  currentUsersAdminOrganisations: this.store.query('organisation', {
                    filter: {
                      role: 'owner,admin'
                    }
                  })
                });
              } else {
                this.set('sitetransfer', false);
                return RSVP.hash({

                  invite: this.store.findRecord('invite', inviteObj.get('id'))

                });
              }
            }
          }
        }
        // eslint-disable-next-line no-empty
      } catch (err) {}
    },
    afterModel: function afterModel(model) {
      var _this2 = this;

      return new RSVP.Promise(function (resolve) {

        if (_this2.get('sitetransfer')) {
          model.sitename = _this2.get('sitename');
          model.id = _this2.get('tmpinviteid');
          model.data = model.invite.content[0]._data;
          resolve();
        } else {
          model.invite.get('organisation').then(function (organisation) {

            model.organisation = organisation;
            resolve();
          });
        }
      });
    },


    actions: {
      error: function error() {
        // called when an error occurs in model query
        // this.handleRailsError([{ detail: 'Your invite has been cancelled. Contact your admin to arrange a new invite.' }]);
        //  this.get('branch').clearInvite();
        this.replaceWith('sites');
      },
      accept: function accept() {
        var _this3 = this;

        this.controller.set('isQueryPending', true);
        var inviteObj = this.get('branch').getInvite();
        var _currentModel = this.currentModel,
            invite = _currentModel.invite,
            organisation = _currentModel.organisation;


        this.store.findRecord('invite', invite.id).then(function (invite) {
          invite.status = 'accepted';
        });

        this.get('inviteAccept').accept(invite, inviteObj.get('token')).then(function () {
          // setCurrentOrganisation is defined in the application route

          _this3.get('branch').clearInvite();
          _this3.send('setCurrentOrganisation', organisation.get('id'));
        }).catch(function (reason) {
          _this3.handleRailsError(reason);
        }).finally(function () {
          _this3.controller.set('isQueryPending', false);
        });
      },
      acceptTransfer: function acceptTransfer(site, newOwner) {
        var _this4 = this;

        var newOwnerId = newOwner ? newOwner : this.currentModel.currentUser.get('id');

        this.controller.set('isQueryPending', true);

        var invite = this.currentModel.invite;

        var id = this.get('tmpinviteid');

        this.get('inviteAccepttransfer').accepttransfer(invite, id, newOwnerId).then(function () {
          // setCurrentOrganisation is defined in the application route
          _this4.get('branch').clearInvite();
          _this4.send('setCurrentOrganisation', newOwner);
          _this4.skip();
        }).catch(function () {}).finally(function () {
          _this4.controller.set('isQueryPending', false);
        });
      },
      skip: function skip() {
        this.get('branch').clearInvite();
        this.transitionTo('sites');
      }
    }

  });
});