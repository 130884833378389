define('clever-sparky-frontend/components/test-event-table', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend({

    testEvents: [],

    selectedTestEvents: [],
    count: '0',

    isDeletingTestEvents: false,

    hasNoRecords: computed.equal('testEvents.length', 0),

    noTestEventsSelected: computed.equal('selectedTestEvents.length', 0),

    actions: {
      toggleDeleteTestEvents: function toggleDeleteTestEvents() {
        this.toggleProperty('isDeletingTestEvents');
        this.set('selectedTestEvents', []);
      },
      showTestEvent: function showTestEvent(testEvent) {
        if (this.get('count') == '0') {
          this.attrs.showTestEvent(testEvent);
          this.set('count', '1');
        }
      },
      deleteTestEvents: function deleteTestEvents(selectedTestEvents) {
        this.attrs.batchDeleteTestEvents(selectedTestEvents);
      }
    }

  });
});