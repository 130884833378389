define('clever-sparky-frontend/services/invite-accept', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = _ember.default.Service,
      inject = _ember.default.inject,
      ENV = _ember.default.ENV;
  exports.default = Service.extend({
    requestService: inject.service('request-service'),

    _acceptMethod: 'POST',

    _inviteAcceptURL: function _inviteAcceptURL(inviteId) {
      return ENV.api.host + '/' + ENV.api.namespace + '/invites/' + inviteId + '/accept';
    },
    accept: function accept(invite, token) {
      var url = this._inviteAcceptURL(invite.get('id'));
      var method = this.get('_acceptMethod');
      var data = {
        data: {
          type: 'invites',
          attributes: {
            token: token
          }
        }
      };

      return this.get('requestService').sendAuthenticatedRequest(url, method, data);
    }
  });
});