define('clever-sparky-frontend/routes/reset-password', ['exports', 'ember', 'clever-sparky-frontend/mixins/handle-error'], function (exports, _ember, _handleError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      inject = _ember.default.inject;
  exports.default = Route.extend(_handleError.default, {
    resetPassword: inject.service('resetPassword'),

    actions: {
      resetPassword: function resetPassword(token, password, passwordConfirmation) {
        var _this = this;

        this.get('resetPassword').resetPassword(token, password, passwordConfirmation).then(function () {
          _this.transitionTo('reset-password.success');
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        }).finally(function () {
          _this.controllerFor('reset-password.index').set('isQueryPending', false);
        });
      }
    }
  });
});