define('clever-sparky-frontend/components/quick-test-modal', ['exports', 'ember', 'ember-macaroni'], function (exports, _ember, _emberMacaroni) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend({
    // Attributes
    isQuickTestPassModal: false,

    // Properties
    headingText: (0, _emberMacaroni.ifThenElseWithValues)('isQuickTestPassModal', 'Mark Selected Fittings as Pass', 'Mark Selected Fittings as Fail'),

    isQuickTestPassForm: computed.alias('isQuickTestPassModal'),

    // Methods
    closeModal: function closeModal() {},
    save: function save() {},


    actions: {
      save: function save(testAttributes) {
        this.attrs.save(testAttributes);
      }
    }
  });
});