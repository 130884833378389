define('clever-sparky-frontend/adapters/application', ['exports', 'ember-data', 'ember', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberData, _ember, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ENV = _ember.default.ENV;
  var JSONAPIAdapter = _emberData.default.JSONAPIAdapter;
  exports.default = JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    authorizer: 'authorizer:cleversparky',

    host: ENV.api.host,
    namespace: ENV.api.namespace,

    ajaxOptions: function ajaxOptions() {
      var _this = this;

      var authorizer = this.get('authorizer');
      var hash = this._super.apply(this, arguments);

      hash.beforeSend = function (xhr) {
        _this.get('session').authorize(authorizer, function (identification, token) {
          xhr.setRequestHeader('Content-Type', 'application/json');
          xhr.setRequestHeader('X-Session-id', identification);
          xhr.setRequestHeader('X-Session-Token', token);
        });
      };
      return hash;
    }
  });
});