define('clever-sparky-frontend/services/holdvaribles', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  exports.default = Service.extend({
    CurrentPhotoUploadSignatureBool: undefined,
    CurrentPhotoUploadDeleteBool: undefined,
    CurrentViewsetting: undefined,
    CurrentTestEvent: undefined,
    CurrentGroup: undefined,
    allfittingpagebool: undefined,
    alltestevents: undefined,
    CurrentSite: undefined,
    CurrentTemplateSet: undefined,
    setupCurrentPhotoUploadSignatureBool: function setupCurrentPhotoUploadSignatureBool(trueorfalse) {
      this.set('CurrentPhotoUploadSignatureBool', trueorfalse);
    },
    setupCurrentPhotoUploadDeleteBool: function setupCurrentPhotoUploadDeleteBool(trueorfalse) {
      this.set('CurrentPhotoUploadDeleteBool', trueorfalse);
    },
    setupCurrentViewsetting: function setupCurrentViewsetting(grouporallfitting) {
      this.set('CurrentViewsetting', grouporallfitting);
    },
    setupCurrentTestEvent: function setupCurrentTestEvent(testevent) {
      this.set('CurrentTestEvent', testevent);
    },
    setupHoldSelectedFittingGroup: function setupHoldSelectedFittingGroup(groupid) {
      this.set('CurrentGroup', groupid);
    },
    setupallfittingpagebool: function setupallfittingpagebool(allfittingbool) {
      this.set('allfittingpagebool', allfittingbool);
    },
    setupCurrentSite: function setupCurrentSite(CurrentSitex) {
      this.set('CurrentSite', CurrentSitex);
    },
    setupAllTestEvents: function setupAllTestEvents(testevents) {
      this.set('alltestevents', testevents);
    },
    setupHoldTemplates: function setupHoldTemplates(template) {
      this.set('CurrentTemplateSet', template);
    },
    showCurrentPhotoUploadSignatureBool: function showCurrentPhotoUploadSignatureBool() {
      return this.get('CurrentPhotoUploadSignatureBool');
    },
    showCurrentPhotoUploadDeleteBool: function showCurrentPhotoUploadDeleteBool() {
      return this.get('CurrentPhotoUploadDeleteBool');
    },
    showCurrentViewsetting: function showCurrentViewsetting() {
      return this.get('CurrentViewsetting');
    },
    showCurrentTestEvent: function showCurrentTestEvent() {
      return this.get('CurrentTestEvent');
    },
    showHoldSelectedFittingGroup: function showHoldSelectedFittingGroup() {
      return this.get('CurrentGroup');
    },
    showallfittingpagebool: function showallfittingpagebool() {
      return this.get('allfittingpagebool');
    },
    showCurrentSite: function showCurrentSite() {
      return this.get('CurrentSite');
    },
    showAlltestevents: function showAlltestevents() {
      return this.get('alltestevents');
    },
    showAllTemplates: function showAllTemplates() {
      return this.get('CurrentTemplateSet');
    }
  });
});