define('clever-sparky-frontend/models/ledtest', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    // Relationships
    fitting: belongsTo('fitting'),

    testEvent: belongsTo('test-event'),

    // Attributes
    testStatus: attr('string'),

    testDate: attr('string'),

    runTime: attr('number'),

    nextTestDate: attr('string'),

    requiredDuration: attr('number')
  });
});