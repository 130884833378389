define('clever-sparky-frontend/adapters/group', ['exports', 'ember', 'clever-sparky-frontend/adapters/application'], function (exports, _ember, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ENV = _ember.default.ENV;
  exports.default = _application.default.extend({
    urlForQuery: function urlForQuery(query) {
      var url = '';
      if (query.testEvent) {
        url = ENV.api.host + '/' + ENV.api.namespace + '/test-events/' + query.testEvent + '/missing-groups';
      } else if (query.order) {
        url = ENV.api.host + '/' + ENV.api.namespace + '/orders/' + query.order + '/groups';
      } else {
        url = ENV.api.host + '/' + ENV.api.namespace + '/sites/' + query.site + '/groups';
      }
      return url;
    }
  });
});