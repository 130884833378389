define('clever-sparky-frontend/components/date-picker', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component;
  exports.default = Component.extend({

    classNames: ['date-picker'],

    targetToAttach: null,

    isOpen: false,

    maxDate: null,

    minDate: null,

    date: null,

    isShowingDays: true,

    calendarAttachmentPosition: 'top center',

    targetAttachmentPosition: 'bottom center',

    monthGrid: [[{ label: 'JAN', value: 0 }, { label: 'FEB', value: 1 }, { label: 'MAR', value: 2 }], [{ label: 'APR', value: 3 }, { label: 'MAY', value: 4 }, { label: 'JUN', value: 5 }], [{ label: 'JUL', value: 6 }, { label: 'AUG', value: 7 }, { label: 'SEP', value: 8 }], [{ label: 'OCT', value: 9 }, { label: 'NOV', value: 10 }, { label: 'DEC', value: 11 }]],

    didInsertElement: function didInsertElement() {
      var _this = this;

      var $input = this.$('.date-picker-input');
      this.set('targetToAttach', $input);

      $input.on('focusin', function () {
        _this.send('open');
      });
    },


    actions: {
      showMonths: function showMonths() {
        this.set('isShowingDays', false);
      },
      open: function open() {
        this.set('isOpen', true);
        if (this.get('value')) {
          this.set('center', this.get('value'));
        } else {
          this.set('center', (0, _moment.default)());
        }
      },
      dateSelected: function dateSelected(e) {
        this.set('date', e.moment);
        this.set('value', e.moment.format('YYYY-MM-DD'));

        this.send('close');
      },
      monthSelected: function monthSelected(calendar, center, monthObj) {
        calendar.actions.changeCenter((0, _moment.default)(center).month(monthObj.value), calendar);
        this.set('isShowingDays', true);
      },
      close: function close() {
        this.set('isOpen', false);
        this.set('isShowingDays', true);
      }
    }

  });
});