define('clever-sparky-frontend/validators/forms/maintenance', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.MaintenanceValidations = undefined;
  var MaintenanceValidations = exports.MaintenanceValidations = (0, _emberCpValidations.buildValidations)({
    repairDate: (0, _emberCpValidations.validator)('presence', true),
    repair: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
      in: ['battery', 'fitting', 'control_gear', 'lamp', 'other'],
      message: 'Repair can be one of either Replaced battery, Replaced fitting, Replaced control gear, Replaced lamp or Other'
    })]
  });

  exports.default = { MaintenanceValidations: MaintenanceValidations };
});