define('clever-sparky-frontend/routes/site/fittings', ['exports', 'ember', 'clever-sparky-frontend/mixins/handle-error', 'ember-infinity/mixins/route', 'clever-sparky-frontend/mixins/setup-tour-sequence', 'ember-local-storage'], function (exports, _ember, _handleError, _route, _setupTourSequence, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP,
      inject = _ember.default.inject;
  exports.default = Route.extend(_route.default, _handleError.default, _setupTourSequence.default, {
    // Services
    holdvaribles: inject.service('holdvaribles'),
    batchDeleteFittings: inject.service('batchDeleteFittings'),
    // localstorage
    sitetemp: (0, _emberLocalStorage.storageFor)('site'),
    batchMoveFittings: inject.service('batchMoveFittings'),

    viewGroupTour: inject.service('tours/view-group'),

    viewFittingTour: inject.service('tours/view-fitting'),

    features: inject.service(),

    batchDeleteOrderItems: inject.service(),

    // Attributes

    perPageParam: 'page[size]', // instead of 'per_page'

    pageParam: 'page[number]', // instead of 'page'

    totalPagesParam: 'meta.total-pages', // instead of "meta.total_pages"

    // Route Attributes

    queryParams: {
      commissioned: {
        refreshModel: true
      }
    },

    // Hooks

    beforeModel: function beforeModel() {
      if (this.get('features').isEnabled('guidedTour')) {
        return RSVP.hash({
          viewGroupTooltipEvent: this.setupTourSequence('view_group_web', this.get('viewGroupTour')),
          viewFittingTooltipEvent: this.setupTourSequence('view_fitting_web', this.get('viewFittingTour'))
        });
      }
    },
    model: function model(params) {

      this.get('holdvaribles').setupallfittingpagebool(true);

      var _modelFor = this.modelFor('site'),
          site = _modelFor.site,
          group = _modelFor.group;

      this.get('holdvaribles').setupCurrentSite(site);
      this.set('sitetemp.site', site);

      var allfittingsb = 'allfittings';
      var siteid = site.id;

      var filter = {};
      if (params.hasOwnProperty('commissioned')) {
        filter.commissioned = params.commissioned;
      }
      return RSVP.hash({

        fittings: this.infinityModel('fitting', {
          allfittingsb: allfittingsb,
          perPage: 10,
          siteid: siteid,
          startingPage: 1,
          filter: filter,
          modelPath: 'controller.model.fittings'
        }),
        currentFilterState: false,
        fitting: this.store.createRecord('fitting', {
          group: group
        })
      });
    },
    afterModel: function afterModel(model) {

      return new RSVP.Promise(function (resolve) {

        model.data = model.fittings.links.size;
        resolve();
      });
    },


    actions: {
      addFitting: function addFitting(fitting) {
        var _this = this;

        fitting.save('hllo').then(function () {
          _this.controller.send('toggleAddFitting');
          _this.showSuccessFlash('Fitting Added');
          // refresh the top most parent route
          _this.send('refreshRoute');
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        }).finally(function () {
          _this.controller.set('isQueryPending', false);
        });
      },
      confirmBatchDeleteFittings: function confirmBatchDeleteFittings(selectedFittings) {
        var _this2 = this;

        this.get('batchDeleteFittings').deleteBatch(selectedFittings).then(function () {
          _this2.controller.send('closeBatchConfirmationModal');
          _this2.showSuccessFlash('Fittings Deleted');
          _this2.transitionTo('groups.fittings');
          // refresh the top most parent route
          _this2.send('refreshRoute');
        }).catch(function (reason) {
          _this2.handleRailsError(reason);
        }).finally(function () {
          _this2.controller.set('isQueryPending', false);
        });
      },
      confirmBatchMoveFittings: function confirmBatchMoveFittings(selectedFittings, groupId) {
        var _this3 = this;

        this.get('batchMoveFittings').moveBatch(selectedFittings, groupId).then(function () {
          _this3.controller.send('closeBatchConfirmationModal');
          _this3.showSuccessFlash('Fittings Moved');
          // refresh the top most parent route
          _this3.send('refreshRoute');
        }).catch(function (reason) {
          _this3.handleRailsError(reason);
        }).finally(function () {
          _this3.controller.set('isQueryPending', false);
        });
      },
      showFittingDetails: function showFittingDetails(fitting) {
        this.transitionTo('site.fittings.show.details', fitting.id);
      },
      createOrderItem: function createOrderItem(fitting, partType) {
        var _this4 = this;

        var orderItem = this.store.createRecord('order-item', {
          partType: partType,
          fitting: fitting
        });
        orderItem.save().then(function () {
          _this4.currentModel.site.reload();
        }).catch(function (reason) {
          _this4.handleRailsError(reason);
        });
      },
      deleteOrderItem: function deleteOrderItem(fitting, partType) {
        var _this5 = this;

        var batch = [{
          partType: partType,
          fitting: fitting
        }];
        this.get('batchDeleteOrderItems').deleteBatch(batch).then(function () {
          _this5.currentModel.site.reload();
        }).catch(function (reason) {
          _this5.handleRailsError(reason);
        });
      }
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.controller.set('commissioned', false);
      }
    }
  });
});