define('clever-sparky-frontend/validators/array-length', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ArrayLength = _base.default.extend({
    validate: function validate(value, options) {
      if (value.length >= options.min) {
        return true;
      } else {
        return options.message;
      }
    }
  });

  ArrayLength.reopenClass({
    getDependentsFor: function getDependentsFor() /* attribute, options */{
      return [];
    }
  });

  exports.default = ArrayLength;
});