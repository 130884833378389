define('clever-sparky-frontend/models/test-event', ['exports', 'ember', 'ember-data', 'ember-api-actions'], function (exports, _ember, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    // Relationships
    site: belongsTo('site', { async: true }),

    testEventGroups: hasMany('test-event-group'),

    // Attributes
    label: attr('string'),

    startDate: attr('string'),

    endDate: attr('string'),

    groupsCount: attr('number'),

    archived: attr('boolean'),

    defaultTestDate: attr('string'),

    defaultRunTime: attr('number'),

    defaultRequiredDuration: attr('number'),

    nexttestdate: attr('string'),

    viewb: attr('string'),

    // Properties
    hasGroups: computed.gte('groupsCount', 1),

    canDelete: computed.not('hasGroups'),

    // actions
    duplicate: (0, _emberApiActions.memberAction)({
      path: 'duplicate',
      type: 'post'
    }),

    archive: (0, _emberApiActions.memberAction)({
      path: 'archive',
      type: 'post'
    })

  });
});