define('clever-sparky-frontend/components/quick-test-defaults-modal', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component;
  exports.default = Component.extend({

    // Attributes
    testEvent: null,

    isQueryPending: false,

    // Methods
    save: function save() {},


    actions: {
      submitQuickTestDefaults: function submitQuickTestDefaults(testEvent) {
        this.set('isQueryPending', true);
        this.attrs.save(testEvent);
      },
      closeModal: function closeModal() {
        this.sendAction('closeModal');
      }
    }
  });
});