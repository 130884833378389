define('clever-sparky-frontend/controllers/site/groups', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      computed = _ember.default.computed;
  exports.default = Controller.extend({

    groups: computed.alias('model.groups'),

    group: computed.alias('model.group'),

    isDeletingGroups: false,

    selectedGroups: [],

    displayAddGroupModal: false,

    displayBatchErrorModal: false,

    displayBatchConfirmationModal: false,

    batchLength: computed.alias('selectedGroups.length'),

    actions: {
      showGroupDetails: function showGroupDetails(group) {
        this.transitionToRoute('groups', group.id);
      },
      toggleAddGroup: function toggleAddGroup() {
        this.toggleProperty('displayAddGroupModal');
      },
      closeBatchErrorModal: function closeBatchErrorModal() {
        this.set('displayBatchErrorModal', false);
      },
      closeBatchConfirmationModal: function closeBatchConfirmationModal() {
        this.set('displayBatchConfirmationModal', false);
        this.set('selectedGroups', []);
        this.set('isDeletingGroups', false);
      },
      batchDeleteGroups: function batchDeleteGroups(selectedGroups) {
        var canDeleteGroups = selectedGroups.reduce(function (acc, group) {
          return acc && group.get('canDelete');
        }, true);

        if (canDeleteGroups) {
          this.set('displayBatchConfirmationModal', true);
        } else {
          this.set('displayBatchErrorModal', true);
        }
      }
    }

  });
});