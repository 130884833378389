define('clever-sparky-frontend/services/batch-delete-order-items', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = _ember.default.Service,
      inject = _ember.default.inject,
      ENV = _ember.default.ENV;
  exports.default = Service.extend({
    requestService: inject.service('request-service'),

    _orderItemDeleteURL: ENV.api.host + '/' + ENV.api.namespace + '/order-items',

    _orderItemDeleteMethod: 'DELETE',

    _type: 'order-items',

    deleteBatch: function deleteBatch(batch) {
      var method = this.get('_orderItemDeleteMethod');
      var url = this.get('_orderItemDeleteURL');
      var type = this.get('_type');

      var data = {
        data: []
      };

      batch.forEach(function (item) {
        data.data.push({
          type: type,
          attributes: {
            'part-type': item.partType
          },
          relationships: {
            fitting: {
              data: {
                type: 'fitting',
                id: item.fitting.get('id')
              }
            }
          }
        });
      });

      return this.get('requestService').sendAuthenticatedRequest(url, method, data);
    }
  });
});