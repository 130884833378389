define('clever-sparky-frontend/services/reset-tooltip-events', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = _ember.default.Service,
      ENV = _ember.default.ENV,
      inject = _ember.default.inject;
  exports.default = Service.extend({
    requestService: inject.service('request-service'),

    _resetTooltipEventsMethod: 'POST',

    _resetTooltipEventsURL: function _resetTooltipEventsURL() {
      return ENV.api.host + '/' + ENV.api.namespace + '/users/me/reset-tooltip-events';
    },
    reset: function reset() {
      var url = this._resetTooltipEventsURL();
      var method = this.get('_resetTooltipEventsMethod');
      var data = {};

      return this.get('requestService').sendAuthenticatedRequest(url, method, data);
    }
  });
});