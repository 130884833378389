define('clever-sparky-frontend/routes/signup', ['exports', 'ember', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'clever-sparky-frontend/mixins/handle-error', 'ember-local-storage'], function (exports, _ember, _unauthenticatedRouteMixin, _handleError, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      inject = _ember.default.inject;
  exports.default = Route.extend(_unauthenticatedRouteMixin.default, _handleError.default, {
    session: inject.service(),

    currentOrganisation: (0, _emberLocalStorage.storageFor)('current-organisation'),

    queryParams: {
      token: {
        refreshModel: true
      }
    },

    afterModel: function afterModel() {
      this.transitionTo('signup.terms');
    },


    actions: {
      submit: function submit(user) {
        var _this = this;

        user.save().then(function (user) {
          var identification = user.get('email');
          var password = user.get('password');

          user.clearPassword();

          _this.get('session').authenticate('authenticator:cleversparky', identification, password).then(function () {
            _this.controllerFor('application').set('hasJustSignedUp', true);
          }).catch(function (reason) {
            _this.set('errorMessage', reason.error || reason);
          }).finally(function () {
            _this.controllerFor('signup.user').set('isQueryPending', false);
          });

          if (user.get('organisations').toArray()[0]) {
            _this.set('currentOrganisation.id', user.get('organisations').toArray()[0].get('id'));
          }
        }).catch(function (reason) {
          _this.handleRailsError(reason);
          _this.controllerFor('signup.user').set('isQueryPending', false);
        });
      },
      next: function next() {
        this.transitionTo('signup.user');
      },
      back: function back() {
        var currentRoute = this.controllerFor('application').currentRouteName;

        if (currentRoute === 'signup.terms') {
          this.transitionTo('login');
        } else if (currentRoute === 'signup.user') {
          this.transitionTo('signup.terms');
        }
      }
    }
  });
});