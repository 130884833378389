define('clever-sparky-frontend/components/forms/quick-test-form', ['exports', 'ember', 'moment', 'ember-macaroni', 'clever-sparky-frontend/mixins/option-lookup', 'clever-sparky-frontend/mixins/toggle-label-focus', 'clever-sparky-frontend/validators/forms/ledtest'], function (exports, _ember, _moment, _emberMacaroni, _optionLookup, _toggleLabelFocus, _ledtest) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend(_optionLookup.default, _toggleLabelFocus.default, _ledtest.LEDTestValidations, {
    // Attributes

    nextTestDate: '',

    testDate: '',

    runTime: '',

    requiredDuration: '',

    minDate: (0, _moment.default)(),

    testEvent: null,

    didValidate: false,

    isQueryPending: false,

    isQuickTestPassForm: false,

    // Properties

    inputsDisabled: computed.alias('isQuickTestPassForm'),

    testStatus: (0, _emberMacaroni.ifThenElseWithValues)('isQuickTestPassForm', 'valid_pass', ''),

    testStatusOptions: (0, _emberMacaroni.ifThenElseWithKeys)('isQuickTestPassForm', 'passTestStatusOptions', 'failTestStatusOptions'),

    canSubmit: computed.alias('validations.isValid'),

    // Methods
    setStaticAttributes: function setStaticAttributes() {
      this.set('testDate', this.get('testEvent.defaultTestDate'));
      this.set('runTime', this.get('testEvent.defaultRunTime'));
      this.set('requiredDuration', this.get('testEvent.defaultRequiredDuration'));
    },
    submit: function submit() {},


    // hooks
    init: function init() {
      this._super.apply(this, arguments);
      this.setStaticAttributes();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.toggleLabelFocus(this.$('#quick-test-form'));
    },


    actions: {
      submit: function submit() {
        var testAttributes = {
          testStatus: this.get('testStatus'),
          testDate: this.get('testDate'),
          runTime: this.get('runTime'),
          nextTestDate: this.get('nextTestDate'),
          requiredDuration: this.get('requiredDuration')
        };

        this.set('didValidate', true);
        if (this.get('canSubmit')) {
          this.set('isQueryPending', true);
          this.attrs.submit(testAttributes);
        }
      }
    }
  });
});