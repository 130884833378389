define('clever-sparky-frontend/serializers/fitting', ['exports', 'clever-sparky-frontend/serializers/application', 'ember'], function (exports, _application, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = _ember.default.inject;
  exports.default = _application.default.extend({
    holdvaribles: inject.service('holdvaribles'),
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      if (json.data.relationships && json.data.relationships['latest-test']) {
        delete json.data.relationships['latest-test'];
      }
      if (json.data.relationships != null) {
        if (json.data.relationships.group.data == null) {

          var groupid = this.get('holdvaribles').showHoldSelectedFittingGroup();

          var data = {
            id: groupid,
            type: 'groups'
          };

          json.data.relationships.group.data = data;
          // json.data.relationships.group.data['type'] = 'groups';
        }
      }

      return json;
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {

      if (requestType === 'findRecord') {
        // deleting relationships from included records as they cause ember to go into an inconsistent state
        payload.included.map(function (includeObj) {
          delete includeObj.relationships;
        });
      }
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});