define('clever-sparky-frontend/routes/site-request', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'clever-sparky-frontend/mixins/show-nav-bar', 'clever-sparky-frontend/mixins/handle-error', 'ember-can'], function (exports, _ember, _authenticatedRouteMixin, _showNavBar, _handleError, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _showNavBar.default, _handleError.default, _emberCan.CanMixin, {
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      var _modelFor = this.modelFor('application'),
          currentEmployment = _modelFor.currentEmployment;

      if (!this.can('view user-management', currentEmployment)) {
        this.replaceWith('sites');
      }
    },
    model: function model(params) {
      return RSVP.hash({
        siteRequest: this.get('store').findRecord('access-request', params.access_request_id)
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('siteRequest', model.siteRequest);
    },


    actions: {
      approve: function approve() {
        var _this = this;

        this.currentModel.siteRequest.approve().then(function () {
          _this.showSuccessFlash('Site Access Granted');
          _this.transitionTo('site-requests');
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        }).finally(function () {
          _this.controller.set('isQueryPending', false);
        });
      },
      error: function error() {
        // called when an error occurs in model query
        this.handleRailsError([{ detail: 'Access request has already been actioned' }]);
        this.replaceWith('site-requests');
      },
      decline: function decline() {
        var _this2 = this;

        this.currentModel.siteRequest.destroyRecord().then(function () {
          _this2.showSuccessFlash('Site Access Request Declined');
          _this2.transitionTo('site-requests');
        }).catch(function (reason) {
          _this2.handleRailsError(reason);
        }).finally(function () {
          _this2.controller.set('isQueryPending', false);
        });
      }
    }

  });
});