define('clever-sparky-frontend/models/admin', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  var computed = _ember.default.computed;
  exports.default = Model.extend({

    // Attributes

    firstname: attr('String'),

    lastname: attr('String'),

    email: attr('String'),

    fullName: computed('firstname', 'lastname', function () {
      return this.get('firstname') + ' ' + this.get('lastname');
    })

  });
});