define('clever-sparky-frontend/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var JSONAPISerializer = _emberData.default.JSONAPISerializer;
  exports.default = JSONAPISerializer.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey(key) {
      if (key === 'tests') {
        key = 'led' + key;
      }
      return this._super(key);
    }
  });
});