define('clever-sparky-frontend/validators/forms/quick-test-defaults', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.QuickTestDefaultsValidations = undefined;
  var QuickTestDefaultsValidations = exports.QuickTestDefaultsValidations = (0, _emberCpValidations.buildValidations)({
    defaultTestDate: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please enter a date',
      ignoreBlank: true
    }),
    defaultRunTime: (0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true,
      gte: 0,
      lte: 254
    })
  });

  exports.default = { QuickTestDefaultsValidations: QuickTestDefaultsValidations };
});