define('clever-sparky-frontend/routes/forgot-password', ['exports', 'ember', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'clever-sparky-frontend/mixins/handle-error'], function (exports, _ember, _unauthenticatedRouteMixin, _handleError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      inject = _ember.default.inject;
  exports.default = Route.extend(_unauthenticatedRouteMixin.default, _handleError.default, {
    resetService: inject.service('request-password-reset'),

    actions: {
      requestPasswordReset: function requestPasswordReset(email) {
        var _this = this;

        this.get('resetService').requestPasswordReset(email).then(function () {
          _this.transitionTo('forgot-password.success', {
            queryParams: {
              email: email
            }
          });
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        }).finally(function () {
          _this.controllerFor('forgot-password.index').set('isQueryPending', false);
        });
      }
    }
  });
});