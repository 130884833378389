define('clever-sparky-frontend/validators/forms/test-event', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.TestEventValidations = undefined;
  var computed = _ember.default.computed;
  var TestEventValidations = exports.TestEventValidations = (0, _emberCpValidations.buildValidations)({
    startDate: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please enter a start date',
      ignoreBlank: true
    }),

    endDate: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please enter an end date',
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('date', {
      after: computed.alias('model.startDate'),
      precision: 'day',
      format: 'YYY-M-D',
      message: 'End date must be after start date'
    })]
  });

  exports.default = { TestEventValidations: TestEventValidations };
});