define('clever-sparky-frontend/services/batch-move', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = _ember.default.Service,
      inject = _ember.default.inject;
  exports.default = Service.extend({
    requestService: inject.service('request-service'),

    _groupDeleteMethod: 'POST',

    moveBatch: function moveBatch(batch, groupId) {
      var url = this._groupDeleteURL(groupId);
      var method = this.get('_groupDeleteMethod');
      var type = this.get('_type');

      var data = {
        data: []
      };

      batch.forEach(function (item) {
        data.data.push({ type: type, id: item.id });
      });

      return this.get('requestService').sendAuthenticatedRequest(url, method, data);
    }
  });
});