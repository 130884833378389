define('clever-sparky-frontend/services/batch-delete-groups', ['exports', 'ember', 'clever-sparky-frontend/services/batch-delete'], function (exports, _ember, _batchDelete) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ENV = _ember.default.ENV;
  exports.default = _batchDelete.default.extend({
    _groupDeleteURL: ENV.api.host + '/' + ENV.api.namespace + '/groups',

    _type: 'groups'
  });
});