define('clever-sparky-frontend/controllers/sites/new', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      computed = _ember.default.computed;
  exports.default = Controller.extend({
    site: computed.alias('model.site')
  });
});