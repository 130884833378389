define('clever-sparky-frontend/components/test-event-group-dropdown-menu', ['exports', 'ember', 'clever-sparky-frontend/components/dropdown-menu'], function (exports, _ember, _dropdownMenu) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed;
  exports.default = _dropdownMenu.default.extend({

    clickOutsideParentElementSelector: '.test-event-group-dropdown-menu',

    classNames: ['dropdown-menu', 'test-event-group-dropdown-menu'],

    hasTestEventGroups: computed.bool('testEventGroups.length'),

    hasNoGroupsToRemove: computed.not('hasTestEventGroups'),

    hasMissingGroups: computed.bool('missingGroups.length'),

    hasNoGroupsToAdd: computed.not('hasMissingGroups'),

    testEventDropdownMenuTourNames: ['testEventDropdownMenu'],

    actions: {
      showAddGroupsModal: function showAddGroupsModal() {
        this.attrs.showAddGroupsModal();
        this.send('close');
      },
      showRemoveGroupsModal: function showRemoveGroupsModal() {
        this.attrs.showRemoveGroupsModal();
        this.send('close');
      },
      showEditDateRangeModal: function showEditDateRangeModal() {
        this.attrs.showEditDateRangeModal();
        this.send('close');
      },
      showDuplicateTestEventModal: function showDuplicateTestEventModal() {
        this.attrs.showDuplicateTestEventModal();
        this.send('close');
      },
      showArchiveConfirmationModal: function showArchiveConfirmationModal() {
        this.attrs.showArchiveConfirmationModal();
        this.send('close');
      },
      showQuickTestDefaultsModal: function showQuickTestDefaultsModal() {
        this.attrs.showQuickTestDefaultsModal();
        this.send('close');
      }
    }
  });
});