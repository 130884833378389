define('clever-sparky-frontend/components/modal-view', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component;
  exports.default = Component.extend({

    classNames: ['modal-view'],

    closeOnOverlayClick: false,

    actions: {
      closeModal: function closeModal() {
        if (this.get('closeOnOverlayClick')) {
          this.attrs.closeModal();
        }
      }
    }
  });
});