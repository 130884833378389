define('clever-sparky-frontend/routes/site/fittings/show', ['exports', 'ember', 'clever-sparky-frontend/routes/base-routes/show', 'clever-sparky-frontend/mixins/handle-error', 'clever-sparky-frontend/mixins/setup-tour-sequence'], function (exports, _ember, _show, _handleError, _setupTourSequence) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = _ember.default.RSVP,
      inject = _ember.default.inject;
  exports.default = _show.default.extend(_handleError.default, _setupTourSequence.default, {

    // Services

    viewFittingPanelTour: inject.service('tours/view-fitting-panel'),

    features: inject.service(),

    // Hooks

    beforeModel: function beforeModel() {
      if (this.get('features').isEnabled('guidedTour')) {
        return RSVP.hash({
          viewFittingPanelTooltipEvent: this.setupTourSequence('view_fitting_panel_web', this.get('viewFittingPanelTour'))
        });
      }
    },


    actions: {
      returnToFittings: function returnToFittings() {
        this.controller.set('showDeleteModal', false);
        this.transitionTo('site.fittings');
      },
      deleteFitting: function deleteFitting(fitting) {
        var _this = this;

        fitting.destroyRecord().then(function () {
          fitting.unloadRecord();
          _this.showSuccessFlash('Fitting Deleted');
          // refresh the top most parent route
          _this.send('refreshRoute');
          _this.send('returnToFittings');
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        }).finally(function () {
          _this.controller.set('isQueryPending', false);
        });
      }
    }

  });
});