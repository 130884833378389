define('clever-sparky-frontend/components/user-panel', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component;
  exports.default = Component.extend({

    actions: {
      deleteUser: function deleteUser() {
        this.sendAction('deleteUser');
      },
      updatePurchasingRights: function updatePurchasingRights(employment) {
        this.attrs.updatePurchasingRights(employment);
      }
    }

  });
});