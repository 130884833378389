define('clever-sparky-frontend/adapters/test-event-fitting', ['exports', 'ember', 'clever-sparky-frontend/adapters/application'], function (exports, _ember, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ENV = _ember.default.ENV;
  exports.default = _application.default.extend({
    urlForFindRecord: function urlForFindRecord() {
      return this._super.apply(this, arguments) + '?include=latest-test,maintenances';
    },
    urlForQuery: function urlForQuery(query) {
      return ENV.api.host + '/' + ENV.api.namespace + '/test-event-groups/' + query.testEventGroup + '/test-event-fittings?include=fitting';
    }
  });
});