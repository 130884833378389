define('clever-sparky-frontend/components/file-upload-input', ['exports', 'ember', 'ember-uploader'], function (exports, _ember, _emberUploader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var emberIsEmpty = _ember.default.isEmpty;
  exports.default = _emberUploader.default.FileField.extend({
    elementId: 'file-upload',

    filesDidChange: function filesDidChange(files) {
      if (!emberIsEmpty(files)) {
        this.attrs.uploadCsv(files[0]);
        this.$().val(null);
      }
    }
  });
});