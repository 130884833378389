define('clever-sparky-frontend/models/employment', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    // Relationships

    user: belongsTo('user'),

    organisation: belongsTo('organisation'),

    invite: belongsTo('invite'),

    // Attributes

    role: attr('string'),

    checkoutPermission: attr('boolean'),

    // Properties

    isTechnician: computed.equal('role', 'technician')

  });
});