define('clever-sparky-frontend/authorizers/cleversparky', ['exports', 'ember', 'ember-simple-auth/authorizers/devise'], function (exports, _ember, _devise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isEmpty = _ember.default.isEmpty;
  exports.default = _devise.default.extend({

    resourceName: 'session',

    identificationAttributeName: 'session_id',

    authorize: function authorize(data, block) {
      var _getProperties = this.getProperties('tokenAttributeName', 'identificationAttributeName', 'resourceName'),
          tokenAttributeName = _getProperties.tokenAttributeName,
          identificationAttributeName = _getProperties.identificationAttributeName,
          resourceName = _getProperties.resourceName;

      var userToken = data[resourceName][tokenAttributeName];
      var userIdentification = data[resourceName][identificationAttributeName];

      if (!isEmpty(userToken) && !isEmpty(userIdentification)) {
        block(userIdentification, userToken);
      }
    }
  });
});