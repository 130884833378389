define('clever-sparky-frontend/controllers/reset-password/index', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller;
  exports.default = Controller.extend({
    queryParams: {
      token: 'reset_password_token'
    },

    token: null
  });
});