define('clever-sparky-frontend/components/forms/user-details-form', ['exports', 'ember', 'clever-sparky-frontend/components/forms/user-form', 'clever-sparky-frontend/mixins/option-lookup', 'ember-macaroni'], function (exports, _ember, _userForm, _optionLookup, _emberMacaroni) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed,
      run = _ember.default.run;
  exports.default = _userForm.default.extend(_optionLookup.default, {

    // Attributes

    inputsDisabled: true,

    // Properties

    hasGrantedPurchaseRights: computed.reads('employment.checkoutPermission'),

    isCheckboxSelected: computed.reads('hasGrantedPurchaseRights'),

    userRoleOption: (0, _emberMacaroni.findFromCollectionByKey)('userTypeOptions', 'value', 'employment.role'),

    roleLabel: computed.alias('userRoleOption.label'),

    showGrantPurchasingRights: computed.equal('roleLabel', 'Technician'),

    // Methods
    grantPurchasingRights: function grantPurchasingRights() {
      this.set('hasGrantedPurchaseRights', true);
      this.set('employment.checkoutPermission', true);
      this.attrs.updatePurchasingRights(this.get('employment'));
    },
    revokePurchasingRights: function revokePurchasingRights() {
      this.set('hasGrantedPurchaseRights', false);
      this.set('employment.checkoutPermission', false);
      this.attrs.updatePurchasingRights(this.get('employment'));
    },
    handleUpdatePurchasingRights: function handleUpdatePurchasingRights() {
      if (this.get('isCheckboxSelected') && !this.get('hasGrantedPurchaseRights')) {
        this.grantPurchasingRights();
      } else if (!this.get('isCheckboxSelected') && this.get('hasGrantedPurchaseRights')) {
        this.revokePurchasingRights();
      }
    },


    actions: {
      toggleCheckboxSelected: function toggleCheckboxSelected() {
        this.toggleProperty('isCheckboxSelected');
        run.debounce(this, this.handleUpdatePurchasingRights, 300);
      }
    }

  });
});