define('clever-sparky-frontend/routes/edit/organisation', ['exports', 'ember', 'clever-sparky-frontend/mixins/handle-error', 'ember-can'], function (exports, _ember, _handleError, _emberCan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP,
      inject = _ember.default.inject;
  exports.default = Route.extend(_handleError.default, _emberCan.CanMixin, {
    // Services

    wholesalerAuth: inject.service(),

    // Hooks

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      var _modelFor = this.modelFor('application'),
          currentEmployment = _modelFor.currentEmployment;

      if (!this.can('modify business-profile', currentEmployment)) {
        this.replaceWith('sites');
      }
    },
    model: function model() {
      return RSVP.hash({
        organisation: this.modelFor('application').currentOrganisation,
        wholesalers: this.modelFor('edit').wholesalers
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('organisation', model.organisation);
      controller.set('wholesalers', model.wholesalers);
      controller.set('displayDeleteAccountModal', false);
      controller.set('isQueryPending', false);
    },


    actions: {
      save: function save(organisation) {
        var _this = this;

        organisation.save().then(function () {
          _this.controller.set('isEditing', false);
          _this.showSuccessFlash('Business Details successfully updated');
        }).catch(function (reason) {
          _this.handleRailsError(reason);
          _this.currentModel.organisation.rollbackAttributes();
        }).finally(function () {
          _this.controller.set('isQueryPending', false);
        });
      },
      deleteAccount: function deleteAccount(organisation) {
        var _this2 = this;

        this.controller.set('isQueryPending', true);

        organisation.destroyRecord().then(function () {
          // setCurrentOrganisation is defined in the application route
          _this2.send('setCurrentOrganisation', null);
          _this2.showSuccessFlash('Account Deleted');
        }).catch(function (reason) {
          _this2.handleRailsError(reason);
        }).finally(function () {
          _this2.controller.set('isQueryPending', false);
        });
      },
      authenticateWholesaler: function authenticateWholesaler(wholesaler, username, password) {
        var credentials = {
          username: username,
          password: password
        };
        return this.get('wholesalerAuth').sendWholesalerAuthRequest(wholesaler, credentials);
      },
      openDeleteAccountModal: function openDeleteAccountModal() {
        this.controller.set('displayDeleteAccountModal', true);
      },
      closeDeleteAccountModal: function closeDeleteAccountModal() {
        this.controller.set('displayDeleteAccountModal', false);
      }
    }
  });
});