define('clever-sparky-frontend/routes/test-event-group/fittings', ['exports', 'ember', 'clever-sparky-frontend/mixins/handle-error', 'ember-infinity/mixins/route', 'clever-sparky-frontend/mixins/setup-tour-sequence', 'ember-local-storage'], function (exports, _ember, _handleError, _route, _setupTourSequence, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP,
      inject = _ember.default.inject;
  exports.default = Route.extend(_route.default, _handleError.default, _setupTourSequence.default, {
    // Services
    sitetmp: (0, _emberLocalStorage.storageFor)('site'),
    testeventtmp: (0, _emberLocalStorage.storageFor)('test-event'),
    viewTestEventGroupTour: inject.service('tours/view-test-event-group'),

    holdvaribles: inject.service('holdvaribles'),

    features: inject.service(),
    store: inject.service(),

    batchCreateLedtests: inject.service(),

    // Attributes
    perPageParam: 'page[size]', // instead of 'per_page'

    pageParam: 'page[number]', // instead of 'page'

    totalPagesParam: 'meta.total-pages', // instead of "meta.total_pages"

    // Route Attributes

    queryParams: {
      collected: {
        refreshModel: true
      }
    },

    // Hooks

    beforeModel: function beforeModel() {
      if (this.get('features').isEnabled('guidedTour')) {
        return RSVP.hash({
          viewTestEventGroupTooltipEvent: this.setupTourSequence('view_test_event_group_web', this.get('viewTestEventGroupTour'))
        });
      }
    },
    model: function model(params) {
      var _modelFor = this.modelFor('test-event-group'),
          testEvent = _modelFor.testEvent;

      var grouporallfittings = testEvent.data.viewb;
      var filter = {};
      if (params.hasOwnProperty('collected')) {
        filter.collected = params.collected;
      }

      var site = this.get('sitetmp.site');
      var testEventid = this.get('testeventtmp.id');
      testEvent = this.get('testeventtmp.testevent');

      if (grouporallfittings === 'allfittings') {
        return RSVP.hash({
          testEvent: testEvent,
          testEventFittings: this.infinityModel('test-event-fitting', {
            siteid: site.id,
            testEventid: testEventid,
            perPage: 10,
            grouporallfittings: 'allfittings',
            startingPage: 1,
            modelPath: 'controller.model.testEventFittings',
            filter: filter
          }),
          fitting: this.store.createRecord('fitting', { group: null }),
          newTestEvent: this.store.createRecord('test-event')
        });
      } else {
        var _modelFor2 = this.modelFor('test-event-group'),
            testEventGroup = _modelFor2.testEventGroup;

        return RSVP.hash({
          testEventGroup: testEventGroup,
          testEvent: testEvent,
          testEventFittings: this.infinityModel('test-event-fitting', {
            testEventGroup: testEventGroup.id,
            siteid: site.id,
            testEventid: testEvent.id,
            perPage: 10,
            grouporallfittings: 'group',
            startingPage: 1,
            modelPath: 'controller.model.testEventFittings',
            filter: filter
          }),
          fitting: this.store.createRecord('fitting', {
            group: testEventGroup.get('group')
          }),
          newTestEvent: this.store.createRecord('test-event')
        });
      }
    },
    afterModel: function afterModel(model) {

      return new RSVP.Promise(function (resolve) {

        model.data = model.testEventFittings.links.size;
        resolve();
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('collected', false);
        controller.set('selectedTestEventFittings', []);
      }
    },


    actions: {
      showTestEventFittingDetails: function showTestEventFittingDetails(testEventFitting) {
        this.transitionTo('test-event-group.fittings.show.details', testEventFitting.id);
      },
      addFitting: function addFitting(fitting) {
        var _this = this;

        fitting.save().then(function () {
          _this.controller.send('closeAddFittingModal');
          _this.showSuccessFlash('Fitting Added');
          // refresh the top most parent route
          _this.send('refreshRoute');
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        }).finally(function () {
          _this.controller.set('isQueryPending', false);
        });
      },
      batchSaveTestResults: function batchSaveTestResults(testAttributes) {
        var _this2 = this;

        this.get('batchCreateLedtests').createLedTests(this.controller.selectedTestEventFittings, testAttributes).then(function () {
          _this2.controller.send('closeQuickTestModal');
          _this2.controller.set('isSelectingTestEventFittings', false);
          _this2.controller.set('selectedTestEventFittings', []);
          _this2.showSuccessFlash('Batch Test Results added succesfully');
          // refresh the top most parent route
          _this2.send('refreshRoute');
        }).catch(function (reason) {
          _this2.handleRailsError(reason);
        }).finally(function () {
          _this2.controller.set('isQueryPending', false);
        });
      },
      editDateRange: function editDateRange(testEvent) {
        var _this3 = this;

        testEvent.save().then(function () {
          _this3.controller.send('closeEditDateRangeModal');
          _this3.showSuccessFlash('Date range updated');
          // refresh the top most parent route
          _this3.send('refreshRoute');
        }).catch(function (reason) {
          _this3.handleRailsError(reason);
        }).finally(function () {
          _this3.controller.set('isQueryPending', false);
        });
      },
      duplicate: function duplicate(duplicatedTestEvent) {
        var _this4 = this;

        this.currentModel.testEvent.duplicate({
          data: {
            attributes: {
              startDate: duplicatedTestEvent.get('startDate'),
              endDate: duplicatedTestEvent.get('endDate')
            }
          }
        }).then(function (newTestEvent) {
          _this4.controller.send('closeDuplicateTestEventModal');
          _this4.showSuccessFlash('Test Event successfully duplicated');
          _this4.transitionTo('test-event', newTestEvent.data.id);
        }).catch(function (reason) {
          _this4.handleRailsError(reason);
        }).finally(function () {
          _this4.controller.set('isQueryPending', false);
        });
      },
      archive: function archive() {
        var _this5 = this;

        this.currentModel.testEvent.archive().then(function () {
          _this5.controller.send('closeArchiveConfirmationModal');
          _this5.showSuccessFlash('Test Event successfully archived');
          // refresh the top most parent route
          _this5.send('refreshRoute');
        }).catch(function (reason) {
          _this5.handleRailsError(reason);
        }).finally(function () {
          _this5.controller.set('isQueryPending', false);
        });
      },
      saveQuickTestDefaults: function saveQuickTestDefaults(testEvent) {
        var _this6 = this;

        testEvent.save().then(function () {
          _this6.controller.send('closeQuickTestDefaultsModal');
          _this6.showSuccessFlash('Test Event Template updated');
        }).catch(function (reason) {
          _this6.handleRailsError(reason);
        }).finally(function () {
          _this6.controller.set('isQueryPending', false);
        });
      }
    }

  });
});