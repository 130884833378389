define('clever-sparky-frontend/components/file-upload-progress-bar', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend({
    classNames: ['file-upload-progress-bar'],

    classNameBindings: ['showProgressBar:show'],

    intervalDuration: 15,

    isWaiting: computed.equal('uploadStatus', 'waiting'),

    showCancel: computed.equal('uploadStatus', 'preparing'),

    isAborted: computed.equal('uploadStatus', 'aborted'),

    isError: computed.equal('uploadStatus', 'error'),

    isFinished: computed.equal('uploadStatus', 'finished'),

    isProgressFinished: computed.equal('progress', 100),

    showSuccessMessage: computed.and('isFinished', 'isProgressFinished'),

    isErrorAndProgressFinished: computed.and('isError', 'isProgressFinished'),

    showProgressBar: computed('isWaiting', 'isAborted', 'isErrorAndProgressFinished', function () {
      return !this.get('isWaiting') && !this.get('isAborted') && !this.get('isErrorAndProgressFinished');
    }),

    progressLabel: computed('progress', function () {
      return this.get('progress').toFixed(0);
    }),

    displayProgress: computed('progress', function () {
      return this.get('progress') / 100;
    })
  });
});