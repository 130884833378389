define('clever-sparky-frontend/components/fitting-reinstate-confirmation', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component;
  exports.default = Component.extend({

    classNames: ['fitting-reinstate-confirmation'],

    isQueryPending: false,

    actions: {
      reinstateFitting: function reinstateFitting() {
        this.set('isQueryPending', true);
        this.attrs.reinstateFitting();
      }
    }

  });
});