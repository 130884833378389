define('clever-sparky-frontend/components/deleted-fitting-table', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend({

    hasNoRecords: computed.equal('deletedFittings.length', 0),

    actions: {
      showFitting: function showFitting(fitting) {
        this.sendAction('showFittingDetails', fitting);
      }
    }

  });
});