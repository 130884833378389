define('clever-sparky-frontend/mixins/show-nav-bar', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = _ember.default.Mixin;
  exports.default = Mixin.create({
    activate: function activate() {
      this.controllerFor('application').set('showNavBar', true);
    },
    deactivate: function deactivate() {
      this.controllerFor('application').set('showNavBar', false);
    }
  });
});