define('clever-sparky-frontend/components/site-permission-table', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend({

    hasNoRecords: computed.equal('sitePermissions.length', 0),

    actions: {
      removeAccess: function removeAccess(sitePermission) {
        this.set('isQueryPending', true);
        this.sendAction('removeAccess', sitePermission);
      }
    }
  });
});