define('clever-sparky-frontend/components/forms/site-form-new', ['exports', 'clever-sparky-frontend/components/forms/site-form'], function (exports, _siteForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _siteForm.default.extend({
    didInsertElement: function didInsertElement() {
      this.toggleLabelFocus(this.$('#site-form-new'));
    },


    isQueryPending: false,

    actions: {
      save: function save() {
        this.set('didValidate', true);

        if (this.get('validations.isValid')) {
          this.updateSite();
          this.set('isQueryPending', true);
          this.sendAction('save', this.get('site'));
        }
      }
    }

  });
});