define('clever-sparky-frontend/routes/site/test-events', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'clever-sparky-frontend/mixins/handle-error', 'ember-infinity/mixins/route'], function (exports, _ember, _authenticatedRouteMixin, _handleError, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP,
      inject = _ember.default.inject;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _route.default, _handleError.default, {
    holdvaribles: inject.service('holdvaribles'),
    batchDeleteTestEvents: inject.service('batchDeleteTestEvents'),

    perPageParam: 'page[size]', // instead of 'per_page'

    pageParam: 'page[number]', // instead of 'page'

    totalPagesParam: 'meta.total-pages', // instead of "meta.total_pages"

    model: function model() {
      var _modelFor = this.modelFor('site'),
          site = _modelFor.site;

      this.get('holdvaribles').setupCurrentSite(site);
      return RSVP.hash({
        site: site,
        testEvents: this.infinityModel('test-event', {
          site: site.id,
          perPage: 10,
          startingPage: 1,
          modelPath: 'controller.model.testEvents'
        }),
        testEvent: this.store.createRecord('test-event')
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('isDeletingTestEvents', false);
      }
    },


    actions: {
      save: function save(testEvent) {
        var _this = this;

        testEvent.set('site', this.currentModel.site);

        testEvent.save().then(function () {
          _this.refresh();
          _this.controller.send('closeAddTestEventModal');
          _this.currentModel.site.reload();
          _this.showSuccessFlash('Logbook Created');
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        }).finally(function () {
          _this.controller.set('isQueryPending', false);
        });
      },
      confirmBatchDeleteTestEvents: function confirmBatchDeleteTestEvents(selectedTestEvents) {
        var _this2 = this;

        this.get('batchDeleteTestEvents').deleteBatch(selectedTestEvents).then(function () {
          _this2.refresh();
          _this2.currentModel.site.reload();
          _this2.controller.send('closeBatchDeleteConfirmationModal');
          _this2.showSuccessFlash('Logbooks Deleted');
        }).catch(function (reason) {
          _this2.handleRailsError(reason);
        }).finally(function () {
          _this2.controller.set('isQueryPending', false);
        });
      }
    }

  });
});