define('clever-sparky-frontend/components/batch-delete-confirmation', ['exports', 'ember', 'clever-sparky-frontend/helpers/pluralizer'], function (exports, _ember, _pluralizer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend({

    batchName: '',

    isQueryPending: false,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('batchLength', this.get('batch.length'));
    },


    batchLength: null,

    uppercaseBatchName: computed('batchName', function () {
      return this.get('batchName').capitalize();
    }),

    actionText: computed('uppercaseBatchName', function () {
      return 'Back to ' + (0, _pluralizer.pluralizer)([null, this.get('uppercaseBatchName')]);
    }),

    actions: {
      confirmBatchDelete: function confirmBatchDelete(batch) {
        this.set('isQueryPending', true);
        this.attrs.confirmBatchDelete(batch);
      }
    }
  });
});