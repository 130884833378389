define('clever-sparky-frontend/routes/change-password', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'clever-sparky-frontend/mixins/show-nav-bar', 'clever-sparky-frontend/mixins/handle-error'], function (exports, _ember, _authenticatedRouteMixin, _showNavBar, _handleError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _handleError.default, _showNavBar.default, {
    afterModel: function afterModel() {
      this.transitionTo('change-password.index');
    },


    actions: {
      save: function save(user) {
        var _this = this;

        user.save().then(function () {
          _this.transitionTo('change-password.success');
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        }).finally(function () {
          user.clearPassword();
          _this.controllerFor('change-password.index').set('isQueryPending', false);
        });
      }
    }
  });
});