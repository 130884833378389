define('clever-sparky-frontend/adapters/employment', ['exports', 'ember', 'clever-sparky-frontend/adapters/application'], function (exports, _ember, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ENV = _ember.default.ENV;
  exports.default = _application.default.extend({
    urlForFindRecord: function urlForFindRecord() {
      return this._super.apply(this, arguments) + '?include=invite,user,organisation';
    },
    urlForQuery: function urlForQuery(query) {
      var url = ENV.api.host + '/' + ENV.api.namespace + '/users/me/employments';

      if (query.currentOrganisationId) {
        url = ENV.api.host + '/' + ENV.api.namespace + '/organisations/' + query.currentOrganisationId + '/employments?include=invite,user';
      }

      return url;
    }
  });
});