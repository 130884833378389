define('clever-sparky-frontend/controllers/site/faults', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      computed = _ember.default.computed;
  exports.default = Controller.extend({

    faults: computed.alias('model.faults'),

    actions: {
      addOrderItem: function addOrderItem(fitting, partType) {
        this.send('createOrderItem', fitting, partType);
      },
      removeOrderItem: function removeOrderItem(fitting, partType) {
        this.send('deleteOrderItem', fitting, partType);
      },
      backToFaultFittingsIndex: function backToFaultFittingsIndex() {
        this.transitionToRoute('site.faults');
      }
    }

  });
});