define('clever-sparky-frontend/controllers/forgot-password/success', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      computed = _ember.default.computed;
  exports.default = Controller.extend({
    queryParams: ['email'],
    email: null,

    hasEmail: computed.bool('email')
  });
});