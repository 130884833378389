define('clever-sparky-frontend/routes/test-event/groups', ['exports', 'ember', 'ember-infinity/mixins/route', 'clever-sparky-frontend/mixins/handle-error', 'clever-sparky-frontend/mixins/setup-tour-sequence'], function (exports, _ember, _route, _handleError, _setupTourSequence) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP,
      inject = _ember.default.inject;
  exports.default = Route.extend(_route.default, _handleError.default, _setupTourSequence.default, {
    // Services

    batchHandleTestEventGroups: inject.service(),

    testEventDropdownMenuTour: inject.service('tours/test-event-dropdown-menu'),

    features: inject.service(),

    // Attributes

    perPageParam: 'page[size]', // instead of 'per_page'

    pageParam: 'page[number]', // instead of 'page'

    totalPagesParam: 'meta.total-pages', // instead of "meta.total_pages"

    // Hooks

    beforeModel: function beforeModel() {
      if (this.get('features').isEnabled('guidedTour')) {
        return RSVP.hash({
          viewTestEventDropdownTooltipEvent: this.setupTourSequence('test_event_dropdown_web', this.get('testEventDropdownMenuTour'))
        });
      }
    },
    model: function model() {
      var _modelFor = this.modelFor('test-event'),
          site = _modelFor.site,
          testEvent = _modelFor.testEvent;

      return RSVP.hash({
        site: site,
        testEvent: testEvent,
        testEventGroups: this.infinityModel('test-event-group', {
          testEvent: testEvent.id,
          perPage: 10,
          startingPage: 1,
          modelPath: 'controller.model.testEventGroups'
        }),
        missingGroups: this.store.query('group', {
          testEvent: testEvent.id
        }),
        newTestEvent: this.store.createRecord('test-event')
      });
    },


    actions: {
      batchAddGroups: function batchAddGroups(selectedGroups) {
        var _this = this;

        this.get('batchHandleTestEventGroups').addGroups(selectedGroups, this.currentModel.testEvent.get('id')).then(function () {
          _this.showSuccessFlash('Groups added');
          _this.controller.send('closeAddGroupsModal');
          // refresh the top most parent route
          _this.send('refreshRoute');
        }).catch(function (reason) {
          _this.handleRailsError(reason);
        }).finally(function () {
          _this.controller.set('isQueryPending', false);
        });
      },
      batchRemoveGroups: function batchRemoveGroups(selectedGroups) {
        var _this2 = this;

        this.get('batchHandleTestEventGroups').removeGroups(selectedGroups, this.currentModel.testEvent.get('id')).then(function () {
          _this2.showSuccessFlash('Groups removed');
          _this2.controller.send('closeRemoveGroupsModal');
          // refresh the top most parent route
          _this2.send('refreshRoute');
        }).catch(function (reason) {
          _this2.handleRailsError(reason);
        }).finally(function () {
          _this2.controller.set('isQueryPending', false);
        });
      },
      editDateRange: function editDateRange(testEvent) {
        var _this3 = this;

        testEvent.save().then(function () {
          _this3.controller.send('closeEditDateRangeModal');
          _this3.showSuccessFlash('Date range updated');
          // refresh the top most parent route
          _this3.send('refreshRoute');
        }).catch(function (reason) {
          _this3.handleRailsError(reason);
        }).finally(function () {
          _this3.controller.set('isQueryPending', false);
        });
      },
      duplicate: function duplicate(duplicatedTestEvent) {
        var _this4 = this;

        this.currentModel.testEvent.duplicate({
          data: {
            attributes: {
              startDate: duplicatedTestEvent.get('startDate'),
              endDate: duplicatedTestEvent.get('endDate')
            }
          }
        }).then(function (newTestEvent) {
          _this4.controller.send('closeDuplicateTestEventModal');
          _this4.showSuccessFlash('Test Event successfully duplicated');
          _this4.transitionTo('test-event', newTestEvent.data.id);
        }).catch(function (reason) {
          _this4.handleRailsError(reason);
        }).finally(function () {
          _this4.controller.set('isQueryPending', false);
        });
      },
      archive: function archive() {
        var _this5 = this;

        this.currentModel.testEvent.archive().then(function () {
          _this5.controller.send('closeArchiveConfirmationModal');
          _this5.showSuccessFlash('Test Event successfully archived');
          // refresh the top most parent route
          _this5.send('refreshRoute');
        }).catch(function (reason) {
          _this5.handleRailsError(reason);
        }).finally(function () {
          _this5.controller.set('isQueryPending', false);
        });
      },
      saveQuickTestDefaults: function saveQuickTestDefaults(testEvent) {
        var _this6 = this;

        testEvent.save().then(function () {
          _this6.controller.send('closeQuickTestDefaultsModal');
          _this6.showSuccessFlash('Test Event Template updated');
        }).catch(function (reason) {
          _this6.handleRailsError(reason);
        }).finally(function () {
          _this6.controller.set('isQueryPending', false);
        });
      }
    }

  });
});