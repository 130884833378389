define('clever-sparky-frontend/components/create-test-event-modal', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component;
  exports.default = Component.extend({
    // Attributes
    isShowingTestEventForm: true,

    isQueryPending: false,

    // Methods
    closeModal: function closeModal() {},


    actions: {
      submitTestEventDetails: function submitTestEventDetails(testEvent) {
        this.set('testEvent', testEvent);
        this.set('isShowingTestEventForm', false);
      },
      submitQuickTestDefaults: function submitQuickTestDefaults(testEvent) {
        this.set('isQueryPending', true);
        this.attrs.save(testEvent);
      }
    }
  });
});