define('clever-sparky-frontend/components/forms/site-form', ['exports', 'ember', 'clever-sparky-frontend/validators/forms/site', 'clever-sparky-frontend/mixins/toggle-label-focus'], function (exports, _ember, _site, _toggleLabelFocus) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed;
  exports.default = Component.extend(_site.SiteValidations, _toggleLabelFocus.default, {
    didInsertElement: function didInsertElement() {
      this.toggleLabelFocus(this.$('#site-form'));
    },


    didValidate: false,

    canEdit: true,

    isQueryPending: false,

    unit: computed(function () {
      return this.get('site.unit');
    }),

    address: computed(function () {
      return this.get('site.address');
    }),

    businessName: computed(function () {
      return this.get('site.businessName');
    }),

    buildingName: computed(function () {
      return this.get('site.buildingName');
    }),

    contactName: computed(function () {
      return this.get('site.contactName');
    }),

    contactEmail: computed(function () {
      return this.get('site.contactEmail');
    }),
    adminEmail: computed(function () {
      return this.get('site.adminEmail');
    }),

    placeId: computed(function () {
      return this.get('site.placeId');
    }),

    updateSite: function updateSite() {
      this.set('site.unit', this.get('unit'));
      this.set('site.address', this.get('address'));
      this.set('site.businessName', this.get('businessName'));
      this.set('site.buildingName', this.get('buildingName'));
      this.set('site.contactName', this.get('contactName'));
      this.set('site.contactEmail', this.get('contactEmail'));
      this.set('site.adminEmail', this.get('adminEmail'));
      this.set('site.placeId', this.get('placeId'));
    },
    resetSite: function resetSite() {
      this.set('unit', this.get('site.unit'));
      this.set('address', this.get('site.address'));
      this.set('businessName', this.get('site.businessName'));
      this.set('buildingName', this.get('site.buildingName'));
      this.set('contactName', this.get('site.contactName'));
      this.set('contactEmail', this.get('site.contactEmail'));
      this.set('adminEmail', this.get('site.adminEmail'));
      this.set('placeId', this.get('site.placeId'));
    },


    actions: {
      delete: function _delete() {
        this.sendAction('delete');
      },
      cancel: function cancel() {
        this.resetSite();
        this.set('didValidate', false);
        this.set('canEdit', false);
      },
      save: function save() {
        this.set('didValidate', true);

        if (this.get('validations.isValid')) {
          this.updateSite();
          this.sendAction('save', this.get('site'));
          this.set('isQueryPending', true);
          this.set('didValidate', false);
          this.set('canEdit', false);
        }
      }
    }

  });
});