define('clever-sparky-frontend/services/batch-handle-test-event-groups', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = _ember.default.Service,
      inject = _ember.default.inject,
      ENV = _ember.default.ENV;
  exports.default = Service.extend({
    requestService: inject.service('request-service'),

    _type: 'groups',

    _batchAddMethod: 'POST',

    _batchRemoveMethod: 'DELETE',

    _batchActionURL: function _batchActionURL(testEventId) {
      return ENV.api.host + '/' + ENV.api.namespace + '/test-events/' + testEventId + '/relationships/groups';
    },
    addGroups: function addGroups(batch, testEventId) {
      var url = this._batchActionURL(testEventId);
      var method = this.get('_batchAddMethod');
      var type = this.get('_type');

      var data = {
        data: []
      };

      batch.forEach(function (item) {
        data.data.push({ type: type, id: item.id });
      });

      return this.get('requestService').sendAuthenticatedRequest(url, method, data);
    },
    removeGroups: function removeGroups(batch, testEventId) {
      var url = this._batchActionURL(testEventId);
      var method = this.get('_batchRemoveMethod');
      var type = this.get('_type');

      var data = {
        data: []
      };

      batch.forEach(function (item) {
        data.data.push({ type: type, id: item.id });
      });

      return this.get('requestService').sendAuthenticatedRequest(url, method, data);
    }
  });
});